import React, { useState, useEffect, useRef } from 'react';
import { slotToTime } from '../../../utils/converters';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import '../../css/rightsidebar.css';

const colors = ['#FF9500', '#FFCC00', '#4CD964', '#5AC8FA', '#007AFF', '#5856D6'];

const RightSideBar = ({ selectedSlot, onHoverReservation, updateReservedTables, onHighlightTable, adminData, additionalTablesData, tablesData, currentDate, onDateChange, onHoverTable }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const prevFilteredReservations = useRef([]);
  const [hoveredReservation, setHoveredReservation] = useState(null);

  useEffect(() => {
    if (adminData && additionalTablesData) {
      const reservations = adminData.filter(reservation => 
        additionalTablesData.some(table => table.reservation === reservation.id)
      );
      updateReservedTables(reservations);
    }
  }, [adminData, additionalTablesData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDateChange = (days) => {
    onDateChange(days);
  };

  const filteredReservations = adminData?.filter(reservation => {
    const withinRange = selectedSlot && reservation.start_time <= selectedSlot && reservation.start_time > selectedSlot - 4;
    const fullName = `${reservation.first_name} ${reservation.last_name}`;
    const reservationDate = new Date(reservation.date).toDateString();
    const currentDateString = currentDate.toDateString();
    return fullName.toLowerCase().includes(searchTerm.toLowerCase()) && 
           (!selectedSlot || withinRange) && 
           reservationDate === currentDateString;
  }) || [];

  useEffect(() => {
    if (JSON.stringify(filteredReservations) !== JSON.stringify(prevFilteredReservations.current)) {
      prevFilteredReservations.current = filteredReservations;
      if (filteredReservations.length === 1) {
        const mappings = additionalTablesData.filter(map => map.reservation === filteredReservations[0].id);
        const tableIds = mappings.map(map => map.table);
        if (tableIds.length > 0) {
          onHighlightTable(tableIds, colors[0]);
        }
      } else {
        onHighlightTable(null, null);
      }
    }
  }, [filteredReservations, additionalTablesData, onHighlightTable]);

  const handleMouseEnter = (reservation, color) => {
    const mappings = additionalTablesData.filter(map => map.reservation === reservation.id);
    const tableIds = mappings.map(map => map.table);
    setHoveredReservation({ tableIds, color, reservationName: `${reservation.first_name} ${reservation.last_name}` });
    if (tableIds.length > 0) {
      onHoverReservation(tableIds, color, `${reservation.first_name} ${reservation.last_name}`);
      onHoverTable(tableIds, color);
    }
  };

  const handleMouseLeave = () => {
    setHoveredReservation(null);
    onHoverReservation(null, null, null);
    onHoverTable(null, null);
  };

  const getTableNames = (reservationId) => {
    const mappings = additionalTablesData.filter(map => map.reservation === reservationId);
    const uniqueTableIds = [...new Set(mappings.map(map => map.table))];
    const tableNames = uniqueTableIds.map(tableId => {
      const table = tablesData.find(table => table.id === tableId);
      return table ? table.name : 'Unknown Table';
    });
    return tableNames.join(', ');
  };

  const formatDateDutch = (date) => {
    const days = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const months = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
    return `${days[date.getDay()]} ${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  return (
    <div className="right-sidebar">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <button className="btn btn-outline-secondary" onClick={() => handleDateChange(-1)}>
          <FaArrowLeft />
        </button>
        <div className="current-date">{formatDateDutch(currentDate)}</div>
        <button className="btn btn-outline-secondary" onClick={() => handleDateChange(1)}>
          <FaArrowRight />
        </button>
      </div>
      <div className="search-bar mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Zoeken..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <div className="reservations-list">
        {filteredReservations.map((reservation, index) => (
          <div
            key={reservation.id}
            className="reservation-item p-2 mb-2"
            style={{ backgroundColor: `${colors[index % colors.length]}80` }}
            onMouseEnter={() => handleMouseEnter(reservation, colors[index % colors.length])}
            onMouseLeave={handleMouseLeave}
          >
            <div className="d-flex justify-content-between">
              <div className="reservation-details">
                <div className="reservation-name">{reservation.guests}p - {reservation.first_name} {reservation.last_name}</div>
                <div className="reservation-time">{`${reservation.date}, ${slotToTime(reservation.start_time)}`}</div>
                <div className="reservation-guests">{`Tafel: ${getTableNames(reservation.id)}`}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RightSideBar;
