import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import DeleteModal from '../../components/delete_modal';
import '../css/opening.css';
import '../css/general.css';

const Giftcard = () => {
  const url = `${window.baseDomain}giftcards/`;
  const { data, error, loading } = useDataRetriever(url);
  const [labels, setLabels] = useState([]);
  const [newLabel, setNewLabel] = useState(0);
  const [editingLabel, setEditingLabel] = useState(null);
  const [editedAmount, setEditedAmount] = useState(0);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) {
      const labelList = data.map(item => ({ id: item.id, amount: item.amount }));
      setLabels(labelList);
    }
  }, [data]);

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const postLabel = async (amount_in_euro) => {
    const payload = { amount: amount_in_euro };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post giftcard');
      }

      const newLabel = await response.json();
      setLabels([...labels, newLabel]);
    } catch (error) {
      console.error(`Failed to post giftcard ${amount_in_euro}: ${error.message}`);
    }
  };

  const addLabel = async () => {
    if (newLabel && !labels.some(label => label.amount === newLabel)) {
      await postLabel(newLabel);
      setNewLabel(0);
    }
  };

  const deleteLabel = async (id) => {
    const endpoint = `${url}update-delete/${id}/`;

    try {
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete label');
      }

      setLabels(labels.filter(label => label.id !== id));
    } catch (error) {
      console.error(`Failed to delete label ${id}: ${error.message}`);
    }
  };

  const confirmDeleteLabel = (label) => {
    setLabelToDelete(label);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    if (labelToDelete) {
      await deleteLabel(labelToDelete.id);
      setDeleteModalVisible(false);
      setLabelToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setLabelToDelete(null);
  };

  const removeLabel = async (labelToRemove) => {
    confirmDeleteLabel(labelToRemove);
  };

  const editLabel = (label) => {
    setEditingLabel(label);
    setEditedAmount(label.amount);
  };

  const updateLabel = async () => {
    if (!editingLabel) return;

    const endpoint = `${url}update-delete/${editingLabel.id}/`;
    const payload = { amount: editedAmount };

    try {
      const response = await fetch(endpoint, {
        method: 'PATCH',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to update giftcard');
      }

      const updatedLabel = await response.json();
      setLabels(labels.map(label => (label.id === updatedLabel.id ? updatedLabel : label)));
      setEditingLabel(null);
      setEditedAmount(0);
    } catch (error) {
      console.error(`Failed to update giftcard ${editingLabel.id}: ${error.message}`);
    }
  };

  const generateOptions = () => {
    const options = [];
    
    for (let i = 15; i <= 50; i += 5) {
      options.push(i);
    }
    for (let i = 60; i <= 100; i += 10) {
      options.push(i);
    }
    for (let i = 125; i <= 200; i += 25) {
      options.push(i);
    }
    for (let i = 250; i <= 300; i += 50) {
      options.push(i);
    }
    for (let i = 400; i <= 500; i += 100) {
      options.push(i);
    }
    return options;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <h1>Cadeaubonnen</h1>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <label htmlFor="newLabel" className="form-label">Voeg cadeaubonnen toe</label>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <select 
              className="form-select" 
              value={newLabel} 
              onChange={(e) => setNewLabel(parseInt(e.target.value))}
            >
            <option value={0}>Selecteer bedrag</option>
            {generateOptions().map((amount) => (
              <option key={amount} value={amount}>{amount}</option>
            ))}
          </select>
          <button className="btn btn-primary" onClick={addLabel}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Cadeaubonnen</label>
        <ul className="list-group">
          {labels.map((label) => (
            <li key={label.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                {label.amount}
              </div>
              <div>
                <button className="btn btn-secondary btn-sm me-2" onClick={() => editLabel(label)}>Bewerk</button>
                <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteLabel(label)}>Verwijder</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {editingLabel && (
        <div className="mb-3">
          <label htmlFor="editLabel" className="form-label">Bewerk Cadeaubon Prijs (in euros)</label>
          <div className="input-group">
            <select 
                className="form-select" 
                value={editedAmount} 
                onChange={(e) => setEditedAmount(parseInt(e.target.value))}
              >
              <option value={0}>Selecteer bedrag</option>
              {generateOptions().map((amount) => (
                <option key={amount} value={amount}>{amount}</option>
              ))}
            </select>
            <button className="btn btn-primary" onClick={updateLabel}>Update</button>
            <button className="btn btn-secondary" onClick={() => setEditingLabel(null)}>Annuleer</button>
          </div>
        </div>
      )}
      <DeleteModal
        visible={deleteModalVisible}
        message={`Weet u zeker dat u de cadeaubon van ${labelToDelete?.amount} euro wilt verwijderen?`}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}

export default Giftcard;
