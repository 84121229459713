import React from 'react';
import Note from './note';
import Tags from './tags';

function getInitials(name) {
  const nameParts = name.trim().split(' ');
  const initials = nameParts.length > 1
    ? `${nameParts[0][0]}${nameParts[1][0]}`
    : `${nameParts[0][0]}${nameParts[0][1]}`;
  return initials.toUpperCase();
}

function getColorFromInitials(initials) {
  // Generate a color based on the initials (deterministic)
  let hash = 0;
  for (let i = 0; i < initials.length; i++) {
    hash = initials.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 60%)`;
  return color;
}

function ProfilePicture({ name }) {
  const initials = getInitials(name);
  const bgColor = getColorFromInitials(initials);
  
  return (
    <div style={{
      backgroundColor: bgColor,
      color: 'white',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      fontSize: '20px',
      fontWeight: 'bold',
      marginRight: '10px'
    }}>
      {initials}
    </div>
  );
}

function Profile() {
  const name = window.currentNameSelection;

  return (
    <div className="container">
      <div style={{ display: 'flex', alignItems: 'center', gap: "7px" }}>
        <ProfilePicture name={name} />
        <h1>{name} <Tags /></h1>
      </div>
      <Note/>
    </div>
  );
}

export default Profile;
