const useDataDelete = async (url, data) => {
	const response = await fetch(url, {
	  method: 'DELETE',
	  headers: {
		'Content-Type': 'application/json',
		'Authorization': 'JWT ' + localStorage.getItem('authKey'),
	  },
	  body: JSON.stringify(data),
	});
  };
  
  export default useDataDelete;
  