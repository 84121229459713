const handleRefreshToken = async () => {
	const refreshToken = localStorage.getItem('refreshKey');
	if (!refreshToken) {
	  throw new Error('No refresh token found');
	}
  
	const response = await fetch(window.baseDomain + 'auth/jwt/refresh', {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json',
	  },
	  body: JSON.stringify({ refresh: refreshToken }),
	});
  
	if (!response.ok) {
	  throw new Error(`Error: ${response.statusText}`);
	}
  
	const data = await response.json();
	if (data.access) {
	  localStorage.setItem('authKey', data.access);
	} else {
	  throw new Error('Invalid response data');
	}
  
	return data;
  };
  
  export default handleRefreshToken;
  