import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import '../css/opening.css';
import '../css/general.css';

const Closing = () => {
  const url = `${window.baseDomain}restaurants/closing-dates/`;
  const { data, error, loading } = useDataRetriever(url);
  const [dates, setDates] = useState([]);
  const [newDate, setNewDate] = useState('');
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');

  useEffect(() => {
    if (data && data.length > 0) {
      const closingDates = data.map(item => ({ id: item.id, date: item.date }));
      setDates(closingDates);
    }
  }, [data]);

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const postDate = async (date) => {
    const payload = { date };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post date');
      }

      const newDate = await response.json();
      setDates([...dates, newDate]);
    } catch (error) {
      console.error(`Failed to post date ${date}: ${error.message}`);
    }
  };

  const addDate = async () => {
    if (newDate && !dates.some(date => date.date === newDate)) {
      await postDate(newDate);
      setNewDate('');
    }
  };

  const addDateRange = async () => {
    if (rangeStart && rangeEnd && new Date(rangeStart) <= new Date(rangeEnd)) {
      const start = new Date(rangeStart);
      const end = new Date(rangeEnd);
      const range = [];

      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        const date = d.toISOString().split('T')[0];
        if (!dates.some(d => d.date === date)) {
          await postDate(date);
          range.push({ id: new Date().getTime(), date });
        }
      }

      setDates([...dates, ...range]);
      setRangeStart('');
      setRangeEnd('');
    }
  };

  const deleteDate = async (id) => {
    const endpoint = `${url}${id}/`;

    try {
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete date');
      }

      setDates(dates.filter(date => date.id !== id));
    } catch (error) {
      console.error(`Failed to delete date ${id}: ${error.message}`);
    }
  };

  const removeDate = async (dateToRemove) => {
    const dateObj = dates.find(date => date.date === dateToRemove);
    if (dateObj) {
      await deleteDate(dateObj.id);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <h1>Sluitingsdagen</h1>
      <div className="mb-3">
        <label htmlFor="newDate" className="form-label">Voeg sluitingsdag toe</label>
        <div className="input-group">
          <input 
            type="date" 
            className="form-control" 
            id="newDate" 
            value={newDate} 
            onChange={(e) => setNewDate(e.target.value)}
          />
          <button className="btn btn-primary" onClick={addDate}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="rangeStart" className="form-label">Voeg een reeks sluitingsdagen toe</label>
        <div className="input-group">
          <input 
            type="date" 
            className="form-control" 
            id="rangeStart" 
            value={rangeStart} 
            onChange={(e) => setRangeStart(e.target.value)}
          />
          <span className="input-group-text">tot</span>
          <input 
            type="date" 
            className="form-control" 
            id="rangeEnd" 
            value={rangeEnd} 
            onChange={(e) => setRangeEnd(e.target.value)}
          />
          <button className="btn btn-primary" onClick={addDateRange}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Sluitingsdagen</label>
        <ul className="list-group">
          {dates.map((date, index) => (
            <li key={date.id} className="list-group-item d-flex justify-content-between align-items-center">
              {date.date}
              <button className="btn btn-danger btn-sm" onClick={() => removeDate(date.date)}>Verwijderen</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Closing;
