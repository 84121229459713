import React, { useState, useEffect } from 'react';
import { Alert, Card, Row, Col, Modal, Button } from 'react-bootstrap';
import { FaClipboardList, FaPlus } from 'react-icons/fa'; 
import '../../css/reservaties.css';
import '../../css/general.css';
import Calendar from './calendar';
import useDataRetriever from '../../../utils/useDataRetriever';
import CompactDay from '../common/compact_day';
import ApexCharts from 'react-apexcharts';
import Analytics from '../../analytics/analytics'
import Voordeel from './voordeel';
import LocationMap from './map'

function Reservaties({ onPageChange }) {
  const today = new Date().toLocaleDateString('en-CA', { timeZone: 'Europe/Berlin' });
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toLocaleDateString('en-CA', { timeZone: 'Europe/Berlin' });
  const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    .toLocaleDateString('en-CA', { timeZone: 'Europe/Berlin' });
  const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toLocaleDateString('en-CA', { timeZone: 'Europe/Berlin' });

  const { data, error, loading } = useDataRetriever(window.baseDomain + `analytics/daily-interval/${firstDayOfMonth}/${nextYear}/`);
  const [events, setEvents] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    if (data) {
      const formattedData = Object.entries(data).map(([date, counts]) => {
        return Object.entries(counts).map(([interval, guests]) => {
          let reservationType = '';
          if (interval === 'M') reservationType = 'ontbijt';
          else if (interval === 'A') reservationType = 'middag';
          else if (interval === 'E') reservationType = 'avond';
          else if (interval === 'N') reservationType = 'nacht';

          const isPast = date < today;
          const color = isPast ? 'rgba(7, 20, 55, 0.03)' : reservationType === 'ontbijt' ? '#17a2b8' : reservationType === 'middag' ? 'rgb(62, 151, 255)' : reservationType === 'avond' ? 'rgb(7, 20, 55)' : '#ff0000';
          const className = isPast ? 'past-event' : '';

          return {
            title: `${guests} ${reservationType}`,
            date: date,
            guests: guests,
            reservationType: reservationType,
            interval: interval,
            color: color,
            className: className
          };
        });
      }).flat();

      setEvents(formattedData);
    }
  }, [data, today]);

  const calculateReservations = (date, interval = null) => {
    const reservations = events.filter(event => event.date === date && (interval ? event.interval === interval : true));
    return reservations.reduce((acc, event) => acc + event.guests, 0);
  };

  const getNextShiftReservations = () => {
    const intervals = ['M', 'A', 'E', 'N'];
    for (let i = 0; i < intervals.length; i++) {
      const reservations = calculateReservations(today, intervals[i]);
      if (reservations > 0) {
        let reservationType = '';
        if (intervals[i] === 'M') reservationType = 'ontbijt';
        else if (intervals[i] === 'A') reservationType = 'middag';
        else if (intervals[i] === 'E') reservationType = 'avond';
        else if (intervals[i] === 'N') reservationType = 'nacht';
        return `${reservations} ${reservationType}`;
      }
    }
    return 'Geen reservaties voor de volgende shift';
  };

  const calculateWeeklyReservations = () => {
    const daySums = { 'Monday': 0, 'Tuesday': 0, 'Wednesday': 0, 'Thursday': 0, 'Friday': 0, 'Saturday': 0, 'Sunday': 0 };
    events.forEach(event => {
      const day = new Date(event.date).toLocaleDateString('en-US', { weekday: 'long' });
      daySums[day] += event.guests;
    });
    return daySums;
  };

  const dayNamesInDutch = {
    'Monday': 'Ma',
    'Tuesday': 'Di',
    'Wednesday': 'Wo',
    'Thursday': 'Do',
    'Friday': 'Vr',
    'Saturday': 'Za',
    'Sunday': 'Zo'
  };

  const weeklyReservations = calculateWeeklyReservations();
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const reservationsToday = calculateReservations(today);
  const reservationsTomorrow = calculateReservations(tomorrow);
  const nextShift = getNextShiftReservations();

  const handleShowModal = (date) => {
    setSelectedDate(date);
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const chartOptions = {
    series: [{
      name: 'Aantal Reservaties',
      data: daysOfWeek.map(day => weeklyReservations[day])
    }],
    chart: {
      type: 'area',
      height: 250,
      toolbar: {
        show: false
      }
    },
    fill: {
      type: 'solid',
      opacity: 1
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: ['#62a6f1']
    },
    xaxis: {
      categories: daysOfWeek.map(day => dayNamesInDutch[day]),
      labels: {
        style: {
          colors: '#6c757d',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#6c757d',
          fontSize: '12px'
        }
      }
    },
    grid: {
      borderColor: '#ebedf2',
      strokeDashArray: 4
    },
    markers: {
      strokeColor: '#62a6f1',
      strokeWidth: 3
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
  <h1>Restaurant Dashboard</h1>
  <div className="button-group" style={{ display: 'flex', flexDirection: 'row', gap: "15px" }}>
    <Button onClick={() => onPageChange("exceptions")} data-page="exceptions" variant="secondary" style={{ display: 'flex', alignItems: 'center' }}>
      <FaClipboardList style={{ marginRight: '5px' }} /> Uitzonderingen
    </Button>
    <Button className="gradient-btn" onClick={() => onPageChange("nieuw")} data-page="nieuw" variant="primary" style={{ display: 'flex', alignItems: 'center' }}>
      <FaPlus style={{ marginRight: '5px' }} /> Nieuwe Reservatie
    </Button>
  </div>
</div>

      {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
      <Row>
        <Col md={7}>
          <Card className="mb-3">
            <Card.Body>
              <Calendar events={events} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Row>
            <Col>
              <Card className="mb-3" onClick={() => handleShowModal(today)}>
                <Card.Body>
                  <Card.Title className="small-card-title">Reservaties Vandaag</Card.Title>
                  <Card.Text className="large-card-text">{reservationsToday}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3" onClick={() => handleShowModal(tomorrow)}>
                <Card.Body>
                  <Card.Title className="small-card-title">Reservaties Morgen</Card.Title>
                  <Card.Text className="large-card-text">{reservationsTomorrow}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="small-card-title">Volgende Shift</Card.Title>
                  <Card.Text className="large-card-text">{nextShift}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <h5 style={{ marginTop: '20px' }}>Reservaties per Dag</h5>
          <div className="card card-bordered">
            <div className="card-body">
              <div id="kt_apexcharts_3">
                <ApexCharts options={chartOptions} series={chartOptions.series} type="area" height={135} />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <LocationMap></LocationMap>
      <Analytics></Analytics>
      <Voordeel></Voordeel> 
      
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reservaties voor {new Date(selectedDate).toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompactDay date={selectedDate} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Reservaties;
