import React, { useEffect, useState } from 'react';
import QrScanner from 'react-qr-scanner';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qrScanVisible, setQrScanVisible] = useState(false);
  const [scanResult, setScanResult] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [highlightedOrderId, setHighlightedOrderId] = useState(null);
  const [creditValue, setCreditValue] = useState('');
  const [showCreditInput, setShowCreditInput] = useState(null);
  const [paidOfflineValue, setPaidOfflineValue] = useState('');
  const [showPaidOfflineInput, setShowPaidOfflineInput] = useState(null);

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${window.baseDomain}giftcards/orders/`, {
        headers: {
          'Authorization': 'JWT ' + localStorage.getItem('authKey'),
        },
      });

      if (!response.ok) {
        throw new Error('Bestellingen ophalen mislukt');
      }

      const data = await response.json();

      const formattedData = data.map(order => ({
        ...order,
        id: order.id.toString(), // Ensure id is treated as a string
        amount: Number(order.amount),
        credit: Number(order.credit),
        physical_gift: order.physical_gift,
        pay_in_advance: order.pay_in_advance,
        paid_offline: order.paid_offline
      }));

      setOrders(formattedData);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleScan = (data) => {
    if (data && data.text) {
      try {
        const foundOrder = orders.find(order => order.payment_id === data.text);
        if (foundOrder) {
          if (foundOrder.is_paid) {
            setAlertType('success');
            setAlertMessage(`Bestelling gevonden voor waarde: €${foundOrder.amount}.`);
          } else {
            setAlertType('warning');
            setAlertMessage(`Bestelling gevonden maar niet betaald: Betalings-ID ${foundOrder.payment_id}, Waarde: €${foundOrder.amount}`);
          }
          setHighlightedOrderId(foundOrder.payment_id);
        } else {
          setAlertType('danger');
          setAlertMessage('Bestelling niet gevonden');
        }
        setScanResult(data.text);
        setQrScanVisible(false);
      } catch (error) {
        setAlertType('danger');
        setAlertMessage('Ongeldige QR-code');
        setQrScanVisible(false);
      }
    }
  };

  const handleError = (err) => {
    if (err.name === 'NotAllowedError') {
      setAlertType('danger');
      setAlertMessage('Camera toestemming geweigerd. Schakel camera toegang in om QR-code te scannen.');
    } else {
      setError('Fout bij het scannen van de QR-code');
    }
    setQrScanVisible(false);
  };

  const handleCreditChange = (e) => {
    setCreditValue(e.target.value);
  };

  const handlePaidOfflineChange = (e) => {
    setPaidOfflineValue(e.target.value);
  };

  const applyCredit = async (orderId, full = false) => {
    const order = orders.find(order => order.id === orderId);
    const amount = order.amount;
  
    let credit = full ? Number(amount) : Number(creditValue);
  
    if (credit > amount || credit < 0) {
      setAlertType('danger');
      setAlertMessage('De toegekende waarde mag niet hoger zijn dan de cadeaubonwaarde of lager dan nul.');
      return;
    }
  
    try {
      const response = await fetch(`${window.baseDomain}giftcards/credit/${orderId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'JWT ' + localStorage.getItem('authKey'),
        },
        body: JSON.stringify({ credit }),
      });
  
      if (!response.ok) {
        throw new Error('Uitbetaling instellen mislukt');
      }
  
      const updatedOrder = await response.json();
      setOrders(orders.map(order => order.id === orderId ? { ...order, credit: Number(updatedOrder.credit) } : order));
      setAlertType('success');
      setAlertMessage('Uitbetaling succesvol ingesteld.');
      setShowCreditInput(null);
    } catch (error) {
      setAlertType('danger');
      setAlertMessage(error.message);
    }
  };

  const applyPaidOffline = async (orderId) => {
    try {
      const response = await fetch(`${window.baseDomain}giftcards/credit/${orderId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'JWT ' + localStorage.getItem('authKey'),
        },
        body: JSON.stringify({ paid_offline: paidOfflineValue === 'Ja' }),
      });

      if (!response.ok) {
        throw new Error('Betaald in restaurant instellen mislukt');
      }

      const updatedOrder = await response.json();
      setOrders(orders.map(order => order.id === orderId ? { ...order, paid_offline: updatedOrder.paid_offline } : order));
      setAlertType('success');
      setAlertMessage('Betaald in restaurant succesvol ingesteld.');
      setShowPaidOfflineInput(null);
    } catch (error) {
      setAlertType('danger');
      setAlertMessage(error.message);
    }
  };

  const filteredOrders = orders.filter(order => {
    return (order.is_paid || !order.pay_in_advance);
  });

  const sortedOrders = filteredOrders.sort((a, b) => {
    const remainingA = a.amount - a.credit;
    const remainingB = b.amount - b.credit;
    return remainingA === 0 ? 1 : remainingB === 0 ? -1 : 0;
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <h1>Cadeaubonnen Bestellingen</h1>
      {alertMessage && (
        <div className={`alert alert-${alertType}`} role="alert">
          {alertMessage}
        </div>
      )}
      {!qrScanVisible && (
        <button 
          className="btn btn-primary mb-3 d-flex align-items-center justify-content-center position-absolute top-0 end-0 m-3" 
          onClick={() => setQrScanVisible(true)}
        >
          <i className="bi bi-camera me-2"></i> QR Scanner
        </button>
      )}
      {qrScanVisible && (
        <div style={{ position: 'relative' }}>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
          <button
            className="btn btn-secondary"
            onClick={() => setQrScanVisible(false)}
            style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1 }}
          >
            &times;
          </button>
        </div>
      )}
      <ul className="list-group mt-3">
        {sortedOrders.map((order) => {
          const remainingValue = order.amount - order.credit;
          return (
            <li 
              key={order.payment_id} 
              className={`list-group-item ${order.payment_id === highlightedOrderId ? 'list-group-item-success' : ''}`}
              style={{ position: 'relative', color: remainingValue === 0 ? 'gray' : 'inherit' }}
            >
              <p className="mt-3"><strong>#{order.id} - {order.first_name} {order.last_name}</strong></p>
              <p><strong>{order.email}</strong></p>
              <p>Resterende waarde: €{remainingValue}</p>
              <p>Uitbetaalde waarde: €{order.credit}</p>
              <p>Originele waarde: €{order.amount}</p>
              <p>Levering: {order.physical_gift ? 'Ophalen' : 'Digitaal met QR'}</p>
              <p style={{ color: order.is_paid || order.paid_offline ? 'green' : 'red' }}>
                <strong>{order.is_paid ? 'Al Betaald' : order.paid_offline ? 'Betaald in Restaurant' : 'Te Betalen in Restaurant'}</strong>
              </p>

              <button
                className="btn btn-secondary position-absolute top-0 end-0 m-2"
                onClick={() => {
                  setShowCreditInput(showCreditInput === order.id ? null : order.id);
                  setCreditValue(order.credit);
                }}
              >
                Uitbetaling Instellen
              </button>
              {showCreditInput === order.id && (
  <>
    <input
      type="number"
      className="form-control mb-2 mt-2"
      placeholder="Cadeaubon uitgeven"
      value={creditValue}
      onChange={handleCreditChange}
    />
    <div className="d-flex gap-5 mt-5">
      <button
        className="btn btn-primary mb-2"
        onClick={() => applyCredit(order.id, false)}
      >
        Instellen
      </button>
      <button
        className="btn btn-primary mb-2"
        onClick={() => {
          applyCredit(order.id, true);
        }}
      >
        Volledige Uitbetaling
      </button>
    </div>
  </>
)}

              {!order.is_paid && (
                <button
                  className="btn btn-secondary position-absolute top-0 end-0 m-2 mt-20"
                  onClick={() => {
                    setShowPaidOfflineInput(showPaidOfflineInput === order.id ? null : order.id);
                    setPaidOfflineValue(order.paid_offline ? 'Ja' : 'Nee');
                  }}
                >
                  Betaald In Restaurant
                </button>
              )}
              {showPaidOfflineInput === order.id && (
                <>
                  <select
                    className="form-control mb-2 mt-2"
                    value={paidOfflineValue}
                    onChange={handlePaidOfflineChange}
                  >
                    <option value="">Selecteer</option>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                  </select>
                  <button
                    className="btn btn-primary mb-4"
                    onClick={() => applyPaidOffline(order.id)}
                  >
                    Instellen
                  </button>
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Orders;