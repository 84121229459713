import React from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import '../css/analytics.css';
import '../css/general.css';

const getMonthName = (month) => {
  const monthNames = [
    'Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni',
    'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'
  ];
  return monthNames[month - 1];
};

const getApiUrl = (year, month) => {
  return `${window.baseDomain}analytics/monthly/${year}/${month}/`;
};

function Analytics() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
  const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

  const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
  const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear;

  const { data: lastMonthData, error: lastMonthError, loading: lastMonthLoading } = useDataRetriever(getApiUrl(previousYear, previousMonth));
  const { data: currentMonthData, error: currentMonthError, loading: currentMonthLoading } = useDataRetriever(getApiUrl(currentYear, currentMonth));
  const { data: nextMonthData, error: nextMonthError, loading: nextMonthLoading } = useDataRetriever(getApiUrl(nextYear, nextMonth));

  if (lastMonthLoading || currentMonthLoading || nextMonthLoading) return <p>Loading...</p>;
  if (lastMonthError || currentMonthError || nextMonthError) return <p>Error: {lastMonthError?.message || currentMonthError?.message || nextMonthError?.message}</p>;

  const currentMonthName = getMonthName(currentMonth);
  const previousMonthName = getMonthName(previousMonth);
  const nextMonthName = getMonthName(nextMonth);

  return (
    <div>
      <h1>Statistieken</h1>
      <div className="row">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header text-muted">
              Aantal gasten {previousMonthName} {previousYear}
            </div>
            <div className="card-body">
              <h5 className="card-title">{lastMonthData?.guest_count || 0}</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header text-muted">
              Aantal gasten {currentMonthName} {currentYear}
            </div>
            <div className="card-body">
              <h5 className="card-title">{currentMonthData?.guest_count || 0}</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header text-muted">
              Verwachte gasten {nextMonthName} {nextYear}
            </div>
            <div className="card-body">
              <h5 className="card-title">{nextMonthData?.guest_count || 0}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
