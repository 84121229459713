import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { subDays, startOfDay, startOfWeek, startOfMonth, startOfYear, endOfDay, endOfWeek, endOfMonth, endOfYear, isWithinInterval } from "date-fns";
import { FaHome } from 'react-icons/fa';
import './map.css';
import ViewModeButtons from './map_buttons';
import useDataRetriever from '../../../utils/useDataRetriever';

const belgiumCoordinates = [50.8503, 4.3517];

const MIcon = new L.Icon({
  iconUrl: "https://static.reservaties.net/images/logo/logo.png",
  iconSize: [30, 30],
  iconAnchor: [15, 15],
  popupAnchor: [0, -15],
});

const BackToBelgiumButton = () => {
  const map = useMap();

  const moveToBelgium = () => {
    map.setView(belgiumCoordinates, 7);
  };

  return (
    <div className="back-to-belgium" onClick={moveToBelgium}>
      <FaHome className="fa-lg" />
    </div>
  );
};

const LocationMap = () => {
  const [viewMode, setViewMode] = useState("day");

  // Fetch data from the API using useDataRetriever
  const { data, error, loading } = useDataRetriever(window.baseDomain + "reservations/map-view/");

  // Log fetched data
  console.log("Fetched data:", data);

  // Determine the date range based on the viewMode (day, week, month, year)
  const today = new Date();
  const getDateRange = () => {
    if (viewMode === "day") {
      return { start: startOfDay(today), end: endOfDay(today) };
    } else if (viewMode === "week") {
      return { start: startOfWeek(today, { weekStartsOn: 1 }), end: endOfWeek(today, { weekStartsOn: 1 }) };
    } else if (viewMode === "month") {
      return { start: startOfMonth(today), end: endOfMonth(today) };
    } else if (viewMode === "year") {
      return { start: startOfYear(today), end: endOfYear(today) };
    }
  };

  const { start, end } = getDateRange();
  console.log("Date range:", start, end);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data!</div>;

  // Filter data points based on the selected date range and skip reservations with null loc or city
  const filteredDataPoints = data?.filter((point) => {
    try {
      if (!point.loc || !point.city) {
        console.log(`Skipping reservation with ID ${point.id} due to missing loc or city`);
        return false; // Skip if loc or city is null
      }
      return isWithinInterval(new Date(point.created_at), { start, end });
    } catch (err) {
      console.error("Error processing point:", err);
      return false; // Skip this point in case of an error
    }
  }) || [];

  console.log("Filtered data points:", filteredDataPoints);

  // Sum guests by city
  const guestsByCity = filteredDataPoints.reduce((acc, point) => {
    const city = point.city;
    if (!acc[city]) {
      acc[city] = point.guests;
    } else {
      acc[city] += point.guests;
    }
    return acc;
  }, {});

  console.log("Guests by city:", guestsByCity);

  // Convert 'loc' string into coordinates array for markers
  const getCoordinatesFromLoc = (locString) => {
    try {
      const [lat, lon] = locString.split(',').map(coord => parseFloat(coord));
      return [lat, lon];
    } catch (err) {
      console.error("Error parsing loc string:", err);
      return [0, 0]; // Return default coordinates in case of error
    }
  };

  return (
    <div className="container-fluid mt-4">
      <h1>Reservatiemap</h1>
      <div style={{marginTop:'-20px'}} className="row">
        <div className="col-md-12 position-relative">
          <MapContainer
            className="mt-10"
            center={belgiumCoordinates}
            zoom={7}
            style={{ height: "600px", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            />

            {/* Render markers for each unique loc */}
            {filteredDataPoints.map((point, index) => (
              <Marker
                key={index}
                position={getCoordinatesFromLoc(point.loc)} // Convert 'loc' to coordinates
                icon={MIcon}
              >
                <Popup>
                  <strong>{point.city}</strong> <br />
                  Totaal gasten vanuit {point.city}: {guestsByCity[point.city]}
                </Popup>
              </Marker>
            ))}
            <BackToBelgiumButton />
          </MapContainer>
          <ViewModeButtons viewMode={viewMode} setViewMode={setViewMode} />
        </div>
      </div>
    </div>
  );
};

export default LocationMap;
