import React from 'react';
import '../../css/feedback.css';
import '../../css/general.css';

function Feedback() {
	const myStyle = {
        height: "100vh",
		marginHeight: "0",
		marginWidth: "0",
		frameBorder: "0",
    };

  return (
	<div className="container">
	  <iframe style={myStyle} src="https://docs.google.com/forms/d/e/1FAIpQLSehiJI8R5BPhqSYjVhezT6oM46Da8Lzr10BxtCgioUUr49hnA/viewform?embedded=true" width="100%" height="">Laden…</iframe>
	</div>
  );
}

export default Feedback;
