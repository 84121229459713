import React, { useState } from 'react';

function EditModal({ visible, message, maxCapacity, onMaxCapacityChange, onCancel, onConfirm }) {
  if (!visible) return null;

  return (
    <div className="custom-alert modal-on-top">
      <div className="custom-alert-content">
        <h5>Maximale capaciteit bijwerken</h5>
        <p>{message}</p>
        <input 
          type="number" 
          className="form-control mb-3" 
          value={maxCapacity}
          onChange={onMaxCapacityChange}
          placeholder="Nieuwe maximale capaciteit"
        />
        <div className="custom-alert-actions">
          <button className="btn btn-secondary" onClick={onCancel}>Nee, annuleer</button>
          <button className="btn btn-primary" onClick={onConfirm}>Ja, bijwerken</button>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
