import React, { useState } from 'react';
import { format, addDays, subDays } from 'date-fns';
import { nl } from 'date-fns/locale';
import ReservationList from './reservation_list';
import '../../css/general.css';
import '../../css/day.css';

const DayWait = ({ name, endpoint }) => {
  const [searchName, setSearchName] = useState('');
  const [searchTime, setSearchTime] = useState('');
  const [searchGuests, setSearchGuests] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [currentDate, setCurrentDate] = useState(endpoint);
  const [mealType, setMealType] = useState('Hele Dag');
  const [totalGuests, setTotalGuests] = useState(0);

  const incrementDate = () => {
    const newDate = format(addDays(new Date(currentDate), 1), 'yyyy-MM-dd');
    setCurrentDate(newDate);
  };

  const decrementDate = () => {
    const newDate = format(subDays(new Date(currentDate), 1), 'yyyy-MM-dd');
    setCurrentDate(newDate);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getTitle = () => {
    const today = new Date(endpoint);
    const date = new Date(currentDate);
    const tomorrow = format(addDays(today, 1), 'yyyy-MM-dd');
    const yesterday = format(subDays(today, 1), 'yyyy-MM-dd');
    const formattedCurrentDate = format(date, 'yyyy-MM-dd');

    if (formattedCurrentDate === format(today, 'yyyy-MM-dd')) {
      return 'Wachtlijst ' + name;
    } else if (formattedCurrentDate === tomorrow) {
      return 'Wachtlijst Morgen';
    } else if (formattedCurrentDate === yesterday) {
      return 'Wachtlijst Gisteren';
    } else {
      return 'Wachtlijst ' + capitalizeFirstLetter(format(date, 'EEEE d MMMM', { locale: nl }));
    }
  };

  return (
    <div className="container">
      <div className="header-container">
        <h1>{mealType === 'Hele Dag' ? getTitle() : `${getTitle()} - ${mealType}`}</h1>
        <button className="btn btn-secondary btn-sm mx-1" onClick={decrementDate}>{`<- Vorige`}</button>
        <button className="btn btn-secondary btn-sm mx-1" onClick={incrementDate}>{`Volgende ->`}</button>

        <span className="badge bg-secondary badge-margin">
          <i className="bi bi-person"></i> {totalGuests} personen
        </span>
      </div>
      
      <div className="row mb-3">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Zoeken op naam..."
            value={searchName}
            onChange={e => setSearchName(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Zoeken op uur..."
            value={searchTime}
            onChange={e => setSearchTime(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Zoeken op aantal gasten..."
            value={searchGuests}
            onChange={e => setSearchGuests(e.target.value)}
          />
        </div>
      </div>

      <ReservationList
        endpoint={currentDate}
        name={name}
        searchName={searchName}
        searchTime={searchTime}
        searchGuests={searchGuests}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        setMealTypeParent={setMealType}
        setTotalGuests={setTotalGuests}
      />
    </div>
  );
}

export default DayWait;
