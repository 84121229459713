import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import fr from 'date-fns/locale/fr';
import enUS from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import { format, addMonths, isBefore, isSameDay } from 'date-fns';
import '../css/calendar_view.css';

registerLocale('nl', nl);
registerLocale('fr', fr);
registerLocale('en-US', enUS);

const Calendar = ({ selectedDate, handleDateSelect, slotData, guests, language, fetchSlotData }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    fetchSlotData(currentYear, currentMonth);
  }, [currentYear, currentMonth, fetchSlotData]);

  const handleMonthChange = (date) => {
    const newMonth = date.getMonth() + 1;
    setCurrentMonth(newMonth);
    setCurrentYear(date.getFullYear());
    fetchSlotData(date.getFullYear(), newMonth);
  };

  const checkConsecutiveSlots = (availableTimeslots, guests) => {
    const slots = availableTimeslots.map(Number);
    for (let i = 0; i < slots.length; i++) {
      let isValid = true;
      for (let j = 0; j < 4; j++) {
        if (!slots.includes(slots[i] + j) || slotData[slots[i] + j] < guests) {
          isValid = false;
          break;
        }
      }
      if (isValid) return true;
    }
    return false;
  };

  const dayClassName = (date) => {
    const today = new Date();
    const nextMonth = addMonths(new Date(currentYear, currentMonth - 1), 1);
    const previousMonth = addMonths(new Date(currentYear, currentMonth - 1), -1);

    if (isBefore(date, today) && !isSameDay(date, today)) {
      return 'react-datepicker__day--disabled';
    }
    if (date.getMonth() === nextMonth.getMonth() && date.getFullYear() === nextMonth.getFullYear()) {
      return 'react-datepicker__day--next-month';
    }
    if (date.getMonth() === previousMonth.getMonth() && date.getFullYear() === previousMonth.getFullYear()) {
      return 'react-datepicker__day--next-month';
    }
    if (selectedDate && date.getTime() === selectedDate.getTime()) {
      return 'react-datepicker__day--highlighted-custom';
    }

    const formattedDate = format(date, 'yyyy-MM-dd');

    if (slotData[formattedDate]) {
      const slots = Object.keys(slotData[formattedDate]);
      const availableSlots = Object.keys(slotData[formattedDate]).filter(slot => slotData[formattedDate][slot] >= guests);
      if (checkConsecutiveSlots(availableSlots, guests)) {
        return 'react-datepicker__day--highlighted';
      }
    }
    return 'react-datepicker__day--disabled';
  };

  const isDaySelectable = (date) => {
    const today = new Date();
    if (isBefore(date, today) && !isSameDay(date, today)) {
      return false;
    }
    const formattedDate = format(date, 'yyyy-MM-dd');
    const availableSlots = Object.keys(slotData[formattedDate] || {}).filter(slot => slotData[formattedDate][slot] >= guests);
    return checkConsecutiveSlots(availableSlots, guests);
  };

  return (
    <div className="react-datepicker-sizing">
      <DatePicker
        inline
        locale={language === 'NL' ? 'nl' : language === 'FR' ? 'fr' : 'en-US'}
        selected={selectedDate}
        onChange={(date) => {
          if (isDaySelectable(date)) {
            handleDateSelect(date);
          }
        }}
        onMonthChange={handleMonthChange}
        dayClassName={dayClassName}
        className="custom-datepicker"
        minDate={new Date()}
      />
    </div>
  );
};

export default Calendar;
