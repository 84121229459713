import React, { useState, useEffect } from 'react';
import TableSlots from './tableslots';
import RightSideBar from './rightsidebar';
import '../../css/tableslots.css';
import useDataRetriever from '../../../utils/useDataRetriever';

function TableView() {
  const initialAreaSize = { width: 10, height: 5 };
  const [areaSize, setAreaSize] = useState(initialAreaSize);
  const [grid, setGrid] = useState(Array(initialAreaSize.height).fill().map(() => Array(initialAreaSize.width).fill(0)));
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [hoveredTable, setHoveredTable] = useState({ tableId: null, color: null, reservationName: null });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [reservedTables, setReservedTables] = useState([]);
  const [reservationNames, setReservationNames] = useState({});
  const [highlightedTable, setHighlightedTable] = useState({ tableId: null, color: null });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hoveredReservationTables, setHoveredReservationTables] = useState(null);

  const { data: tablesData, error: tablesError, loading: tablesLoading } = useDataRetriever(window.baseDomain + 'tables/');
  const { data: mapData, error: mapError, loading: mapLoading } = useDataRetriever(window.baseDomain + 'restaurants/map/');

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const formattedDate = getFormattedDate(currentDate);
  const { data: adminData, error: adminError, loading: adminLoading } = useDataRetriever(`${window.baseDomain}reservations/${formattedDate}/`);
  const { data: additionalTablesData, error: additionalTablesError, loading: additionalTablesLoading } = useDataRetriever(window.baseDomain + `tables/slot/${formattedDate}/`);

  useEffect(() => {
    if (!mapLoading && !mapError && mapData && mapData.length > 0) {
      const { width, height } = mapData[0];
      setAreaSize({ width, height });
    }
  }, [mapData, mapLoading, mapError]);

  useEffect(() => {
    if (areaSize.width > 0 && areaSize.height > 0) {
      setGrid(Array(areaSize.height).fill().map(() => Array(areaSize.width).fill(0)));
    }
  }, [areaSize]);

  useEffect(() => {
    if (!tablesLoading && !tablesError && tablesData) {
      setTables(tablesData);
    }
  }, [tablesData, tablesLoading, tablesError]);

  useEffect(() => {
    if (!additionalTablesLoading && !additionalTablesError && additionalTablesData) {
      console.log('Additional Tables Data:', additionalTablesData);
    }
  }, [additionalTablesData, additionalTablesLoading, additionalTablesError]);

  useEffect(() => {
    if (!adminLoading && !adminError && adminData) {
      console.log('Admin Data:', adminData);
    }
  }, [adminData, adminLoading, adminError]);

  const handleTableClick = (tableId) => {
    setSelectedTable(tableId);
  };

  const handleHoverReservation = (tableId, color, reservationName) => {
    setHoveredTable({ tableId, color, reservationName });
  };

  const handleHighlightTable = (tableId, color) => {
    setHighlightedTable({ tableId, color });
  };

  const updateReservedTables = (reservations, selectedSlot) => {
    const reservedTableIds = reservations.flatMap(reservation => {
      const mappings = additionalTablesData.filter(map => map.reservation === reservation.id);
      if (mappings.some(mapping => selectedSlot >= reservation.start_time && selectedSlot <= reservation.start_time + 3)) {
        return mappings.map(mapping => ({ tableId: mapping.table, reservationName: `${reservation.first_name} ${reservation.last_name}` }));
      }
      return [];
    });

    const reservedTableIdsOnly = reservedTableIds.map(entry => entry.tableId);
    const reservationNamesMap = reservedTableIds.reduce((acc, entry) => {
      acc[entry.tableId] = entry.reservationName;
      return acc;
    }, {});

    setReservedTables(reservedTableIdsOnly);
    setReservationNames(reservationNamesMap);
  };

  useEffect(() => {
    if (selectedSlot !== null && adminData && additionalTablesData) {
      updateReservedTables(adminData, selectedSlot);
    }
  }, [selectedSlot, adminData, additionalTablesData]);

  const handleDateChange = (days) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  };

  const handleHoverTable = (tableIds, color) => {
    setHoveredReservationTables({ tableIds, color });
  };

  return (
    <div className="container">
      <RightSideBar 
        selectedSlot={selectedSlot} 
        onHoverReservation={handleHoverReservation} 
        updateReservedTables={(reservations) => updateReservedTables(reservations, selectedSlot)} 
        onHighlightTable={handleHighlightTable}
        adminData={adminData}
        additionalTablesData={additionalTablesData}
        tablesData={tablesData}
        currentDate={currentDate}
        onDateChange={handleDateChange}
        onHoverTable={handleHoverTable}
      />
      <div className="content">
        <h1>Tafelslots</h1>
        <TableSlots selectedSlot={selectedSlot} onSelectSlot={setSelectedSlot} currentDate={currentDate} />
        <div className="d-grid gap-2" style={{ gridTemplateColumns: `repeat(${areaSize.width}, 1fr)`, position: 'relative' }}>
          {grid.map((row, i) =>
            row.map((col, j) => (
              <div key={`${i}-${j}`} className={`square ${col ? 'bg-success' : 'bg-secondary'}`}>
                <div className="square-content"></div>
              </div>
            ))
          )}
          {tables.map((table) => (
            <div
              key={table.id}
              className={`table ${selectedTable === table.id ? 'selected' : ''} 
                ${hoveredTable.tableId === table.id ? 'hovered' : ''} 
                ${highlightedTable.tableId === table.id ? 'highlighted' : ''}`}
              style={{
                top: `${table.y / areaSize.height * 100}%`,
                left: `${table.x / areaSize.width * 100}%`,
                width: `${table.width / areaSize.width * 100}%`,
                height: `${table.height / areaSize.height * 100}%`,
                backgroundColor: 
                  hoveredTable.tableId === table.id ? hoveredTable.color :
                  highlightedTable.tableId === table.id ? highlightedTable.color :
                  (hoveredReservationTables && hoveredReservationTables.tableIds && hoveredReservationTables.tableIds.includes(table.id)) ? hoveredReservationTables.color :
                  reservedTables.includes(table.id) ? '#17c653' : '#ff0000',
                border: `2px solid ${
                  hoveredTable.tableId === table.id ? hoveredTable.color :
                  highlightedTable.tableId === table.id ? highlightedTable.color :
                  (hoveredReservationTables && hoveredReservationTables.tableIds && hoveredReservationTables.tableIds.includes(table.id)) ? hoveredReservationTables.color :
                  reservedTables.includes(table.id) ? '#17c653' : '#ff0000'
                }`,
                boxShadow: 
                  hoveredTable.tableId === table.id ? `0 4px 8px ${hoveredTable.color}` :
                  highlightedTable.tableId === table.id ? `0 4px 8px ${highlightedTable.color}` : 
                  (hoveredReservationTables && hoveredReservationTables.tableIds && hoveredReservationTables.tableIds.includes(table.id)) ? `0 4px 8px ${hoveredReservationTables.color}` : 
                  'none',
              }}
              onClick={() => handleTableClick(table.id)}
            >
              <div>{table.name}</div>
              <div style={{ fontSize: '0.75em' }}>{reservationNames[table.id]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TableView;
