import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import '../css/opening.css';
import '../css/general.css';

const Opening = ({ onPageChange }) => {
  const openingHoursUrl = `${window.baseDomain}restaurants/openinghours/`;
  const { data: openingHoursData, error, loading } = useDataRetriever(openingHoursUrl);

  const [breakfastTitle, setBreakfastTitle] = useState('');
  const [lunchTitle, setLunchTitle] = useState('');
  const [dinnerTitle, setDinnerTitle] = useState('');

  const classifySlots = (slots) => {
    const breakfastSlots = new Set([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21]);
    const lunchSlots = new Set([22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32]);
    const dinnerSlots = new Set([33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48]);

    const breakfast = slots.some(slot => breakfastSlots.has(slot.slot));
    const lunch = slots.some(slot => lunchSlots.has(slot.slot));
    const dinner = slots.some(slot => dinnerSlots.has(slot.slot));

    return { breakfast, lunch, dinner };
  };

  useEffect(() => {
    if (openingHoursData && openingHoursData.length > 0) {
      const classifiedSlots = classifySlots(openingHoursData);
      setBreakfastTitle(classifiedSlots.breakfast ? '' : 'Inactief');
      setLunchTitle(classifiedSlots.lunch ? '' : 'Inactief');
      setDinnerTitle(classifiedSlots.dinner ? '' : 'Inactief');
    }
  }, [openingHoursData]);

  const handleButtonClick = (e) => {
    const page = e.currentTarget.getAttribute('data-page');
    onPageChange(page);
  };

  const allInactive = breakfastTitle === 'Inactief' && lunchTitle === 'Inactief' && dinnerTitle === 'Inactief';

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container">
      {allInactive && (
        <div className="alert alert-danger" role="alert">
          Voeg openingstijden toe om nieuwe reservaties te ontvangen.
        </div>
      )}
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-coffee fa-3x mb-3"></i>
              <h5 className="card-title">
                Ontbijt 
                {breakfastTitle && <span className="badge text-bg-secondary ms-2">{breakfastTitle}</span>}
              </h5>
              <p className="card-text">Bekijk de ontbijturen.</p>
              <a 
                data-page="ontbijt" 
                className={`gradient-btn btn ${breakfastTitle ? 'btn-success' : 'btn-primary'}`} 
                onClick={handleButtonClick}
              >
                {breakfastTitle ? 'Activeer' : 'Bekijk Ontbijt'}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-utensils fa-3x mb-3"></i>
              <h5 className="card-title">
                Lunch 
                {lunchTitle && <span className="badge text-bg-secondary ms-2">{lunchTitle}</span>}
              </h5>
              <p className="card-text">Bekijk de lunchuren.</p>
              <a 
                data-page="lunch" 
                className={`gradient-btn btn ${lunchTitle ? 'btn-success' : 'btn-primary'}`} 
                onClick={handleButtonClick}
              >
                {lunchTitle ? 'Activeer' : 'Bekijk Lunch'}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-drumstick-bite fa-3x mb-3"></i>
              <h5 className="card-title">
                Diner 
                {dinnerTitle && <span className="badge text-bg-secondary ms-2">{dinnerTitle}</span>}
              </h5>
              <p className="card-text">Bekijk de dineruren.</p>
              <a 
                data-page="diner" 
                className={`gradient-btn btn ${dinnerTitle ? 'btn-success' : 'btn-primary'}`} 
                onClick={handleButtonClick}
              >
                {dinnerTitle ? 'Activeer' : 'Bekijk Diner'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opening;
