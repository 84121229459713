import React, { useState } from 'react';
import '../css/general.css'
import useDataPatch from '../../utils/useDataPatch';

function Settings() {
  const [formData, setFormData] = useState({
    breakfast_duration_in_minutes: 60,
    lunch_duration_in_minutes: 90,
    dinner_duration_in_minutes: 120,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: parseInt(value, 10),
    });
  };

  const useHandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await useDataPatch(window.baseDomain + 'restaurants/update/', {
        breakfast_duration_in_minutes: formData.breakfast_duration_in_minutes,
        lunch_duration_in_minutes: formData.lunch_duration_in_minutes,
        dinner_duration_in_minutes: formData.dinner_duration_in_minutes,
      });
      console.log('Form submitted successfully:', result);
    } catch (error) {
      console.error('There was a problem with the submission:', error);
    }
  };

  const options = [];
  for (let i = 30; i <= 360; i += 30) {
    options.push(<option key={i} value={i}>{i}</option>);
  }

  return (
    <div className="container">
      <h1>Speciale Instellingen</h1>

      <form onSubmit={useHandleSubmit}>
        <div className="mb-3">
          <label htmlFor="breakfast_duration_in_minutes" className="form-label">Ontbijt Duratie (in minuten)</label>
          <select
            className="form-control"
            id="breakfast_duration_in_minutes"
            name="breakfast_duration_in_minutes"
            value={formData.breakfast_duration_in_minutes}
            onChange={handleChange}
          >
            {options}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="lunch_duration_in_minutes" className="form-label">Lunch Duratie (minuten)</label>
          <select
            className="form-control"
            id="lunch_duration_in_minutes"
            name="lunch_duration_in_minutes"
            value={formData.lunch_duration_in_minutes}
            onChange={handleChange}
          >
            {options}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="dinner_duration_in_minutes" className="form-label">Diner Duratie (minuten)</label>
          <select
            className="form-control"
            id="dinner_duration_in_minutes"
            name="dinner_duration_in_minutes"
            value={formData.dinner_duration_in_minutes}
            onChange={handleChange}
          >
            {options}
          </select>
        </div>
        <button type="submit" className="btn btn-primary">Update</button>
      </form>
    </div>
  );
}

export default Settings;
