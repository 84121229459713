import React, { useState, useEffect } from 'react';
import '../css/opening.css';
import '../css/general.css';
import { slotToTime } from '../../utils/converters';
import useDataRetriever from '../../utils/useDataRetriever';
import dataSaver from '../../utils/useDataSaver';

const Diner = () => {
  const dayTime = 'E';
  const startTime = 34;
  const endTime = 47;
  const [data, setData] = useState([]);
  const [days, setDays] = useState([]);
  const [title, setTitle] = useState('Inactief');

  const { data: fetchedData, error, loading } = useDataRetriever(window.baseDomain + `restaurants/days/${dayTime}/`);

  const putDay = async (id, updatedDay) => {
    const body = {
      id: id,
      begin: updatedDay.opening,
      end: updatedDay.closing,
      day_number: updatedDay.day_number,
      time_of_day: dayTime,
      closed: updatedDay.closed
    };

    try {
      await dataSaver(window.baseDomain + `restaurants/days/update-delete/${id}/`, body);
    } catch (error) {
      console.error('Error updating day:', error);
    }
  };

  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (data && data.length > 0) {
      const fetchedDays = [
        { id: null, day_number: 1, name: 'Maandag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 2, name: 'Dinsdag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 3, name: 'Woensdag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 4, name: 'Donderdag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 5, name: 'Vrijdag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 6, name: 'Zaterdag', opening: 0, closing: 0, closed: true },
        { id: null, day_number: 7, name: 'Zondag', opening: 0, closing: 0, closed: true }
      ];

      data.forEach(slot => {
        const dayIndex = slot.day_number - 1;
        fetchedDays[dayIndex].id = slot.id;
        fetchedDays[dayIndex].opening = slot.begin;
        fetchedDays[dayIndex].closing = slot.end;
        fetchedDays[dayIndex].closed = slot.closed;
      });

      setDays(fetchedDays);
    }
  }, [data]);

  useEffect(() => {
    const anyDayOpen = days.some(day => day.opening && !day.closed);
    setTitle(anyDayOpen ? '' : 'Inactief');
  }, [days]);

  const toggleDayClosed = (index) => {
    const newDays = [...days];
    newDays[index].closed = !newDays[index].closed;
    if (!newDays[index].closed) {
      newDays[index].opening = startTime;
      newDays[index].closing = endTime;
    }
    setDays(newDays);
    putDay(newDays[index].id, newDays[index]);
  };

  const handleTimeChange = (index, time, value) => {
    const newDays = [...days];
    newDays[index][time] = parseInt(value);
    setDays(newDays);
    putDay(newDays[index].id, newDays[index]);
  };

  const generateTimeOptions = (valueShift) => {
    const options = [];
    for (let i = startTime; i <= endTime; i++) {
      options.push(<option key={i} value={i}>{slotToTime(i + valueShift)}</option>);
    }
    return options;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container mt-25">
      <h1>Dineruren <span className="badge text-bg-secondary">{title}</span></h1>
      
      <div className="alert alert-primary mb-12" role="alert">
        Opgelet. De standaard duur van een reservatie is 2 uur. Als u tot 19u wilt laten boeken, stel dan de openingstijden in tot 21u.
      </div>

      {days.map((day, index) => (
        <div key={index} className="row align-items-center mb-3">
          <div className="col-md-2">
            <strong>{day.name}</strong>
          </div>
          <div className="col-md-3">
            <select
              className="form-control"
              id={`begin-${index}`}
              value={day.opening}
              onChange={(e) => handleTimeChange(index, 'opening', e.target.value)}
              disabled={day.closed}
            >
              {generateTimeOptions(0)}
            </select>
          </div>
          <div className="col-md-3">
            <select
              className="form-control"
              id={`end-${index}`}
              value={day.closing}
              onChange={(e) => handleTimeChange(index, 'closing', e.target.value)}
              disabled={day.closed}
            >
              {generateTimeOptions(1)}
            </select>
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              onClick={() => toggleDayClosed(index)}
            >
              {day.closed ? 'Open Dag' : 'Sluit Dag'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Diner;
