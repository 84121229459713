import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Alert, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import Calendar from './calender_view';
import Timeslot from './timeslots';
import ReservationModal from './reservation_modal';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';
import dataPost from '../../utils/useDataPost';
import { slotToTime } from '../../utils/converters';
import '../css/nieuw.css';

registerLocale('nl', nl);
registerLocale('fr', fr);

const languageOptions = [
  { value: 'NL', label: 'NL' },
  { value: 'EN', label: 'EN' },
  { value: 'FR', label: 'FR' }
];

const Nieuw = ({ onPageChange }) => {
  const [language, setLanguage] = useState('NL');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotData, setSlotData] = useState({});
  const [guests, setGuests] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [alertMessage, setAlertMessage] = useState(null);
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [displayedLabel, setDisplayedLabel] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [extraInfo, setExtraInfo] = useState('');
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const guestsRef = useRef(null);

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(today.getFullYear());

  const startOfMonth = `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`;
  const endOfMonth = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${new Date(currentYear, currentMonth, 0).getDate()}`;

  let url = `${window.baseDomain}restaurants/admin-slots/${startOfMonth}/${endOfMonth}/${selectedLabelId}`;

  const useDataRetriever = (url) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': 'JWT ' + localStorage.getItem('authKey'),
            },
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          setData(data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [url]);

    return { data, error, loading };
  };

  let { data, error, loading } = useDataRetriever(url);
  const { data: labelData } = useDataRetriever(`${window.baseDomain}tables/admin-label`);

  useEffect(() => {
    if (labelData) {
      const labelsWithCapacity = labelData.map(label => ({
        value: label.id,
        label: label.display_description,
        max_capacity: label.max_capacity
      }));

      setLabels(labelsWithCapacity);

      if (labelsWithCapacity.length > 0) {
        const defaultLabel = labelsWithCapacity.reduce((max, label) => (label.max_capacity > max.max_capacity ? label : max), labelsWithCapacity[0]);
        setSelectedLabel(defaultLabel.value);
        setSelectedLabelId(defaultLabel.value);
        setDisplayedLabel(defaultLabel.label);
        if (labelsWithCapacity.length === 1) {
          setGuests(Math.min(guests, defaultLabel.max_capacity));
        }
      }
    }
  }, [labelData]);

  useEffect(() => {
    if (data) {
      setSlotData((prevSlotData) => ({ ...prevSlotData, ...data }));
    }
    if (error) {
      console.error('Error fetching slot data:', error);
    }
  }, [data, error]);

  useEffect(() => {
    if (selectedLabelId) {
      url = `${window.baseDomain}restaurants/admin-slots/${startOfMonth}/${endOfMonth}/${selectedLabelId}`;

      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': 'JWT ' + localStorage.getItem('authKey'),
            },
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const data = await response.json();
          setSlotData(data);
        } catch (err) {
          console.error('Error fetching slot data:', err);
        }
      };

      fetchData();
    }
  }, [currentYear, currentMonth, selectedLabelId]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleGuestChange = (event) => {
    setGuests(Number(event.target.value));
  };

  const handleLanguageChange = (selectedOption) => {
    setLanguage(selectedOption.value);
  };

  const handleLabelChange = async (selectedOption) => {
    setSelectedLabel(selectedOption.value);
    setSelectedLabelId(selectedOption.value);
    setDisplayedLabel(selectedOption.label);

    const selectedLabel = labels.find(label => label.value === selectedOption.value);
    if (selectedLabel && guests > selectedLabel.max_capacity) {
      setGuests(selectedLabel.max_capacity);
    }
  };

  const handleSubmit = async () => {
    const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null;
    const cleanMenu = selectedMenu.split('__rules')[0];

    const reservationData = {
      start_time: selectedSlot !== null ? selectedSlot : null,
      date: format(selectedDate, 'yyyy-MM-dd'),
      guests,
      restaurant: 8,
      phone,
      email,
      first_name: firstName,
      last_name: lastName,
      language: language,
      table_label: selectedLabelId,
      menu: cleanMenu || '',
      extra_info: extraInfo || '',
      timeslot_name: slotToTime(selectedSlot),
    };

    console.log(reservationData);

    try {
      setIsLoading(true);
      setProgress(0);

      const simulateProgress = () => {
        setTimeout(() => setProgress(33), 1000);
        setTimeout(() => setProgress(66), 2000);
        setTimeout(() => setProgress(100), 3000);
      };

      simulateProgress();

      const response = await dataPost(window.baseDomain + 'reservations/create-admin/', reservationData);

      const alertMessage = { type: 'success', message: 'De reservatie is succesvol aangemaakt en de klant heeft een bevestigingsemail ontvangen.' };
      setShowModal(false);
      window.alertMessage = alertMessage;
      window.reservationData = reservationData;
      window.displayedLabel = displayedLabel;
      onPageChange("reservation-summary", { reservationData, alertMessage });
    } catch (error) {
      console.error('Error submitting reservation:', error);
      setAlertMessage({ type: 'danger', message: 'Error submitting reservation' });
    } finally {
      setIsLoading(false);
      setProgress(0);
    }
  };

  useEffect(() => {
    if (selectedSlot !== null) {
      setShowModal(true);
    }
  }, [selectedSlot]);

  const handleGuestsClick = () => {
    setShowModal(false);
    setTimeout(() => {
      guestsRef.current.focus();
      guestsRef.current.click();
    }, 300);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedSlot(null);
  };

  const fetchSlotData = (year, month) => {
    setCurrentYear(year);
    setCurrentMonth(month);
  };

  const fetchMenuItems = async () => {
    try {
      const response = await fetch(window.baseDomain + `tables/menu/`, {
        method: 'GET',
        headers: {
          'Authorization': 'JWT ' + localStorage.getItem('authKey'),
        },
      });
      const data = await response.json();
      setMenuItems(data);
    } catch (error) {
      console.error('Error fetching menu items:', error);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  return (
    <Container className="reservation-container">
      <div className='fullscreen-bg-reservation'></div>
      <div className='frame'></div>

      <Row className="justify-content-md-center align-items-center">
        <Col xs={6} sm={9} md={6} lg={10} xl={6}>
          <h1>{language === 'NL' ? 'Reserveer Nu' : language === 'FR' ? 'Réservez Maintenant' : 'Reserve Now'}</h1>
        </Col>
        <Col xs={6} sm={3} md={2} lg={2} xl={2} className="language-selector-col">
          <Select
            options={languageOptions}
            defaultValue={languageOptions.find(option => option.value === language)}
            onChange={handleLanguageChange}
            className="language-selector"
          />
        </Col>
      </Row>

      {alertMessage && (
        <Row className="justify-content-md-center">
          <Col xs={12} sm={12} md={8} lg={12} xl={8}>
            <Alert variant={alertMessage.type}>
              {alertMessage.message}
            </Alert>
          </Col>
        </Row>
      )}

      <Row className="guests-row justify-content-md-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8}>
          <Form>
            <Form.Label htmlFor="formNumberOfGuests">
              {language === 'NL' ? 'Aantal Gasten' : language === 'FR' ? 'Nombre de Convives' : 'Number of Guests'}
            </Form.Label>
            <Form.Group controlId="formNumberOfGuests">
              <Form.Control
                as="select"
                value={guests}
                onChange={handleGuestChange}
                ref={guestsRef}
              >
                {Array.from({ length: 70 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {language === 'NL' ? `${i + 1} ${i + 1 === 1 ? 'persoon' : 'personen'}` : language === 'FR' ? `${i + 1} ${i + 1 === 1 ? 'personne' : 'personnes'}` : `${i + 1} ${i + 1 === 1 ? 'person' : 'people'}`}
                </option>
              ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {labels.length > 1 && (
        <Row className="selection-box mt-7 justify-content-md-center align-items-center">
          <Col xs={12} sm={12} md={8} lg={12} xl={8}>
            <Form.Label htmlFor="formNumberOfGuests">
              {language === 'NL' ? 'Selecteer Reservatietype' : language === 'FR' ? 'Sélectionnez le Type de Réservation' : 'Select Reservation Type'}
            </Form.Label>
            <Select
              options={labels}
              value={labels.find(option => option.value === selectedLabel)}
              onChange={handleLabelChange}
              className="label-selector"
            />
          </Col>
        </Row>
      )}

      <Row className="row justify-content-md-center align-items-start">
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Calendar
            selectedDate={selectedDate}
            handleDateSelect={handleDateSelect}
            slotData={slotData}
            guests={guests}
            language={language}
            fetchSlotData={fetchSlotData}
          />
        </Col>
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Timeslot
            selectedDate={selectedDate}
            slotData={slotData}
            guests={guests}
            setSelectedSlot={setSelectedSlot}
            language={language}
          />
        </Col>
      </Row>

      <ReservationModal
        showModal={showModal}
        setShowModal={handleModalClose}
        guests={guests}
        selectedDate={selectedDate}
        selectedSlot={selectedSlot}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        handleSubmit={handleSubmit}
        handleGuestsClick={handleGuestsClick}
        language={language}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        menuItems={menuItems}
        extraInfo={extraInfo}
        setExtraInfo={setExtraInfo}
        isLoading={isLoading}
        progress={progress}
      />
    </Container>
  );
};

export default Nieuw;
