import React, { useState, useRef, useEffect } from 'react';
import useDataRetriever from '../../../utils/useDataRetriever';
import { slotToTime, INTERVALS } from '../../../utils/converters';
import { useReactToPrint } from 'react-to-print';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PrintSelectionAlert from './print_alert';
import ColumnSelection from './column_selection';
import FilterShiftAlert from './filter_shift';
import '../../css/reservation_list.css';

const ReservationList = ({ endpoint, name, searchName, searchTime, searchGuests, showAlert, setShowAlert, setMealTypeParent, setTotalGuests, onPageChange }) => {
  const [mealType, setMealType] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterAlert, setFilterAlert] = useState(false);
  const [showExtraInfo, setShowExtraInfo] = useState(true);
  const [sortType, setSortType] = useState(null);
  const [sortAlert, setSortAlert] = useState(false);
  const [columnSelectionAlert, setColumnSelectionAlert] = useState(false);
  const componentRef = useRef();

  const { data, error, loading } = useDataRetriever(window.baseDomain + 'reservations/' + endpoint + '/');

  const blacklistedIPs = ['88.82.37.102', '185.41.237.221', '178.118.103.214', '178.116.20.219'];

  const defaultColumns = {
    guests: true,
    time: true,
    name: true,
    email: true,
    phone: true,
    table: true,
    menu: true,
    extraInfo: true,
  };

  const getStoredColumnSettings = () => {
    const storedSettings = localStorage.getItem('reservationColumnSettings');
    return storedSettings ? JSON.parse(storedSettings) : defaultColumns;
  };

  const [selectedColumns, setSelectedColumns] = useState(getStoredColumnSettings);

  const saveColumnSettings = (settings) => {
    localStorage.setItem('reservationColumnSettings', JSON.stringify(settings));
  };

  useEffect(() => {
    setHasError(false);
  }, [endpoint]);

  useEffect(() => {
    if (error) {
      setHasError(true);
      setFilteredData([]);
    }
  }, [error]);

  useEffect(() => {
    if (!loading && data && !hasError) {
      const sortedData = [...data].sort((a, b) => a.start_time - b.start_time);
      
      // Apply the name, time, and guests filters
      const filtered = sortedData.filter(
        reservation =>
          (reservation.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
            reservation.last_name.toLowerCase().includes(searchName.toLowerCase())) &&
          (!searchTime || matchTime(reservation.start_time, searchTime)) &&
          reservation.guests.toString().includes(searchGuests)
      );
      
      // Apply the meal type filter
      const mealFiltered = mealType && mealType !== 'Hele Dag'
        ? filtered.filter(
          reservation =>
            reservation.start_time >= INTERVALS[mealType].begin &&
            reservation.start_time <= INTERVALS[mealType].end
        )
        : filtered;
      
      // Update the filtered data and recalculate total guests
      setFilteredData(mealFiltered);
  
      const totalGuests = mealFiltered.reduce((sum, reservation) => sum + reservation.guests, 0);
      setTotalGuests(totalGuests);
    }
  }, [data, hasError, loading, searchName, searchTime, searchGuests, mealType]);  

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${name} Reservations - ${endpoint} - ${mealType}`,
    onBeforePrint: () => { setSortAlert(true); setColumnSelectionAlert(false); setSortAlert(false); },
    onAfterPrint: () => {
      setMealType("Hele Dag");
      setMealTypeParent("Hele Dag");
      setSortType(null);
    },
  });

  const handlePrintSelect = (selectedMealType) => {
    setMealType(selectedMealType);
    setShowAlert(false);
    setSortAlert(true);
    setMealTypeParent(selectedMealType);
  };

  const handleSortSelect = (selectedSortType) => {
    setSortType(selectedSortType);
    setSortAlert(false);
    setColumnSelectionAlert(true);
  };

  const handleFilterSelect = (selectedMealType) => {
    setMealType(selectedMealType);
    setFilterAlert(false);
    setMealTypeParent(selectedMealType);
  };

  const matchTime = (reservationTime, searchTime) => {
    const formattedReservationTime = slotToTime(reservationTime).slice(0, 2).replace(/^0/, '');
    const formattedSearchTime = searchTime.replace(/^0/, '');
    return formattedReservationTime === formattedSearchTime;
  };

  const mealFilteredData = mealType
    ? filteredData.filter(
      reservation =>
        reservation.start_time >= INTERVALS[mealType].begin && reservation.start_time <= INTERVALS[mealType].end
    )
    : filteredData;

  const sortedMealFilteredData = sortType === 'Gasten'
    ? [...mealFilteredData].sort((a, b) => a.guests - b.guests)
    : [...mealFilteredData].sort((a, b) => a.start_time - b.start_time);

  const handleColumnSelection = (column) => {
    const updatedColumns = { ...selectedColumns, [column]: !selectedColumns[column] };
    setSelectedColumns(updatedColumns);
    saveColumnSettings(updatedColumns);
  };

  const visibleColumnCount = Object.values(selectedColumns).filter(Boolean).length;
  const columnWidth = `${100 / visibleColumnCount}%`;

  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <div>
      <div ref={componentRef}>
        <h2 className="print-only" style={{ paddingBottom: "-4rem", marginBottom: "-4rem" }}>Reservaties op {endpoint} - {mealType}</h2>

        {sortType && (
          <table className="ex-table js-ex-table print-only" aria-label="Expandable table example">
            <thead className="ex-table__header">
              <tr className="ex-table__row">
                {selectedColumns.guests && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Gasten</th>}
                {selectedColumns.time && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Uur</th>}
                {selectedColumns.name && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Naam</th>}
                {selectedColumns.email && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Email</th>}
                {selectedColumns.phone && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Telefoon</th>}
                {selectedColumns.table && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Zitplaats</th>}
                {selectedColumns.menu && <th className="ex-table__cell" scope="col" style={{ width: columnWidth }}>Menu</th>}
              </tr>
            </thead>
            <tbody className="ex-table__body js-ex-table__body">
              {sortedMealFilteredData.length > 0 ? (
                sortedMealFilteredData.map((reservation, index) => {
                  const isBlacklisted = blacklistedIPs.includes(reservation.ip_address);
                  return (
                    <React.Fragment key={index}>
                      <tr className={`ex-table__row ${isBlacklisted ? 'blacklisted-reservation' : ''}`}>
                        {selectedColumns.guests && <td className="ex-table__cell" style={{ width: columnWidth, fontSize: "1.2rem" }}><strong>{reservation.guests}</strong></td>}
                        {selectedColumns.time && <td className="ex-table__cell" style={{ width: columnWidth }}><strong>{slotToTime(reservation.start_time)}</strong></td>}
                        {selectedColumns.name && <td className="ex-table__cell" style={{ width: columnWidth }}>{reservation.first_name + ' ' + reservation.last_name}</td>}
                        {selectedColumns.email && <td className="ex-table__cell" style={{ width: columnWidth }}>{reservation.email}</td>}
                        {selectedColumns.phone && <td className="ex-table__cell" style={{ width: columnWidth }}>{reservation.phone}</td>}
                        {selectedColumns.table && <td className="ex-table__cell" style={{ width: columnWidth }}>{reservation.table_label}</td>}
                        {selectedColumns.menu && <td className="ex-table__cell" style={{ width: columnWidth }}>{reservation.menu}</td>}
                      </tr>
                      {showExtraInfo && (reservation.extra_info || isBlacklisted) && selectedColumns.extraInfo && (
                        <tr className="ex-table__row">
                          <td className="ex-table__cell" colSpan={visibleColumnCount}>
                            <strong>Extra info voor {reservation.first_name} {reservation.last_name}:</strong> {reservation.extra_info}
                            <div className="icon-container">
                              {reservation.extra_info && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{reservation.extra_info}</Tooltip>}
                                >
                                  <i className="bi bi-info-circle icon print-hidden"></i>
                                </OverlayTrigger>
                              )}
                              {isBlacklisted && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Deze reservatie is mogelijks spam.</Tooltip>}
                                >
                                  <i className="bi bi-exclamation-triangle-fill icon" style={{ color: 'yellow' }}></i>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr className="ex-table__row">
                  <td className="ex-table__cell" colSpan={visibleColumnCount}>
                    Geen reservaties op deze dag.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {!sortType && (
          <table className="ex-table js-ex-table" aria-label="Expandable table example">
            <thead className="ex-table__header">
              <tr className="ex-table__row">
                <th className="ex-table__cell" scope="col">Gasten</th>
                <th className="ex-table__cell" scope="col">Uur</th>
                <th className="ex-table__cell" scope="col">Naam</th>
                <th className="ex-table__cell" scope="col">Email</th>
                <th className="ex-table__cell" scope="col">Telefoon</th>
                <th className="ex-table__cell" scope="col">Zitplaats</th>
                <th className="ex-table__cell" scope="col">Menu</th>
                <th className="ex-table__cell" scope="col">Extra Info</th>
              </tr>
            </thead>
            <tbody className="ex-table__body js-ex-table__body">
              {sortedMealFilteredData.length > 0 ? (
                sortedMealFilteredData.map((reservation, index) => {
                  const isBlacklisted = blacklistedIPs.includes(reservation.ip_address);
                  return (
                    <React.Fragment key={index}>
                      <tr className={`ex-table__row ${isBlacklisted ? 'blacklisted-reservation' : ''}`}>
                        <td className="ex-table__cell hidden_desktop" role="cell" colSpan="5">
                          <span className="ex-table__title">{reservation.first_name} {reservation.last_name} - {reservation.guests} gasten</span>
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Gasten:</span> {reservation.guests}
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Uur:</span> {slotToTime(reservation.start_time)}
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Naam:</span> {reservation.first_name + ' ' + reservation.last_name}
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Email:</span>
                          <a
                          href="#"
                          onClick={() => {
                            window.currentNameSelection = reservation.first_name + ' ' + reservation.last_name;
                            window.currentEmailSelection = reservation.email;
                            onPageChange("profile-detail")
                          }}
                          rel="noopener noreferrer"
                          style={{ color: '#327aed' }}
                        >
                          {reservation.email}
                        </a>

                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Telefoon:</span> {reservation.phone}
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Zitplaats:</span> {reservation.table_label}
                        </td>
                        <td className="ex-table__cell" role="cell">
                          <span className="ex-table__label js-ex-table__label" aria-hidden="true">Menu:</span> {reservation.menu}
                        </td>
                        {showExtraInfo && (reservation.extra_info || isBlacklisted) && (
                          <td className="ex-table__cell" role="cell">
                            <div className="icon-container">
                              {reservation.extra_info && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>{reservation.extra_info}</Tooltip>}
                                >
                                  <i className="bi bi-info-circle icon"></i>
                                </OverlayTrigger>
                              )}
                              {isBlacklisted && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Deze reservatie is mogelijks spam.</Tooltip>}
                                >
                                  <i className="bi bi-exclamation-triangle-fill icon" style={{ color: '#ff9900' }}></i>
                                </OverlayTrigger>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })
              ) : (
                <tr className="ex-table__row">
                  <td className="ex-table__cell" colSpan="9">
                    Geen reservaties op deze dag.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <div className="row print-buttons">
        <div className="col-6">
          <button className="btn btn-secondary w-100" onClick={() => setShowAlert(true)}>
            <i className="bi bi-printer"></i> Print
          </button>
        </div>
        <div className="col-6">
          <button className="btn btn-secondary w-100" onClick={() => setFilterAlert(true)}>
            <i className="bi bi-filter"></i> Filter Shift
          </button>
        </div>
      </div>

      {showAlert && (
        <PrintSelectionAlert
        setShowAlert={setShowAlert}
        handlePrintSelect={handlePrintSelect}
      />
      )}

      {sortAlert && (
        <div className="custom-alert sort-alert">
          <div className="custom-alert-content">
            <h5>Sorteer Volgorde</h5>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }} className="custom-alert-actions">
              <button className="btn btn-primary w-100 mb-2" onClick={() => handleSortSelect('Uur')}>Sorteer op Uur</button>
              <button className="btn btn-primary w-100 mb-2" onClick={() => handleSortSelect('Gasten')}>Sorteer op Gasten</button>
              <button className="btn btn-secondary w-100 mt-3" onClick={() => { setSortAlert(false); setMealType("Hele Dag"); setMealTypeParent("Hele Dag"); setSortType(null) }}>Annuleer</button>
            </div>
          </div>
        </div>
      )}

      {columnSelectionAlert && (
        <ColumnSelection
        selectedColumns={selectedColumns}
        handleColumnSelection={handleColumnSelection}
        handlePrint={handlePrint}
        setColumnSelectionAlert={setColumnSelectionAlert}
      />
      )}


      {filterAlert && (
        <FilterShiftAlert
        handleFilterSelect={handleFilterSelect}
        setFilterAlert={setFilterAlert}
        setMealType={setMealType}
        setMealTypeParent={setMealTypeParent}
        setSortType={setSortType}
      />
      )}
    </div>
  );
};

export default ReservationList;
