import { useState, useEffect } from 'react';
import handleRefreshToken from './handleRefreshToken';

const useDataRetriever = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': 'JWT ' + localStorage.getItem('authKey'),
          },
        });

        if (response.status === 401) {
          await handleRefreshToken();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }        

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, error, loading };
};

export default useDataRetriever;
