import React, { useState, useRef, useEffect } from 'react';
import { slotToTime } from '../../../utils/converters';
import '../../css/tableslots.css';

const getDateInDutch = (date) => {
  const day = date.getDate();
  const months = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
  ];
  const month = months[date.getMonth()];
  return `${day} ${month}`;
};

const TableSlots = ({ selectedSlot, onSelectSlot, currentDate }) => {
  const slotsContainerRef = useRef(null);
  const dateInDutch = getDateInDutch(currentDate);

  const handleSlotClick = (slot) => {
    onSelectSlot(slot);
    centerSelectedSlot(slot - 14);
  };

  const centerSelectedSlot = (slotIndex) => {
    const slotsContainer = slotsContainerRef.current;
    const slotWidth = 120.85;
    const containerWidth = slotsContainer.offsetWidth;
    const newScrollPosition = slotIndex * slotWidth - (containerWidth / 2 - slotWidth / 2);
    slotsContainer.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
  };

  useEffect(() => {
    if (selectedSlot) {
      centerSelectedSlot(selectedSlot - 14);
    }
  }, [selectedSlot]);

  return (
    <div className="timeline-container" ref={slotsContainerRef}>
      <div className="timeline">
        {Array.from({ length: 34 }, (_, i) => i + 14).map((slot) => (
          <div
            key={slot}
            className={`timeline-slot ${selectedSlot === slot ? 'selected' : ''}`}
            onClick={() => handleSlotClick(slot)}
          >
            <div className="slot-content">
              <div className="slot-subtitle">{dateInDutch}</div>
              <div className="slot-title">{slotToTime(slot)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableSlots;
