import React from 'react';
import { slotToTime } from '../../utils/converters';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';

const ReservationSummary = () => {
  const reservationData = window.reservationData;
  const alertMessage = window.alertMessage;

  return (
    <Container className="reservation-summary-container mt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={8}>
          {alertMessage && (
            <Alert variant={alertMessage.type}>
              {alertMessage.message}
            </Alert>
          )}
          <Card className="shadow-sm">
            <Card.Header className="bg-primary text-white text-center">
              <h2>Reservatiebevestiging</h2>
            </Card.Header>
            <Card.Body>
              {reservationData ? (
                <div>
                  <p><strong>Datum:</strong> {reservationData.date}</p>
                  <p><strong>Tijd:</strong> {slotToTime(reservationData.start_time)}</p>
                  <p><strong>Gasten:</strong> {reservationData.guests}</p>
                  <p><strong>Naam:</strong> {reservationData.first_name} {reservationData.last_name}</p>
                  <p><strong>Email:</strong> {reservationData.email}</p>
                  <p><strong>Telefoon:</strong> {reservationData.phone}</p>
                  <p><strong>Taal:</strong> {reservationData.language}</p>
                  <p><strong>Zitplaats:</strong> {window.displayedLabel}</p>
                </div>
              ) : (
                <p className="text-center">Geen reservatiegegevens beschikbaar.</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReservationSummary;
