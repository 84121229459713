import React from 'react';
import '../css/opening.css';
import '../css/general.css';

const LayoutNav = ({ onPageChange }) => {

  const handleButtonClick = (e) => {
    const page = e.currentTarget.getAttribute('data-page');
    onPageChange(page);
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-coffee fa-3x mb-3"></i>
              <h5 className="card-title">
                Sidebar 
              </h5>
              <p className="card-text">Bewerk de sidebar.</p>
              <a 
                data-page="gradient" 
                className='gradient-btn btn btn-primary' 
                onClick={handleButtonClick}
              >
                Berwerk Sidebar
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-utensils fa-3x mb-3"></i>
              <h5 className="card-title">
                Logo 
              </h5>
              <p className="card-text">Bewerk uw logo.</p>
              <a 
                data-page="logo" 
                className='gradient-btn btn btn-primary' 
                onClick={handleButtonClick}
              >
                Bewerk Logo
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-drumstick-bite fa-3x mb-3"></i>
              <h5 className="card-title">
                Profielfoto
              </h5>
              <p className="card-text">Kies een profielfoto.</p>
              <a 
                data-page="profile" 
                className='gradient-btn btn btn-primary' 
                onClick={handleButtonClick}
              >
                Kies Profielfoto
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutNav;
