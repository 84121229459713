/* global grecaptcha */

import React, { useState } from 'react';
import './css/login.css'; // Reusing the same CSS styles for consistency
import 'react-phone-input-2/lib/style.css'; // Import the default styles for the phone input
import PhoneInput from 'react-phone-input-2';

const Phone = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [captchaToken, setCaptchaToken] = useState(''); // Store the reCAPTCHA token

  const validateForm = () => {
    const newErrors = {};

    if (!restaurantName.trim()) {
      newErrors.restaurantName = 'Restaurantnaam is verplicht.';
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email.trim()) {
      newErrors.email = 'Emailadres is verplicht.';
    } else if (!emailPattern.test(email)) {
      newErrors.email = 'Voer een geldig emailadres in.';
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = 'Telefoonnummer is verplicht.';
    } else if (phoneNumber.length < 10) {
      newErrors.phoneNumber = 'Voer een geldig telefoonnummer in.';
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Trigger reCAPTCHA
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6LfUvicqAAAAACVQKZ0zewG5K6_cY7sF9bTwnK2E', { action: 'LOGIN' });
      setCaptchaToken(token); // Store the token
      console.log('Generated reCAPTCHA Token:', token);
    });
  };

  return (
    <div className="container-fluid mateza-container">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col-md-6 right-section d-flex justify-content-center align-items-center position-relative">
          <form className="sign-in-form w-75" onSubmit={handleSubmit}>
            <h2 className="text-center mb-4">Voer gegevens in</h2>
            <div className="form-group mb-3">
              <label htmlFor="restaurantName">Restaurantnaam</label>
              <input
                type="text"
                className="form-control"
                id="restaurantName"
                placeholder="Voer de naam van uw restaurant in"
                value={restaurantName}
                onChange={(e) => setRestaurantName(e.target.value)}
              />
              {errors.restaurantName && (
                <div className="text-danger mt-2">{errors.restaurantName}</div>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Emailadres</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Voer uw emailadres in"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <div className="text-danger mt-2">{errors.email}</div>
              )}
            </div>
            <div className="form-group mb-4">
              <label htmlFor="phone">Telefoonnummer</label>
              <PhoneInput
                country={'be'}
                value={phoneNumber}
                onChange={setPhoneNumber}
                inputClass="inner-form"
                containerClass="inner-container"
                placeholder="0470 12 34 56"
                onlyCountries={[
                  'at', 'be', 'bg', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hu', 'ie',
                  'it', 'lv', 'lt', 'lu', 'mt', 'nl', 'pl', 'pt', 'ro', 'sk', 'si', 'es', 'se',
                  'gb', 'us', 'au'
                ]}
              />
              {errors.phoneNumber && (
                <div className="text-danger mt-2">{errors.phoneNumber}</div>
              )}
            </div>
            <button type="submit" className="btn btn-form btn-primary btn-block mb-3">
              Start Demo
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Phone;
