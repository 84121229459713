import React, { useEffect } from 'react';
import './css/login.css';
import videoBackground from '../assets/video.webm'; // Add your video file here
import logoImage from '../assets/logo.webp';
import './css/login.css';

const Login = () => {
  const handleSubmit = (username, password, suffix) => {
      fetch(window.baseDomain + 'auth/jwt/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access) {
            const currentDate = new Date().toISOString().split('T')[0];
            localStorage.setItem('loginDate', currentDate);
            localStorage.setItem('restaurantName', username);
            localStorage.setItem('authKey', data.access);
            localStorage.setItem('refreshKey', data.refresh);
            const logoUpdateEvent = new CustomEvent('logoUpdate');
            window.dispatchEvent(logoUpdateEvent);
            window.location.href = window.currentDomain + suffix;
          } else {
            console.error('Error: Invalid credentials');
          }
        })
        .catch((error) => {
          localStorage.setItem('login', 'failure');
          console.error('Error:', error);
        });
    };
    
  useEffect(() => {
    localStorage.removeItem('authKey');
    localStorage.removeItem('refreshKey');

    const form = document.getElementById('signInForm');
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;
      handleSubmit(username, password, "");
    });

    const appWrapper = document.getElementById('kt_app_wrapper');
    const appHeaderContainer = document.getElementById('kt_app_header_container');
    if (appWrapper) appWrapper.classList.add('hidden');
    if (appHeaderContainer) appHeaderContainer.classList.add('hidden');

    return () => {
      form.removeEventListener('submit', handleSubmit);
      if (appWrapper) appWrapper.classList.remove('hidden');
      if (appHeaderContainer) appHeaderContainer.classList.remove('hidden');
    };
  }, []);

  return (
    <div className="container-fluid mateza-container">
      <div className="row vh-100">
        <div className="col-md-6 d-none d-md-flex left-section flex-column justify-content-center align-items-center position-relative">
          <video
            className="video-background"
            src={videoBackground}
            autoPlay
            loop
            muted
          />
          <img src={logoImage} alt="Logo" className="logo img-fluid mb-4" />
          <h1 className="text-center">Mateza Booking 3.0</h1>
          <p className="text-center">
            Mateza Booking is ontwikkeld door Thibault Van de Sompele (23). Als programmeur zet hij zijn ervaring in om boekingssystemen betaalbaarder te maken. Zijn passie voor digitale marketing begon op 15-jarige leeftijd toen hij een ijssalon hielp met social media.
          </p>
        </div>
        <div className="col-md-6 right-section d-flex justify-content-center align-items-center position-relative">
          <form className="sign-in-form w-75" id="signInForm">
            <h2 className="text-center mb-4">Aanmelden</h2>
            <div className="form-group">
              <label htmlFor="username">Gebruikersnaam</label>
              <input type="text" className="form-control" id="username" placeholder="Voer je gebruikersnaam in" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord</label>
              <input type="password" className="form-control" id="password" placeholder="Voer je wachtwoord in" />
            </div>
            <button type="submit" className="btn btn-form btn-primary btn-block">Aanmelden</button>
            <button
            type="button"
            className="btn btn-form btn-secondary btn-block mt-2"
            onClick={() => window.location.href = 'https://cal.com/thibault-vds-vej61p/eerste-kennismaking'}
          >
            Gratis Demo Bekijken
          </button>
            <p className="text-center mt-6">
              Vragen? <a href="https://mateza.net/contact">Neem contact</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
