export const findAreas = (grid, gridWidth, gridHeight) => {
    const visited = Array(gridHeight).fill().map(() => Array(gridWidth).fill(false));
    let areas = [];
  
    const dfs = (x, y, area) => {
      if (x < 0 || y < 0 || x >= gridHeight || y >= gridWidth || visited[x][y] || grid[x][y] === 0) return;
      visited[x][y] = true;
      area.push({ x, y });
      dfs(x + 1, y, area);
      dfs(x - 1, y, area);
      dfs(x, y + 1, area);
      dfs(x, y - 1, area);
    };
  
    for (let i = 0; i < gridHeight; i++) {
      for (let j = 0; j < gridWidth; j++) {
        if (!visited[i][j] && grid[i][j] === 1) {
          let area = [];
          dfs(i, j, area);
          if (area.length > 0) {
            areas.push(area);
          }
        }
      }
    }
    return areas.map(area => {
      let minX = Math.min(...area.map(p => p.x));
      let maxX = Math.max(...area.map(p => p.x));
      let minY = Math.min(...area.map(p => p.y));
      let maxY = Math.max(...area.map(p => p.y));
      return { top: minX, left: minY, width: maxY - minY + 1, height: maxX - minX + 1 };
    });
  };