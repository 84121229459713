import React, { useState, useRef } from 'react';
import useDataRetriever from '../../../utils/useDataRetriever';
import dataDelete from '../../../utils/useDataDelete';
import DeleteModal from '../../../components/delete_modal';
import '../../css/compact_day.css';
import { slotToTime } from '../../../utils/converters';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CompactDay = ({ date }) => {
  const { data, error, loading } = useDataRetriever(window.baseDomain + 'reservations/' + date + '/');
  const [searchTerm, setSearchTerm] = useState('');
  const [reservations, setReservations] = useState([]);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [longPressed, setLongPressed] = useState(null);
  const longPressTimeout = useRef(null);
  const [isLongPress, setIsLongPress] = useState(false);

  const blacklistedIPs = ['88.82.37.102', '185.41.237.221', '178.118.103.214', '178.116.20.219'];

  React.useEffect(() => {
    if (data) {
      setReservations(data);
    }
  }, [data]);

  const handleDelete = () => {
    const deleteUrl = window.baseDomain + `reservations/update-delete/${reservationToDelete.id}`;
    dataDelete(deleteUrl);
    setReservations(reservations.filter(reservation => reservation.id !== reservationToDelete.id));
    setReservationToDelete(null);
    setShowAlert(false);
  };

  const confirmDelete = (reservation) => {
    setReservationToDelete(reservation);
    setShowAlert(true);
  };

  const handleEdit = (editToken) => {
    window.location.href = `https://edit.reservaties.net/?edit-token=${editToken}`;
  };

  const isPastDate = (reservationDate) => {
    const resDate = new Date(reservationDate);
    const resDateInCEST = new Date(resDate.toLocaleString("en-US", { timeZone: "Europe/Berlin" }));

    const today = new Date();
    const todayInCEST = new Date(today.toLocaleString("en-US", { timeZone: "Europe/Berlin" }));

    resDateInCEST.setHours(0, 0, 0, 0);
    todayInCEST.setHours(0, 0, 0, 0);

    return resDateInCEST < todayInCEST;
  };

  const handleMouseDown = (reservation) => {
    setIsLongPress(false);
    longPressTimeout.current = setTimeout(() => {
      if (longPressed === reservation.id) {
        setLongPressed(null); // Revert back to "Bewerken"
      } else {
        setLongPressed(reservation.id); // Switch to "Wissen"
      }
      setIsLongPress(true); // Set long press flag
    }, 1000);
  };

  const handleMouseUp = (reservation) => {
    clearTimeout(longPressTimeout.current);

    if (isLongPress) {
      return; // Do nothing if it was a long press
    }

    if (longPressed === reservation.id) {
      confirmDelete(reservation); // If long pressed, trigger the delete confirmation
    } else {
      handleEdit(reservation.edit_token); // If not long pressed, proceed with edit action
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error.message}</div>;

  const sortedData = reservations.sort((a, b) => a.start_time - b.start_time);
  const filteredData = sortedData.filter(
    reservation =>
      reservation.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      reservation.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <ul className="list-group">
        <li className="list-group-item search-bar">
          <input
            type="text"
            placeholder="Zoeken op naam..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </li>
        {filteredData.map((reservation, index) => {
          const isBlacklisted = blacklistedIPs.includes(reservation.ip_address);
          return (
            <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
              <div>
                <p>
                  <strong>
                    {reservation.guests}p - {reservation.first_name} {reservation.last_name} om {reservation.timeslot_name && reservation.timeslot_name !== "" ? reservation.timeslot_name : slotToTime(reservation.start_time)} {reservation?.table_label}
                    {isBlacklisted && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Deze reservatie is mogelijks spam.</Tooltip>}
                      >
                        <i className="bi bi-exclamation-triangle-fill" style={{ color: '#ff9900', marginLeft: '8px' }}></i>
                      </OverlayTrigger>
                    )}
                  </strong>
                </p>
                <p>{reservation.email} - {reservation.phone} {reservation?.menu}</p>
              </div>
              <div className="action-buttons">
                {isPastDate(date) ? (
                  <button className="btn btn-danger" onMouseDown={() => confirmDelete(reservation)}>
                    Wissen
                  </button>
                ) : (
                  <>
                    <button className="btn btn-secondary" onMouseDown={() => handleEdit(reservation.edit_token)}>
                      Bewerken
                    </button>
                    <button className="btn btn-danger" onMouseDown={() => confirmDelete(reservation)}>
                      Wissen
                    </button>
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      <DeleteModal
        visible={showAlert}
        message={"Zeker dat u reservatie voor " + reservationToDelete?.first_name + ' ' + reservationToDelete?.last_name + " wilt verwijderen?"}
        onCancel={() => setShowAlert(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default CompactDay;
