const gradientGroups = {
    blue: [
      { id: 1, gradient: 'linear-gradient(#667db6, #0082c8)', name: 'Blue 1' },
      { id: 2, gradient: 'linear-gradient(#1a2a6c, #22c1c3)', name: 'Blue 2' },
      { id: 3, gradient: 'linear-gradient(#6699ff, #99ccff)', name: 'Blue 3' },
      { id: 4, gradient: 'linear-gradient(#5B86E5, #36D1DC)', name: 'Blue 4' },
      { id: 5, gradient: 'linear-gradient(#2193b0, #6dd5ed)', name: 'Blue 5' },
      { id: 6, gradient: 'linear-gradient(#0072ff, #00c6ff)', name: 'Blue 6' },
      { id: 7, gradient: 'linear-gradient(#00416A, #E4E5E6)', name: 'Blue 7' },
      { id: 8, gradient: 'linear-gradient(#396afc, #2948ff)', name: 'Blue 8' },
      { id: 41, gradient: 'linear-gradient(#005c97, #363795)', name: 'Blue 9' },
      { id: 42, gradient: 'linear-gradient(#1e3c72, #2a5298)', name: 'Blue 10' },
      { id: 43, gradient: 'linear-gradient(#2980b9, #2c3e50)', name: 'Blue 11' },
      { id: 44, gradient: 'linear-gradient(#4568dc, #b06ab3)', name: 'Blue 12' },
      { id: 45, gradient: 'linear-gradient(#4facfe, #00f2fe)', name: 'Blue 13' },
      { id: 46, gradient: 'linear-gradient(#000428, #004e92)', name: 'Blue 14' },
      { id: 47, gradient: 'linear-gradient(#00c6ff, #0072ff)', name: 'Blue 15' },
      { id: 48, gradient: 'linear-gradient(#3a7bd5, #3a6073)', name: 'Blue 16' },
    ],
    red: [
      { id: 9, gradient: 'linear-gradient(#ff3c3c, #ff6666)', name: 'Red 1' },
      { id: 10, gradient: 'linear-gradient(#ff5f6d, #ffc371)', name: 'Red 2' },
      { id: 11, gradient: 'linear-gradient(#FF416C, #FF4B2B)', name: 'Red 3' },
      { id: 12, gradient: 'linear-gradient(#e53935, #e35d5b)', name: 'Red 4' },
      { id: 13, gradient: 'linear-gradient(#cb2d3e, #ef473a)', name: 'Red 5' },
      { id: 14, gradient: 'linear-gradient(#FF5F6D, #FFC371)', name: 'Red 6' },
      { id: 15, gradient: 'linear-gradient(#de6262, #ffb88c)', name: 'Red 7' },
      { id: 16, gradient: 'linear-gradient(#ff6e7f, #bfe9ff)', name: 'Red 8' },
      { id: 49, gradient: 'linear-gradient(#fc5c7d, #6a82fb)', name: 'Red 9' },
      { id: 50, gradient: 'linear-gradient(#d31027, #ea384d)', name: 'Red 10' },
      { id: 51, gradient: 'linear-gradient(#e52d27, #b31217)', name: 'Red 11' },
      { id: 52, gradient: 'linear-gradient(#de6161, #2657eb)', name: 'Red 12' },
      { id: 53, gradient: 'linear-gradient(#cb2d3e, #ef473a)', name: 'Red 13' },
      { id: 54, gradient: 'linear-gradient(#ed213a, #93291e)', name: 'Red 14' },
      { id: 55, gradient: 'linear-gradient(#f85032, #e73827)', name: 'Red 15' },
      { id: 56, gradient: 'linear-gradient(#ff0844, #ffb199)', name: 'Red 16' },
    ],
    purple: [
      { id: 17, gradient: 'linear-gradient(#7F00FF, #E100FF)', name: 'Purple 1' },
      { id: 18, gradient: 'linear-gradient(#C33764, #1D2671)', name: 'Purple 2' },
      { id: 19, gradient: 'linear-gradient(#833ab4, #fd1d1d, #fcb045)', name: 'Purple 3' },
      { id: 20, gradient: 'linear-gradient(#AA076B, #61045F)', name: 'Purple 4' },
      { id: 21, gradient: 'linear-gradient(#614385, #516395)', name: 'Purple 5' },
      { id: 22, gradient: 'linear-gradient(#6a3093, #a044ff)', name: 'Purple 6' },
      { id: 23, gradient: 'linear-gradient(#654ea3, #eaafc8)', name: 'Purple 7' },
      { id: 24, gradient: 'linear-gradient(#20002c, #cbb4d4)', name: 'Purple 8' },
      { id: 57, gradient: 'linear-gradient(#9d50bb, #6e48aa)', name: 'Purple 9' },
      { id: 58, gradient: 'linear-gradient(#fc466b, #3f5efb)', name: 'Purple 10' },
      { id: 59, gradient: 'linear-gradient(#da22ff, #9733ee)', name: 'Purple 11' },
      { id: 60, gradient: 'linear-gradient(#ff758c, #ff7eb3)', name: 'Purple 12' },
      { id: 61, gradient: 'linear-gradient(#7028e4, #e5b2ca)', name: 'Purple 13' },
      { id: 62, gradient: 'linear-gradient(#c33764, #1d2671)', name: 'Purple 14' },
      { id: 63, gradient: 'linear-gradient(#9d50bb, #6e48aa)', name: 'Purple 15' },
      { id: 64, gradient: 'linear-gradient(#e94057, #f27121)', name: 'Purple 16' },
    ],
    darkBlue: [
      { id: 25, gradient: 'linear-gradient(#0F2027, #203A43, #2C5364)', name: 'Dark Blue 1' },
      { id: 26, gradient: 'linear-gradient(#243B55, #141E30)', name: 'Dark Blue 2' },
      { id: 27, gradient: 'linear-gradient(#16222A, #3A6073)', name: 'Dark Blue 3' },
      { id: 28, gradient: 'linear-gradient(#3A1C71, #D76D77, #FFAF7B)', name: 'Dark Blue 4' },
      { id: 29, gradient: 'linear-gradient(#4b6cb7, #182848)', name: 'Dark Blue 5' },
      { id: 30, gradient: 'linear-gradient(#2b5876, #4e4376)', name: 'Dark Blue 6' },
      { id: 31, gradient: 'linear-gradient(#536976, #292E49)', name: 'Dark Blue 7' },
      { id: 32, gradient: 'linear-gradient(#005C97, #363795)', name: 'Dark Blue 8' },
      { id: 65, gradient: 'linear-gradient(#141e30, #243b55)', name: 'Dark Blue 9' },
      { id: 66, gradient: 'linear-gradient(#3c3b3f, #605c3c)', name: 'Dark Blue 10' },
      { id: 67, gradient: 'linear-gradient(#005aa7, #fffde4)', name: 'Dark Blue 11' },
      { id: 68, gradient: 'linear-gradient(#3a7bd5, #3a6073)', name: 'Dark Blue 12' },
      { id: 69, gradient: 'linear-gradient(#004e92, #000428)', name: 'Dark Blue 13' },
      { id: 70, gradient: 'linear-gradient(#0f2027, #2c5364)', name: 'Dark Blue 14' },
      { id: 71, gradient: 'linear-gradient(#2b5876, #4e4376)', name: 'Dark Blue 15' },
      { id: 72, gradient: 'linear-gradient(#00c6ff, #0072ff)', name: 'Dark Blue 16' },
    ],
    azure: [
      { id: 33, gradient: 'linear-gradient(#56CCF2, #2F80ED)', name: 'Azure 1' },
      { id: 34, gradient: 'linear-gradient(#4CA1AF, #C4E0E5)', name: 'Azure 2' },
      { id: 35, gradient: 'linear-gradient(#3a7bd5, #3a6073)', name: 'Azure 3' },
      { id: 36, gradient: 'linear-gradient(#2980b9, #6dd5fa, #ffffff)', name: 'Azure 4' },
      { id: 37, gradient: 'linear-gradient(#43cea2, #185a9d)', name: 'Azure 5' },
      { id: 38, gradient: 'linear-gradient(#1c92d2, #f2fcfe)', name: 'Azure 6' },
      { id: 39, gradient: 'linear-gradient(#3e5151, #decba4)', name: 'Azure 7' },
      { id: 40, gradient: 'linear-gradient(#2193b0, #6dd5ed)', name: 'Azure 8' },
      { id: 73, gradient: 'linear-gradient(#36d1dc, #5b86e5)', name: 'Azure 9' },
      { id: 74, gradient: 'linear-gradient(#43c6ac, #f8ffae)', name: 'Azure 10' },
      { id: 75, gradient: 'linear-gradient(#56ab2f, #a8e063)', name: 'Azure 11' },
      { id: 76, gradient: 'linear-gradient(#396afc, #2948ff)', name: 'Azure 12' },
      { id: 77, gradient: 'linear-gradient(#11998e, #38ef7d)', name: 'Azure 13' },
      { id: 78, gradient: 'linear-gradient(#0cebeb, #29ffc6)', name: 'Azure 14' },
      { id: 79, gradient: 'linear-gradient(#00c6ff, #0072ff)', name: 'Azure 15' },
      { id: 80, gradient: 'linear-gradient(#005aa7, #fffde4)', name: 'Azure 16' },
    ],
  };

  export default gradientGroups;