import React, { useState } from 'react';
import Share from './share'

const Delen = () => {

  return (
    <div className="container">
      <Share></Share>
    </div>
  );
};

export default Delen;
