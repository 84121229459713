import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import '../../css/reservaties.css';
import '../../css/general.css'
import Calendar from './calendar_week';
import useDataRetriever from '../../../utils/useDataRetriever';

const getLabelByTime = (period) => {
  switch (period) {
    case 'N':
      return 'Nacht';
    case 'M':
      return 'Ochtend';
    case 'A':
      return 'Middag';
    case 'E':
      return 'Avond';
    default:
      return '';
  }
};

const getColorByTime = (period) => {
  switch (period) {
    case 'N':
      return '#ff0000'; // Night
    case 'M':
      return '#17a2b8'; // Morning
    case 'A':
      return 'rgb(62, 151, 255)'; // Afternoon
    case 'E':
      return 'rgb(7, 20, 55)'; // Evening
    default:
      return '#000000'; // Default
  }
};

const getTimeRange = (period) => {
  switch (period) {
    case 'N':
      return { start: '00:00', end: '03:00' }; // Night
    case 'M':
      return { start: '03:00', end: '10:00' }; // Morning
    case 'A':
      return { start: '10:00', end: '15:30' }; // Afternoon
    case 'E':
      return { start: '15:30', end: '24:00' }; // Evening
    default:
      return { start: '00:00', end: '24:00' }; // Default
  }
};

function Week() {
  const restaurantId = 1; // Assuming restaurant ID is 1
  const startDate = '2024-06-08';
  const endDate = '2025-06-08';
  const { data, error, loading } = useDataRetriever(`${window.baseDomain}analytics/daily-interval/${startDate}/${endDate}/`);
  const [events, setEvents] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (data) {
      const formattedData = [];
      Object.keys(data).forEach((date) => {
        Object.keys(data[date]).forEach((period) => {
          const { start, end } = getTimeRange(period);
          formattedData.push({
            title: `${getLabelByTime(period)}\n${data[date][period]} gasten`,
            start: `${date}T${start}`,
            end: `${date}T${end}`,
            color: getColorByTime(period),
          });
        });
      });
      setEvents(formattedData);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className='container'>
      {alertMessage && <Alert variant="danger">{alertMessage}</Alert>}
      <Calendar events={events} />
    </div>
  );
}

export default Week;
