import React from 'react';

const PrintSelectionAlert = ({ setShowAlert, handlePrintSelect }) => {
  return (
    <div className="custom-alert print-alert modal-on-top">
      <div className="custom-alert-content">
        <h5>Print Overzicht</h5>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }} className="custom-alert-actions">
          <button className="btn btn-primary w-100 mb-2" onClick={() => handlePrintSelect('Ontbijt')}>Ontbijt</button>
          <button className="btn btn-primary w-100 mb-2" onClick={() => handlePrintSelect('Lunch')}>Lunch</button>
          <button className="btn btn-primary w-100 mb-2" onClick={() => handlePrintSelect('Diner')}>Diner</button>
          <button className="btn btn-danger w-100" onClick={() => handlePrintSelect('Hele Dag')}>Hele Dag</button>
          <button className="btn btn-secondary w-100 mt-3" onClick={() => { setShowAlert(false); }}>Annuleer</button>
        </div>
      </div>
    </div>
  );
};

export default PrintSelectionAlert;
