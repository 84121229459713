import { findAreas } from './areaFinder';
import isAdjacent from './isAdjacent';

export const prepareNewAreas = async (grid, areaSize, createdAreas, areas, dataPost, setTotalCapacity, setCreatedAreas, window, labels) => {
  let newAreas = findAreas(grid, areaSize.width, areaSize.height);

  newAreas = newAreas.filter(area => {
    const areaKey = JSON.stringify(area);
    const isAreaAdjacent = areas.some(existingArea => isAdjacent(area, existingArea));
    return !createdAreas.has(areaKey) && !areas.some(existingArea => existingArea.left === area.left && existingArea.top === area.top) && !isAreaAdjacent;
  });

  newAreas.forEach((area, index) => {
    area.name = `Tafel ${areas.length + index + 1}`;
    setTotalCapacity(totalCapacity => totalCapacity + (area.width * area.height));
  });

  const firstLabelId = labels.length > 0 ? labels[0].id : null;
  const firstLabelDescription = labels.length > 0 ? labels[0].display_description : '';

  for (let area of newAreas) {
    const tableData = {
      capacity: area.width * area.height,
      x: area.left,
      y: area.top,
      height: area.height,
      width: area.width,
      name: area.name,
      restaurant: 1,
      table_label: firstLabelId,
    };
    try {
      const response = await dataPost(window.baseDomain + 'tables/', tableData);
      area.id = response.id;
      area.display_description = firstLabelDescription; // Assign the label description to the new area
      setCreatedAreas(prev => new Set(prev).add(JSON.stringify(area)));
    } catch (error) {
      console.error('Error creating new table', error);
    }
  }

  return newAreas;
};

export const relocateTable = (e, gridElement, areaSize, areas, relocatingTable) => {
  const { clientX, clientY } = e;
  const rect = gridElement.getBoundingClientRect();
  const x = Math.floor(((clientX - rect.left) / rect.width) * areaSize.width);
  const y = Math.floor(((clientY - rect.top) / rect.height) * areaSize.height);

  const updatedAreas = [...areas];
  updatedAreas[relocatingTable] = {
    ...updatedAreas[relocatingTable],
    left: x,
    top: y,
  };
  return updatedAreas;
};

export const finalizeRelocation = async (areas, relocatingTable, dataPatch, window) => {
  const area = areas[relocatingTable];
  const tableData = { x: area.left, y: area.top };

  try {
    await dataPatch(window.baseDomain + `tables/update-delete/${area.id}`, tableData);
  } catch (error) {
    console.error('Failed to update table location', error);
  }
};
