import React, { useState, useEffect } from 'react';
import gradientGroups from './gradient_styles';
import { applyStarsEffect, applyShootingStarsEffect, clearEffects } from './gradient_effects';

const GradientSelector = () => {
  const [selectedGradient, setSelectedGradient] = useState('');
  const [previewGradient, setPreviewGradient] = useState('');
  const [customColor, setCustomColor] = useState('#ffffff'); // Default custom color
  const [filter, setFilter] = useState('all'); // Filter state
  const [selectedEffect, setSelectedEffect] = useState('none'); // State to track the selected effect

  useEffect(() => {
    const storedGradient = localStorage.getItem('selectedGradient');
    if (storedGradient) {
      setSelectedGradient(storedGradient);
      document.getElementById('kt_app_sidebar').style.background = storedGradient;
    }
  }, []);

  const handleSelectGradient = (gradient) => {
    setSelectedGradient(gradient);
    localStorage.setItem('selectedGradient', gradient);
    document.getElementById('kt_app_sidebar').style.background = gradient;
  };

  const handlePreviewGradient = (gradient) => {
    setPreviewGradient(gradient);
    document.getElementById('kt_app_sidebar').style.background = gradient;
  };

  const handleResetPreview = () => {
    document.getElementById('kt_app_sidebar').style.background = selectedGradient || '';
  };

  const handleCustomColorChange = (e) => {
    const color = e.target.value;
    setCustomColor(color);
    handleSelectGradient(color);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleEffectChange = (e) => {
    setSelectedEffect(e.target.value);
  };

  const applySelectedEffect = () => {
    const sidebar = document.getElementById('kt_app_sidebar');
    if (sidebar) {
      clearEffects(sidebar); // Clear previous effects

      if (selectedEffect === 'stars') {
        applyStarsEffect(sidebar);
      } else if (selectedEffect === 'shootingStars') {
        applyShootingStarsEffect(sidebar);
      }
      localStorage.setItem('selectedEffect', selectedEffect);
    }
  };

  useEffect(() => {
    applySelectedEffect();
  }, [selectedEffect]);

  const filteredGradients =
    filter === 'all'
      ? Object.values(gradientGroups).flat()
      : gradientGroups[filter] || [];

  return (
    <div className="container mt-4">
      <h1>Kies Een Sidebar</h1>
      {/* Row to structure the selectors */}
      <div className="row mb-4">
        {/* Column for color filter */}
        <div className="col-md-6">
          <label htmlFor="gradientFilter" className="form-label">Filter op kleur:</label>
          <select id="gradientFilter" className="form-select" value={filter} onChange={handleFilterChange}>
            <option value="all">Alle kleuren</option>
            <option value="blue">Blauw</option>
            <option value="red">Rood</option>
            <option value="purple">Paars</option>
            <option value="darkBlue">Donker Blauw</option>
            <option value="azure">Azure</option>
          </select>
        </div>

        {/* Column for effect selector */}
        <div className="col-md-6">
          <label htmlFor="effectSelector" className="form-label">Kies een effect:</label>
          <select id="effectSelector" className="form-select" value={selectedEffect} onChange={handleEffectChange}>
            <option value="none">Geen Effect</option>
            <option value="stars">Sterrenhemel</option>
            <option value="shootingStars">Vallende Sterren</option>
          </select>
        </div>
      </div>

      {/* Gradient grid */}
      <div className="d-grid gap-3" style={{ gridTemplateColumns: 'repeat(8, 1fr)', display: 'grid' }}>
        {filteredGradients.map((item) => (
          <div
            key={item.id}
            className="gradient-box"
            style={{
              width: '100px',
              height: '100px',
              background: item.gradient,
              cursor: 'pointer',
              border: selectedGradient === item.gradient ? '3px solid #000' : '1px solid #ccc',
              transition: 'border 0.3s',
            }}
            onClick={() => handleSelectGradient(item.gradient)}
            onMouseEnter={() => handlePreviewGradient(item.gradient)}
            onMouseLeave={handleResetPreview}
            title={item.name}
          ></div>
        ))}
        {/* Custom color picker */}
        <div className="custom-color-picker" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <input
            type="color"
            value={customColor}
            onChange={handleCustomColorChange}
            style={{ width: '100px', height: '100px', cursor: 'pointer', border: '1px solid #ccc' }}
            title="Pick a custom color"
          />
          <small>Kies zelf een kleur</small>
        </div>
      </div>
    </div>
  );
};

export default GradientSelector;
