import React, { useState, useEffect, useRef } from 'react';

const Logo = () => {
  const [logoSrc, setLogoSrc] = useState('./assets/media/logos/default-dark.webp');
  const logoRef = useRef(null);

  const updateLogo = () => {
    const restaurantName = localStorage.getItem('restaurantName');
    if (restaurantName) {
      const newLogoSrc = `https://static.reservaties.net/logos/${restaurantName}.webp`;
      setLogoSrc(newLogoSrc);
    }
  };

  useEffect(() => {
    window.addEventListener('logoUpdate', updateLogo);
    updateLogo();

    return () => {
      window.removeEventListener('logoUpdate', updateLogo);
    };
  }, []);

  const handleImageError = () => {
    setLogoSrc('./assets/media/logos/default-dark.webp');
  };

  return (
    <a href="https://mateza.be">
      <img
        alt="Logo"
        id="restaurant-logo"
        src={logoSrc}
        ref={logoRef}
        onError={handleImageError}
        style={{
          maxWidth: '180px',
          maxHeight: '40px',
          width: 'auto', // Ensures the image scales within the maxWidth constraint
          height: '100%', // Maintains aspect ratio
          objectFit: 'contain', // Ensures the image fits within both the width and height constraints without stretching
        }}
        className="app-sidebar-logo-default"
      />
    </a>
  );
};

export default Logo;
