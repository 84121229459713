const useDataSaver = async (url, data) => {
	const response = await fetch(url, {
	  method: 'PUT',
	  headers: {
		'Content-Type': 'application/json',
		'Authorization': 'JWT ' + localStorage.getItem('authKey'),
	  },
	  body: JSON.stringify(data),
	});
  
	if (!response.ok) {
	  throw new Error(`Error: ${response.statusText}`);
	}
  
	return await response.json();
  };
  
  export default useDataSaver;
  