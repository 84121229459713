export function isAdjacent(newArea, existingArea) {
	const newLeft = newArea.left;
	const newRight = newArea.left + newArea.width - 1;
	const newTop = newArea.top;
	const newBottom = newArea.top + newArea.height - 1;
  
	const existingLeft = existingArea.left;
	const existingRight = existingArea.left + existingArea.width - 1;
	const existingTop = existingArea.top;
	const existingBottom = existingArea.top + existingArea.height - 1;
  
	return (
	  (newRight + 1 === existingLeft || newLeft - 1 === existingRight) &&
	  (newTop <= existingBottom && newBottom >= existingTop)
	) || (
	  (newBottom + 1 === existingTop || newTop - 1 === existingBottom) &&
	  (newLeft <= existingRight && newRight >= existingLeft)
	);
  }
  
  export default isAdjacent;