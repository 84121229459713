import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Badge } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

// Helper function to calculate the contrast of a color
function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

const macTagColors = [
  '#FF3B30', // Red
  '#FF9500', // Orange
  '#FFCC00', // Yellow
  '#4CD964', // Green
  '#007AFF', // Blue
];

function NoteInput({ onAddNote, usedTags }) {
  const [newNote, setNewNote] = useState('');
  const [newTag, setNewTag] = useState('');
  const [newTagColor, setNewTagColor] = useState(macTagColors[0]);

  const handleAddNote = () => {
    if (newNote.trim() !== '') {
      onAddNote({
        content: newNote,
        tag: newTag,
        tagColor: newTagColor,
      });

      // Reset fields after adding
      setNewNote('');
      setNewTag('');
      setNewTagColor(macTagColors[0]);
    }
  };

  const handleTagClick = (tag) => {
    setNewTag(tag.name);
    setNewTagColor(tag.color);
  };

  return (
    <Card style={{marginTop:"-5px"}} className="mb-3">
      <Card.Body>
        <Form>
          <Form.Group controlId="noteInput">
            <Form.Label>Nieuwe Notitie</Form.Label>
            <div style={{ position: 'relative' }}>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Voer uw notitie in"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  display: 'flex',
                }}
              >
                {macTagColors.map((color) => (
                  <button
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: color === newTagColor ? '2px solid black' : '1px solid transparent',
                      marginRight: '10px',
                      width: '30px',
                      height: '30px',
                      cursor: 'pointer',
                      borderRadius: '40px',
                    }}
                    onClick={() => setNewTagColor(color)}
                  />
                ))}
              </div>
            </div>
          </Form.Group>

          <Form.Group controlId="tagInput" className="mt-6">
            <Form.Label>Categorie</Form.Label>
            <Form.Control
              type="text"
              placeholder="Voer een categorie in"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
          </Form.Group>

          {/* Previously Used Tags + Always Present Tags */}
          {usedTags.length > 0 && (
            <div className="mt-10">
              <h6 className='mb-4'>Bestaande Categorieën:</h6>
              <div className="d-flex flex-wrap">
                {usedTags.map((tag, index) => (
                  <Badge
                    key={index}
                    className='custom-badge'
                    style={{
                      '--bg-color': tag.color,
                      '--text-color': getContrastYIQ(tag.color),
                      marginLeft: '10px',
                    }}
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag.name}
                  </Badge>
                ))}
              </div>
            </div>
          )}

          <Button variant="primary gradient-btn" className="mt-10" onClick={handleAddNote}>
            <FaPlus style={{ marginRight: '5px' }} />
            Toevoegen
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default NoteInput;
