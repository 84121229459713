import React, { useState } from 'react';
import '../css/opening.css';
import '../css/general.css';

const SettingsNavigation = ({ onPageChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('all');

  const handleButtonClick = (e) => {
    const page = e.currentTarget.getAttribute('data-page');
    onPageChange(page);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const filteredMenuItems = menuItems.filter(item =>
    item.title.toLowerCase().includes(searchTerm) || item.text.toLowerCase().includes(searchTerm)
  );

  const group1 = filteredMenuItems.filter(item => item.group === 'restaurant');
  const group2 = filteredMenuItems.filter(item => item.group === 'scheduling');
  const group3 = filteredMenuItems.filter(item => item.group === 'communication');
  const group4 = filteredMenuItems.filter(item => item.group === 'openinghours');

  return (
    <div className="container">
      <h1 className="mb-10">Alle Instellingen</h1>

      <div className="mb-4">
		<div className="search-bar-wrapper">
			<input 
			type="text" 
			className="form-control search-input" 
			placeholder="Zoek instellingen..." 
			value={searchTerm}
			onChange={handleSearchChange}
			/>
			<i className="fas fa-search search-icon" />
		</div>
		</div>


      <div className="custom-tabs">
        <div 
          className={`custom-tab ${activeTab === 'all' ? 'active' : ''}`} 
          onClick={() => handleTabChange('all')}
        >
          Alle Instellingen
        </div>
        <div 
          className={`custom-tab ${activeTab === 'restaurant' ? 'active' : ''}`} 
          onClick={() => handleTabChange('restaurant')}
        >
          Restaurant Instellingen
        </div>
        <div 
          className={`custom-tab ${activeTab === 'scheduling' ? 'active' : ''}`} 
          onClick={() => handleTabChange('scheduling')}
        >
          Planning en Sluitingen
        </div>
        <div 
          className={`custom-tab ${activeTab === 'communication' ? 'active' : ''}`} 
          onClick={() => handleTabChange('communication')}
        >
          Communicatie
        </div>
        <div 
          className={`custom-tab ${activeTab === 'openinghours' ? 'active' : ''}`} 
          onClick={() => handleTabChange('openinghours')}
        >
          Openingsuren
        </div>
      </div>

      <div className="custom-tab-content">
        {activeTab === 'restaurant' && group1.length > 0 && (
          <>
            <h5 className="mt-10 mb-5">Restaurant Instellingen</h5>
            <div className="list-group rounded-lg mb-4">
              {group1.map((item, index) => (
                <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="icon-container text-white me-4">
                      <i className={`fas ${item.icon}`} />
                    </div>
                    <div>
                      <h5 className="mb-1">{item.title}</h5>
                      <p className="mb-0 text-muted">{item.text}</p>
                    </div>
                  </div>
                  <a
                    data-page={item.page}
                    className="btn btn-secondary"
                    onClick={handleButtonClick}
                  >
                    {item.buttonText}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {activeTab === 'scheduling' && group2.length > 0 && (
          <>
            <h5 className="mt-10 mb-5">Planning en Sluitingen</h5>
            <div className="list-group rounded-lg mb-4">
              {group2.map((item, index) => (
                <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="icon-container text-white me-4">
                      <i className={`fas ${item.icon}`} />
                    </div>
                    <div>
                      <h5 className="mb-1">{item.title}</h5>
                      <p className="mb-0 text-muted">{item.text}</p>
                    </div>
                  </div>
                  <a
                    data-page={item.page}
                    className="btn btn-secondary"
                    onClick={handleButtonClick}
                  >
                    {item.buttonText}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {activeTab === 'communication' && group3.length > 0 && (
          <>
            <h5 className="mt-10 mb-5">Communicatie</h5>
            <div className="list-group rounded-lg mb-4">
              {group3.map((item, index) => (
                <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="icon-container text-white me-4">
                      <i className={`fas ${item.icon}`} />
                    </div>
                    <div>
                      <h5 className="mb-1">{item.title}</h5>
                      <p className="mb-0 text-muted">{item.text}</p>
                    </div>
                  </div>
                  <a
                    data-page={item.page}
                    className="btn btn-secondary"
                    onClick={handleButtonClick}
                  >
                    {item.buttonText}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {activeTab === 'openinghours' && group4.length > 0 && (
          <>
            <h5 className="mt-10 mb-5">Openingsuren</h5>
            <div className="list-group rounded-lg mb-4">
              {group4.map((item, index) => (
                <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="icon-container text-white me-4">
                      <i className={`fas ${item.icon}`} />
                    </div>
                    <div>
                      <h5 className="mb-1">{item.title}</h5>
                      <p className="mb-0 text-muted">{item.text}</p>
                    </div>
                  </div>
                  <a
                    data-page={item.page}
                    className="btn btn-secondary"
                    onClick={handleButtonClick}
                  >
                    {item.buttonText}
                  </a>
                </div>
              ))}
            </div>
          </>
        )}

        {activeTab === 'all' && filteredMenuItems.length > 0 && (
          <>
            {group1.length > 0 && (
              <>
                <h5 className="mt-10 mb-5">Restaurant Instellingen</h5>
                <div className="list-group rounded-lg mb-4">
                  {group1.map((item, index) => (
                    <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="icon-container text-white me-4">
                          <i className={`fas ${item.icon}`} />
                        </div>
                        <div>
                          <h5 className="mb-1">{item.title}</h5>
                          <p className="mb-0 text-muted">{item.text}</p>
                        </div>
                      </div>
                      <a
                        data-page={item.page}
                        className="btn btn-secondary"
                        onClick={handleButtonClick}
                      >
                        {item.buttonText}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
            {group2.length > 0 && (
              <>
                <h5 className="mt-10 mb-5">Planning en Sluitingen</h5>
                <div className="list-group rounded-lg mb-4">
                  {group2.map((item, index) => (
                    <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="icon-container text-white me-4">
                          <i className={`fas ${item.icon}`} />
                        </div>
                        <div>
                          <h5 className="mb-1">{item.title}</h5>
                          <p className="mb-0 text-muted">{item.text}</p>
                        </div>
                      </div>
                      <a
                        data-page={item.page}
                        className="btn btn-secondary"
                        onClick={handleButtonClick}
                      >
                        {item.buttonText}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
            {group3.length > 0 && (
              <>
                <h5 className="mt-10 mb-5">Communicatie</h5>
                <div className="list-group rounded-lg mb-4">
                  {group3.map((item, index) => (
                    <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="icon-container text-white me-4">
                          <i className={`fas ${item.icon}`} />
                        </div>
                        <div>
                          <h5 className="mb-1">{item.title}</h5>
                          <p className="mb-0 text-muted">{item.text}</p>
                        </div>
                      </div>
                      <a
                        data-page={item.page}
                        className="btn btn-secondary"
                        onClick={handleButtonClick}
                      >
                        {item.buttonText}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
            {group4.length > 0 && (
              <>
                <h5 className="mt-10 mb-5">Openingsuren</h5>
                <div className="list-group rounded-lg mb-4">
                  {group4.map((item, index) => (
                    <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between py-4" key={index}>
                      <div className="d-flex align-items-center">
                        <div className="icon-container text-white me-4">
                          <i className={`fas ${item.icon}`} />
                        </div>
                        <div>
                          <h5 className="mb-1">{item.title}</h5>
                          <p className="mb-0 text-muted">{item.text}</p>
                        </div>
                      </div>
                      <a
                        data-page={item.page}
                        className="btn btn-secondary"
                        onClick={handleButtonClick}
                      >
                        {item.buttonText}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        {(activeTab === 'restaurant' && group1.length === 0 &&
         activeTab === 'scheduling' && group2.length === 0 &&
         activeTab === 'communication' && group3.length === 0 &&
         activeTab === 'openinghours' && group4.length === 0 &&
         activeTab === 'all' && filteredMenuItems.length === 0) && (
          <p className="text-muted text-center">Geen instellingen gevonden</p>
        )}
      </div>
    </div>
  );
};

const menuItems = [
  { title: "Zitplaatsen", text: "Beheer de zitplaatsen in het restaurant.", page: "tablelabel", group: "restaurant", icon: "fa-chair", buttonText: "Beheer Zitplaatsen" },
  { title: "Tafelplan", text: "Beheer het tafelplan van uw restaurant.", page: "tables", group: "restaurant", icon: "fa-th-large", buttonText: "Bekijk Tafelplan" },
  { title: "Menu", text: "Beheer het menu van uw restaurant.", page: "menuoptions", group: "restaurant", icon: "fa-utensils", buttonText: "Bekijk Menuopties" },
  { title: "Sluitingsdagen", text: "Beheer de sluitingsdagen van uw restaurant.", page: "closing", group: "scheduling", icon: "fa-calendar-times", buttonText: "Bekijk Sluitingsdagen" },
  { title: "Uitzonderingen", text: "Beheer uitzonderlijke openingstijden.", page: "exceptions", group: "scheduling", icon: "fa-exclamation-circle", buttonText: "Bekijk Uitzonderingen" },
  { title: "Mededelingen", text: "Beheer de mededelingen voor uw klanten.", page: "notifications", group: "communication", icon: "fa-bell", buttonText: "Bekijk Mededelingen" },
  { title: "Feedback", text: "Bekijk en beheer feedback van klanten.", page: "feedback", group: "communication", icon: "fa-comment-alt", buttonText: "Bekijk Feedback" },
  { title: "Ontbijt", text: "Beheer de openingsuren voor ontbijt.", page: "ontbijt", group: "openinghours", icon: "fa-coffee", buttonText: "Bekijk Ontbijt" },
  { title: "Lunch", text: "Beheer de openingsuren voor lunch.", page: "lunch", group: "openinghours", icon: "fa-utensils", buttonText: "Bekijk Lunch" },
  { title: "Diner", text: "Beheer de openingsuren voor diner.", page: "diner", group: "openinghours", icon: "fa-wine-glass-alt", buttonText: "Bekijk Diner" },
  { title: "Cadeaubonnen", text: "Beheer de cadeaubonnen.", page: "giftcards", group: "restaurant", icon: "fa-gift", buttonText: "Bekijk Cadeaubonnen" } // New item
];

export default SettingsNavigation;
