import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import '../css/opening.css';
import '../css/general.css';

const Menu = () => {
  const url = `${window.baseDomain}tables/menu/`;
  const { data, error, loading } = useDataRetriever(url);
  const [labels, setLabels] = useState([]);
  const [newLabel, setNewLabel] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const labelList = data.map(item => ({ id: item.id, display_description: item.display_description }));
      setLabels(labelList);
    }
  }, [data]);

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const capitalizeWords = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  };

  const postLabel = async (display_description) => {
    const formattedLabel = capitalizeWords(display_description);
    const payload = { display_description: formattedLabel };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post label');
      }

      const newLabel = await response.json();
      setLabels([...labels, newLabel]);
    } catch (error) {
      console.error(`Failed to post label ${display_description}: ${error.message}`);
    }
  };

  const addLabel = async () => {
    if (newLabel && !labels.some(label => label.display_description === capitalizeWords(newLabel))) {
      let rules = '';
      if (selectedMonth) {
        rules += `_m${selectedMonth}`;
      }
      if (selectedDays.length > 0) {
        rules += `_${selectedDays.join('')}`;
      }
      if (rules) {
        rules = `__rules${rules}`;
      }
      await postLabel(`${newLabel}${rules}`);
      setNewLabel('');
      setSelectedMonth('');
      setSelectedDays([]);
      setShowModal(false);
    }
  };

  const deleteLabel = async (id) => {
    const endpoint = `${url}update-delete/${id}/`;

    try {
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete label');
      }

      setLabels(labels.filter(label => label.id !== id));
    } catch (error) {
      console.error(`Failed to delete label ${id}: ${error.message}`);
    }
  };

  const removeLabel = async (labelToRemove) => {
    const labelObj = labels.find(label => label.display_description.startsWith(labelToRemove));
    if (labelObj) {
      await deleteLabel(labelObj.id);
    }
  };

  const decodeRules = (ruleset) => {
    const monthNames = ["", "Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    const dayNames = ["", "Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
    
    let decodedRules = [];
    
    const monthMatch = ruleset.match(/_m([1-9]|1[0-2])/);
    if (monthMatch) {
      decodedRules.push(monthNames[parseInt(monthMatch[1], 10)]);
    }
    
    const dayMatches = ruleset.match(/d[1-7]/g);
    if (dayMatches) {
      decodedRules.push(...dayMatches.map(day => dayNames[parseInt(day.slice(1), 10)]));
    }
    
    return decodedRules.join(', ');
  };
  
  const displayLabels = labels.map(label => {
    const [description, ruleset] = label.display_description.includes('__rules')
      ? label.display_description.split('__rules')
      : [label.display_description, ''];
    const decodedRules = ruleset ? decodeRules(ruleset) : '';
    const displayDescription = (
      <span>
        {description}
        {decodedRules && <span style={{ color: 'gray' }}> - {decodedRules}</span>}
      </span>
    );

    return {
      ...label,
      display_description: label.display_description, // Keep original for operations
      displayElement: displayDescription // User-friendly display
    };
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <h1>Menuopties (optioneel)</h1>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <label htmlFor="newLabel" className="form-label">Voeg menu's toe (selecteerbaar door klant)</label>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <input 
            type="text" 
            className="form-control" 
            id="newLabel" 
            value={newLabel} 
            onChange={(e) => setNewLabel(e.target.value)}
            placeholder="Bvb. maandmenu, kreeftmenu, a la carte"
          />
          <button className="btn btn-primary" onClick={() => setShowModal(true)}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Menu's</label>
        <ul className="list-group">
          {displayLabels.map((label) => (
            <li key={label.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                {label.displayElement}
              </div>
              <button className="btn btn-danger btn-sm" onClick={() => removeLabel(label.display_description.split('__rules')[0])}>Wissen</button>
            </li>
          ))}
        </ul>
      </div>

      {showModal && (
        <div className="custom-alert" tabIndex="-1" role="dialog">
          <div className="custom-alert-content" style={{textAlign:"left"}} role="document">
              <h5 className="modal-title" style={{marginBottom:"10px"}}>Selecteer maand en dagen (optioneel)</h5>
                <div className="form-group mb-3">
                  <label className="mb-3" htmlFor="monthSelect">Maand</label>
                  <select 
                    id="monthSelect" 
                    className="form-control" 
                    value={selectedMonth} 
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    <option value="">Alle</option>
                    <option value="1">Januari</option>
                    <option value="2">Februari</option>
                    <option value="3">Maart</option>
                    <option value="4">April</option>
                    <option value="5">Mei</option>
                    <option value="6">Juni</option>
                    <option value="7">Juli</option>
                    <option value="8">Augustus</option>
                    <option value="9">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                <div className="form-group mt-3 mb-3">
                  <label className="mt-3 mb-3">Dagen</label>
                  <div className="d-flex flex-wrap">
                    {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map((day, index) => (
                      <div key={index} className="form-check form-check-inline">
                        <input 
                          className="form-check-input text-black" 
                          type="checkbox" 
                          id={`day-${index}`} 
                          value={"d" + (index + 1)} 
                          onChange={(e) => {
                            const day = e.target.value;
                            setSelectedDays(prev => 
                              e.target.checked ? [...prev, day] : prev.filter(d => d !== day)
                            );
                          }}
                        />
                        <label className="form-check-label text-black" htmlFor={`day-${index}`}>{day}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{marginTop:"15px"}} className="modal-footer">
                <button style={{marginRight:"15px"}} type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Sluiten</button>
                <button type="button" className="btn btn-primary" onClick={addLabel}>Opslaan</button>
              </div>
            </div>
          </div>
      )}
    </div>
  );
}

export default Menu;
