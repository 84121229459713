import React from 'react';
import '../css/opening.css';
import '../css/general.css';

const Help = ({ onPageChange }) => {
  const handleButtonClick = (e) => {
    const page = e.currentTarget.getAttribute('data-page');
    onPageChange(page);
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-calendar fa-3x mb-3"></i>
              <h5 className="card-title">Afspraak</h5>
              <p className="card-text">Maak een afspraak voor klantenondersteuning.</p>
              <a
                href="https://cal.com/thibault-vds-vej61p/klantenondersteuning"
                className="gradient-btn btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Plan Afspraak
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-envelope fa-3x mb-3"></i>
              <h5 className="card-title">Email</h5>
              <p className="card-text">Stuur ons een e-mail voor ondersteuning.</p>
              <a
                href="mailto:thibault@mateza.be"
                className="gradient-btn btn btn-primary"
              >
                Stuur Email
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-phone fa-3x mb-3"></i>
              <h5 className="card-title">Telefonische Hulp</h5>
              <p className="card-text">Neem telefonisch contact met ons op.</p>
              <a
                href="tel:0472134617"
                className="gradient-btn btn btn-primary"
              >
                Bel Nu
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-comment-alt fa-3x mb-3"></i>
              <h5 className="card-title">Feedback</h5>
              <p className="card-text">Deel uw feedback met ons.</p>
              <a
                data-page="feedback"
                className="gradient-btn btn btn-primary"
                onClick={handleButtonClick}
              >
                Geef Feedback
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
