import React from 'react';
import '../css/opening.css';
import '../css/general.css';

const Navigation = ({ onPageChange }) => {

  const handleButtonClick = (e) => {
    const page = e.currentTarget.getAttribute('data-page');
    onPageChange(page);
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-chart-bar fa-3x mb-3"></i>
              <h5 className="card-title">Statistieken</h5>
              <p className="card-text">Bekijk de statistieken van uw restaurant.</p>
              <a 
                data-page="analytics" 
                className="gradient-btn btn btn-primary" 
                onClick={handleButtonClick}
              >
                Bekijk Statistieken
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-chart-line fa-3x mb-3"></i>
              <h5 className="card-title">Grafieken</h5>
              <p className="card-text">Bekijk de grafieken en trends.</p>
              <a 
                data-page="graph" 
                className="gradient-btn btn btn-primary" 
                onClick={handleButtonClick}
              >
                Bekijk Grafieken
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
