import React from 'react';

const ColumnSelection = ({ selectedColumns, handleColumnSelection, handlePrint, setColumnSelectionAlert }) => {
  return (
    <div className="custom-alert column-selection-alert">
      <div className="custom-alert-content">
        <h5>Selecteer Velden voor Printen</h5>

        <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <div className="col-6">
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.guests}
                onChange={() => handleColumnSelection('guests')}
                id="guestsCheckbox"
              />
              <label className="form-check-label" htmlFor="guestsCheckbox">
                Gasten
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.time}
                onChange={() => handleColumnSelection('time')}
                id="timeCheckbox"
              />
              <label className="form-check-label" htmlFor="timeCheckbox">
                Uur
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.name}
                onChange={() => handleColumnSelection('name')}
                id="nameCheckbox"
              />
              <label className="form-check-label" htmlFor="nameCheckbox">
                Naam
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.email}
                onChange={() => handleColumnSelection('email')}
                id="emailCheckbox"
              />
              <label className="form-check-label" htmlFor="emailCheckbox">
                Email
              </label>
            </div>
          </div>

          <div className="col-6">
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.phone}
                onChange={() => handleColumnSelection('phone')}
                id="phoneCheckbox"
              />
              <label className="form-check-label" htmlFor="phoneCheckbox">
                Telefoon
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.table}
                onChange={() => handleColumnSelection('table')}
                id="tableCheckbox"
              />
              <label className="form-check-label" htmlFor="tableCheckbox">
                Zitplaats
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.menu}
                onChange={() => handleColumnSelection('menu')}
                id="menuCheckbox"
              />
              <label className="form-check-label" htmlFor="menuCheckbox">
                Menu
              </label>
            </div>
            <div className="form-check" style={{ marginBottom: '1rem' }}>
              <input
                className="form-check-input"
                type="checkbox"
                checked={selectedColumns.extraInfo}
                onChange={() => handleColumnSelection('extraInfo')}
                id="extraInfoCheckbox"
              />
              <label className="form-check-label" htmlFor="extraInfoCheckbox">
                Extra Info
              </label>
            </div>
          </div>
        </div>

        <div className="custom-alert-actions mt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }}>
          <button className="btn btn-primary w-100 mb-2" onClick={handlePrint}>
            Printen
          </button>
          <button className="btn btn-secondary w-100" onClick={() => { setColumnSelectionAlert(false); }}>
            Annuleer
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColumnSelection;
