import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import useDataRetriever from '../../utils/useDataRetriever';
import 'chart.js/auto';
import '../css/analytics.css';
import '../css/general.css';

Chart.register(...registerables);

function Graph() {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [view, setView] = useState('all');
  const viewTitles = {
    all: 'Alle Reserveringen',
    M: 'Ontbijt',
    A: 'Lunch',
    E: 'Diner',
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const getUrl = (interval) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 15);
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 15);
    return `${window.baseDomain}restaurants/booked-seats/${interval}/${formatDate(startDate)}/${formatDate(endDate)}/`;
  };

  const { data, error, loading } = useDataRetriever(getUrl(view));

  useEffect(() => {
    if (data) {
      const labels = Object.keys(data).map(date => new Date(date));
      const reservationsData = Object.values(data).map(value => value / 4);

      let backgroundColor = 'rgba(108,117,125,0.2)';
      let borderColor = 'rgba(108,117,125,1)';

      switch (view) {
        case 'M':
          backgroundColor = 'rgba(27, 132, 255, 0.2)';
          borderColor = '#1b84ff';
          break;
        case 'A':
          backgroundColor = 'rgba(114, 57, 234, 0.2)';
          borderColor = '#7239ea';
          break;
        case 'E':
          backgroundColor = 'rgba(23, 198, 83, 0.2)';
          borderColor = '#17C653';
          break;
        default:
          backgroundColor = 'rgba(108,117,125,0.2)';
          borderColor = 'rgba(108,117,125,1)';
      }

      setChartData({
        labels,
        datasets: [
          {
            label: `Aantal Reserveringen (${viewTitles[view]})`,
            data: reservationsData,
            fill: true,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            tension: 0.1,
          },
        ],
      });
    }
  }, [data, view]);

  if (loading) return <p>Bezig met laden...</p>;
  if (error) return <p>Fout: {error.message}</p>;

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'dd/MM/yyyy',
        },
        title: {
          display: true,
          text: 'Datum'
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Reserveringen'
        },
      },
    },
  };

  return (
    <div className="container">
      <h1>{viewTitles[view]}</h1>
      <div className="btn-group position-absolute top-0 end-0 m-3" role="group" aria-label="View options">
        <button type="button" className="btn btn-secondary" onClick={() => setView('all')}>Alle Reserveringen</button>
        <button type="button" className="btn btn-primary" onClick={() => setView('M')}>Ochtend</button>
        <button type="button" className="btn btn-info" onClick={() => setView('A')}>Middag</button>
        <button type="button" className="btn btn-success" onClick={() => setView('E')}>Avond</button>
      </div>
      <Line data={chartData} options={options} />
    </div>
  );
}

export default Graph;
