import '../../css/gradient.css'

export const applyStarsEffect = (sidebar) => {
	sidebar.querySelectorAll('.star, .shooting-star').forEach((element) => element.remove());
  
	for (let i = 0; i < 70; i++) {
	  const star = document.createElement('div');
	  const width = Math.random() * 3;
	  star.className = 'star';
	  star.style.top = `calc(80px + ${Math.random() * 100}%)`;
	  star.style.left = `${Math.random() * 100}%`;
	  star.style.width = `${width}px`;
	  star.style.height = `${width}px`;
	  star.style.backgroundColor = 'rgb(240, 240, 240)';
	  star.style.position = 'absolute';
	  star.style.borderRadius = '50%';
	  star.style.zIndex = '0';
	  star.style.opacity = "0.5";
	  star.style.animation = `twinkle 2s infinite ease-in-out ${Math.random() * 5}s`;
	  sidebar.appendChild(star);
	}
  };

  
  export const applyShootingStarsEffect = (sidebar) => {
	// First, apply the regular twinkling stars effect
	applyStarsEffect(sidebar);
  
	// Clear any existing shooting stars and intervals
	sidebar.querySelectorAll('.shooting-star').forEach((element) => element.remove());
	if (sidebar.shootingStarInterval) {
	  clearInterval(sidebar.shootingStarInterval);
	}
  
	// Create a shooting star every 5 seconds at a random location
	sidebar.shootingStarInterval = setInterval(() => {
	  const shootingStar = document.createElement('div');
	  shootingStar.className = 'shooting-star';
	  shootingStar.style.top = `${Math.random() * 60}%`;
	  shootingStar.style.left = `${Math.random() * 80}%`;
	  shootingStar.style.width = '2px';
	  shootingStar.style.height = '5px';
	  shootingStar.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
	  shootingStar.style.position = 'absolute';
	  shootingStar.style.zIndex = '0';
	  shootingStar.style.opacity = "0.5";
	  shootingStar.style.transform = 'rotate(45deg)';
	  shootingStar.style.animation = `shootingStar 2s ease-in-out`;
  
	  // Append the shooting star to the sidebar
	  sidebar.appendChild(shootingStar);
  
	  // Remove the shooting star after the animation completes
	  setTimeout(() => {
		shootingStar.remove();
	  }, 2000); // Matches the animation duration
	}, 5000); // Create a new shooting star every 5 seconds
  };

  
  export const clearEffects = (sidebar) => {
	sidebar.querySelectorAll('.star, .shooting-star').forEach((element) => element.remove());
  };