import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Alert, Image, Modal, ProgressBar } from 'react-bootstrap';

const ProfilePictureUploader = () => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const restaurantName = localStorage.getItem('restaurantName');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file)); // Set preview image
      setShowModal(true); // Show the modal for resizing and preview

      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new window.Image();
        img.src = event.target.result;
        img.onload = () => {
          if (img.width !== img.height) {
            setIsWarningVisible(true); // Show warning if the image is not square
          } else {
            setIsWarningVisible(false);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!imageFile || !restaurantName) {
      alert('Selecteer een afbeelding voor uw profielfoto in png, jpeg of jpg. Houd er rekening mee dat u mogelijks de pagina moet vernieuwen voordat de afbeelding correct wordt weergegeven.');
      return;
    }

    setIsUploading(true);
    setProgress(0);

    const simulateProgress = () => {
      setTimeout(() => setProgress(30), 1000);
      setTimeout(() => setProgress(70), 3000);
      setTimeout(() => setProgress(90), 6000);
    };

    simulateProgress();

    try {
      const formData = new FormData();
      formData.append('file', imageFile);

      const response = await fetch(`https://images-server-eu-394994df6f30.herokuapp.com/upload_profile/${restaurantName}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setProgress(100);
        setUploadSuccess(true);
        setAlertVisible(true);
        setShowModal(false);
      } else {
        setUploadSuccess(false);
        setAlertVisible(true);
      }
    } catch (error) {
      console.error('Fout bij het uploaden van de afbeelding:', error);
      setUploadSuccess(false);
      setAlertVisible(true);
    } finally {
      setIsUploading(false);
      setProgress(0);
    }
  };

  return (
    <div className="container mt-4">
      {alertVisible && (
        <Alert
          variant={uploadSuccess ? 'success' : 'danger'}
          onClose={() => setAlertVisible(false)}
          dismissible
          className="mt-3"
        >
          {uploadSuccess
            ? 'Profielfoto succesvol geüpload! Houd er rekening mee dat het tot vier uur kan duren voordat de afbeelding correct wordt weergegeven.'
            : 'Er is een fout opgetreden bij het uploaden van de afbeelding. Probeer het opnieuw.'}
        </Alert>
      )}
      <h1>Profielfoto Uploaden</h1>
      <p>Vierkante afbeeldingen werken het beste.</p>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Selecteer een afbeelding:</Form.Label>
          <Form.Control type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleFileChange} />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-8">
          Afbeelding Uploaden
        </Button>
      </Form>

      {/* Image Preview Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Afbeelding Voorbeeld</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {isWarningVisible && (
            <Alert variant="danger" className="d-flex align-items-center">
              <div className="me-4" style={{ color: 'red', fontSize: '24px' }}>!</div>
              Voor een optimaal resultaat, kies een afbeelding die vierkant is (gelijk in breedte en hoogte).
            </Alert>
          )}
          {imagePreview && (
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
                margin: '0 auto',
              }}
            >
              <Image
                src={imagePreview}
                alt="Voorbeeld profielfoto"
                fluid
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </div>
          )}

          {isUploading && (
            <div style={{ margin: '20px' }}>
              <ProgressBar now={progress} animated />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)} disabled={isUploading}>
            Annuleren
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isUploading}>
            Afbeelding Uploaden
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePictureUploader;
