import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';
import { slotToTime } from '../../utils/converters';
import { format } from 'date-fns';

const backgrounds = [
  'https://static.reservaties.net/images/bg1.webp',
  'https://static.reservaties.net/images/bg2.webp',
  'https://static.reservaties.net/images/bg3.webp',
  'https://static.reservaties.net/images/bg4.webp',
];

function convertToNumber(...inputs) {
  let result = 0;

  const processValue = (value) => {
    if (typeof value === 'number') {
      result += value;
    } else if (typeof value === 'string') {
      const charSum = Array.from(value).reduce((sum, char) => sum + char.charCodeAt(0), 0);
      result += charSum;
    } else if (Array.isArray(value)) {
      value.forEach(item => processValue(item));
    } else if (typeof value === 'object' && value !== null) {
      Object.values(value).forEach(item => processValue(item));
    } else if (value === null || value === undefined || typeof value === 'boolean') {
    } else {
    }
  };

  inputs.forEach(input => processValue(input));
  console.log('Final result:', result);
  return result;
}

const Event = () => {
  const [title, setTitle] = useState('');
  const [dates, setDates] = useState([]);
  const [newDate, setNewDate] = useState('');
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');
  const [menus, setMenus] = useState([]);
  const [newMenu, setNewMenu] = useState('');
  const [shifts, setShifts] = useState([]);
  const [newShift, setNewShift] = useState(14);
  const [shiftDescription, setShiftDescription] = useState('');
  const [invisibleShifts, setInvisibleShifts] = useState([]);
  const [generatedLink, setGeneratedLink] = useState('');
  const [selectedBackground, setSelectedBackground] = useState('');
  const [shiftLength, setShiftLength] = useState(1);

  const addDate = () => {
    if (newDate && !dates.includes(newDate)) {
      setDates([...dates, newDate]);
      setNewDate('');
    }
  };

  const addDateRange = () => {
    if (rangeStart && rangeEnd && new Date(rangeStart) <= new Date(rangeEnd)) {
      const start = new Date(rangeStart);
      const end = new Date(rangeEnd);
      const range = [];

      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        const date = format(d, 'yyyy-MM-dd');
        if (!dates.includes(date)) {
          range.push(date);
        }
      }

      setDates([...dates, ...range]);
      setRangeStart('');
      setRangeEnd('');
    }
  };

  const addMenu = () => {
    if (newMenu && !menus.includes(newMenu)) {
      setMenus([...menus, newMenu]);
      setNewMenu('');
    }
  };

  const deleteMenu = (index) => {
    const newMenus = menus.filter((_, i) => i !== index);
    setMenus(newMenus);
  };

  const addShift = () => {
    if (shiftDescription && !shifts.some(shift => shift.number === newShift)) {
      setShifts([...shifts, { number: newShift, description: shiftDescription, visible: !invisibleShifts.includes(newShift) }]);
      setNewShift(14);
      setShiftDescription('');
    }
  };

  const deleteShift = (shiftNumber) => {
    const updatedShifts = shifts.filter(shift => shift.number !== shiftNumber);
    setShifts(updatedShifts);
  };

  const generateLink = () => {
    const formattedDates = dates.map(date => format(new Date(date), 'yyyy-MM-dd'));
    const pageType = "eventPage";

    const key = convertToNumber(formattedDates, title, menus, shifts, selectedBackground, shiftLength, pageType);

    /*
    console.log('Generating link with:', {
      title,
      formattedDates,
      menus,
      shifts: shifts.filter(shift => shift.visible),
      selectedBackground,
      shiftLength,
      key,
      pageType,
    });*/

    const link = `https://${localStorage.getItem('restaurantName')}.reservaties.net/?title=${encodeURIComponent(title)}&dates=${encodeURIComponent(formattedDates.join(','))}&menus=${encodeURIComponent(menus.join(','))}&shifts=${encodeURIComponent(shifts.filter(shift => shift.visible).map(shift => `${shift.number}:${shift.description}`).join(','))}&background=${encodeURIComponent(selectedBackground)}&shiftLength=${encodeURIComponent(shiftLength)}&key=${key}&pageType=${pageType}`;
    setGeneratedLink(link);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink);
  };

  return (
    <div className="container">
      <h1>Maak een Eventlink</h1>

      <div className="mb-3">
        <label htmlFor="title" className="form-label">Pagina titel</label>
        <input 
          type="text" 
          className="form-control" 
          id="title" 
          value={title} 
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="background" className="form-label">Selecteer achtergrond</label>
        <div className="d-flex overflow-auto">
          {backgrounds.map((url, index) => (
            <div 
              key={index} 
              className={`position-relative me-2 ${selectedBackground === url ? 'border-primary border-3' : 'border-secondary'} rounded`}
              style={{ width: '100px', height: '100px', cursor: 'pointer', borderRadius: '8px' }}
              onClick={() => setSelectedBackground(url)}
            >
              <img 
                src={url} 
                alt={`Background ${index + 1}`} 
                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }} 
              />
              {selectedBackground === url && (
                <div 
                  className="position-absolute top-0 start-0 w-100 h-100 border border-primary border-3 rounded"
                  style={{ pointerEvents: 'none' }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="mb-3">
        <label htmlFor="shiftLength" className="form-label">Selecteer shiftlengte</label>
        <select 
          className="form-select" 
          id="shiftLength" 
          value={shiftLength} 
          onChange={(e) => setShiftLength(parseInt(e.target.value, 10))}
        >
          <option value={1}>30 min</option>
          <option value={2}>60 min</option>
          <option value={3}>90 min</option>
          <option value={4}>120 min</option>
          <option value={5}>150 min</option>
          <option value={6}>180 min</option>
          <option value={7}>210 min</option>
          <option value={8}>240 min</option>
          <option value={9}>270 min</option>
          <option value={10}>300 min</option>
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="newMenu" className="form-label">Voeg menu's toe</label>
        <div className="input-group">
          <input 
            type="text" 
            className="form-control" 
            id="newMenu" 
            value={newMenu} 
            onChange={(e) => setNewMenu(e.target.value)}
            placeholder="Voer menu in"
          />
          <button className="btn btn-primary" onClick={addMenu}>Toevoegen</button>
        </div>
      </div>
      {menus.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Menu's</label>
          <ul className="list-group">
            {menus.map((menu, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                {menu}
                <button className="btn btn-danger btn-sm" onClick={() => deleteMenu(index)}>Verwijderen</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mb-3">
        <label htmlFor="newDate" className="form-label">Voeg datum toe</label>
        <div className="input-group">
          <input 
            type="date" 
            className="form-control" 
            id="newDate" 
            value={newDate} 
            onChange={(e) => setNewDate(e.target.value)}
          />
          <button className="btn btn-primary" onClick={addDate}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="rangeStart" className="form-label">Voeg een reeks datums toe</label>
        <div className="input-group">
          <input 
            type="date" 
            className="form-control" 
            id="rangeStart" 
            value={rangeStart} 
            onChange={(e) => setRangeStart(e.target.value)}
          />
          <span className="input-group-text">tot</span>
          <input 
            type="date" 
            className="form-control" 
            id="rangeEnd" 
            value={rangeEnd} 
            onChange={(e) => setRangeEnd(e.target.value)}
          />
          <button className="btn btn-primary" onClick={addDateRange}>Toevoegen</button>
        </div>
      </div>
      {dates.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Datums</label>
          <ul className="list-group">
            {dates.map((date, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                {date}
                <button className="btn btn-danger btn-sm" onClick={() => setDates(dates.filter((_, i) => i !== index))}>Verwijderen</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="mb-3">
        <label htmlFor="visibleShifts" className="form-label">Voeg shifts toe</label>
        <div className="input-group">
          <select 
            className="form-control" 
            id="visibleShifts" 
            value={newShift} 
            onChange={(e) => setNewShift(parseInt(e.target.value, 10))}
          >
            {Array.from({ length: 34 }, (_, i) => i + 14).map(shiftNumber => (
              <option key={shiftNumber} value={shiftNumber}>
                {slotToTime(shiftNumber)}
              </option>
            ))}
          </select>
          <input 
            type="text" 
            className="form-control ms-2" 
            value={shiftDescription} 
            onChange={(e) => setShiftDescription(e.target.value)}
            placeholder="Schrijf het uur bvb. 10u - Ontbijt"
          />
          <button className="btn btn-primary ms-2" onClick={addShift}>Toevoegen</button>
        </div>
      </div>
      {shifts.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Shifts</label>
          <ul className="list-group">
            {shifts.map((shift, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                {slotToTime(shift.number)} - {shift.description} 
                <button className="btn btn-danger btn-sm" onClick={() => deleteShift(shift.number)}>Verwijderen</button>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="d-flex flex-column align-items-center">
        <button className="btn btn-primary mb-2 w-100" onClick={generateLink}>Maak Een Link</button>
        {generatedLink && (
          <div className="input-group mt-3">
            <input 
              type="text" 
              className="form-control" 
              value={generatedLink} 
              readOnly 
            />
            <button className="btn btn-outline-secondary" onClick={copyToClipboard}>
              <FaCopy />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Event;
