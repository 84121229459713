
	  import React, { useState } from 'react';
	  import { Form, InputGroup } from 'react-bootstrap';
	  import { FaClipboard } from 'react-icons/fa';
	  
	  const Share = () => {
		const [copiedText, setCopiedText] = useState('');
	  
		const handleCopy = (text) => {
		  navigator.clipboard.writeText(text);
		  setCopiedText(text);
		};
	  
		const InputWithCopyIcon = ({ label, value, onCopy, style }) => (
		  <div style={style}>
			<label className="form-label">{label}</label>
			<InputGroup className="mb-3">
			  <InputGroup.Text onClick={() => onCopy(value)} style={{ cursor: 'pointer' }}>
				<FaClipboard />
			  </InputGroup.Text>
			  <Form.Control value={value} readOnly />
			</InputGroup>
		  </div>
		);
	  
		const messageToFriend = `Hey! Hier zijn de inloggegevens voor de demo van ons reservatiesysteem bij Mateza. Ik ben er zelf erg tevreden van en betaal nu een pak minder!
	  
	  Website: https://login.reservaties.net
	  Gebruikersnaam: demo
	  Wachtwoord: SQWEwCGDNljR2E
	  `
	  
		return (
		  <div>
			<h1 className="mb-6">Deel de Demo met een Vriend</h1>
				{/* Style 1: Subtle shadow with background */}
				<div style={{ backgroundColor: '#f9f9f9', padding: '15px', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
				Mateza is een jong bedrijf, opgericht door <strong>Thibault Van de Sompele</strong> nadat hij ontdekte dat restauranteigenaars erg ontevreden waren over de bestaande reservatiesystemen. 
				Daardoor ontwikkelde hij zijn eigen reservatiesysteem. Ondanks vele bedreigingen van concurrenten gelooft Thibault nog steeds in zijn missie: betaalbare IT voorzien voor alle restaurants. 

				<br /><br />
				Dankzij nieuwe technologieën is het Thibault gelukt om de prijs van zijn systemen met 80% te verlagen. Bovendien engageert Mateza zich om 20% van alle jaarlijkse nettowinst 
				te doneren aan Alzheimeronderzoek in België. <strong>U kan ons helpen door onze missie te delen. Hieronder staan de inlogcodes voor de demo die u met een vriend of collega kan delen. Bedankt voor uw vertrouwen in ons!</strong>
				</div>

				{/* Labels and Input with Copy Functionality with margin */}
				<InputWithCopyIcon label="Website" value="https://login.reservaties.net" onCopy={handleCopy} style={{ marginBottom: '20px' }} />
				<InputWithCopyIcon label="Gebruikersnaam" value="demo" onCopy={handleCopy} style={{ marginBottom: '20px' }} />
				<InputWithCopyIcon label="Wachtwoord" value="SQWEwCGDNljR2E" onCopy={handleCopy} style={{ marginBottom: '20px' }} />

				{/* Message field for sharing with a friend */}
				<InputWithCopyIcon label="Bericht om te delen met een vriend" value={messageToFriend} onCopy={handleCopy} style={{ marginBottom: '20px' }} />

				{copiedText && (
				<p className="mt-3 text-success">Gekopieerd: {copiedText}</p>
				)}
		  </div>
		);
	  };
	  
	  export default Share;
	  