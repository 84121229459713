import React, { useState, useEffect } from 'react';
import '../../css/tafels.css';
import '../../css/general.css';
import AreaModal from '../../../utils/showAreaModal';
import dataRetriever from '../../../utils/useDataRetriever';
import dataSaver from '../../../utils/useDataSaver';
import dataPatch from '../../../utils/useDataPatch';
import dataPost from '../../../utils/useDataPost';
import dataDelete from '../../../utils/useDataDelete';
import EditTableModal from './edit_modal';
import DeleteModal from '../../../components/delete_modal';
import isAdjacent from '../../../utils/isAdjacent';
import { prepareNewAreas, relocateTable, finalizeRelocation } from '../../../utils/tableUtils';

function Tafels() {
  const [areaSize, setAreaSize] = useState({ width: 10, height: 5 });
  const [grid, setGrid] = useState(Array(5).fill(Array(10).fill(0)));
  const [areas, setAreas] = useState([]);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [mapId, setMapId] = useState(null);
  const [createdAreas, setCreatedAreas] = useState(new Set());
  const [hoveredArea, setHoveredArea] = useState(null);
  const [hoveredSquare, setHoveredSquare] = useState(null);
  const [selectedAreaIndex, setSelectedAreaIndex] = useState(null);
  const [relocating, setRelocating] = useState(false);
  const [relocatingTable, setRelocatingTable] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);
  const [labels, setLabels] = useState([]);

  const { data: mapData, error: mapError, loading: mapLoading } = dataRetriever(window.baseDomain + 'restaurants/map/');
  const { data: tablesData, error: tablesError, loading: tablesLoading } = dataRetriever(window.baseDomain + 'tables/');
  const { data: labelsData, error: labelsError, loading: labelsLoading } = dataRetriever(window.baseDomain + 'tables/admin-label/');

  useEffect(() => {
    if (mapData && mapData.length > 0) {
      const { width, height, id } = mapData[0];
      setAreaSize({ width, height });
      setMapId(id);
    }
  }, [mapData]);

  useEffect(() => {
    setGrid(Array(areaSize.height).fill().map(() => Array(areaSize.width).fill(0)));
  }, [areaSize]);

  useEffect(() => {
    if (labelsData) {
      setLabels(labelsData);
    }
  }, [labelsData]);

  useEffect(() => {
    if (tablesData && labels.length > 0) {
      const newAreas = tablesData.map(table => {
        const label = labels.find(label => label.id === table.table_label);
        return {
          id: table.id,
          left: table.x,
          top: table.y,
          width: table.width,
          height: table.height,
          name: table.name,
          display_description: label ? label.display_description : '',
          shape: table.shape || 'square',
        };
      });

      let capacity = 0;
      tablesData.forEach(table => capacity += table.width * table.height);

      setTotalCapacity(capacity);
      setAreas(newAreas);
      setCreatedAreas(new Set(tablesData.map(table => JSON.stringify({
        left: table.x, top: table.y, width: table.width, height: table.height,
        name: table.name, display_description: labels.find(label => label.id === table.table_label)?.display_description || '',
        shape: table.shape
      }))));
    }
  }, [tablesData, labels]);

  const toggleSquare = (row, col) => {
    if (!editMode || isAdjacentToAnyTable(row, col)) return;
    const newGrid = grid.map((r, i) =>
      i === row ? r.map((c, j) => (j === col ? 1 - c : c)) : [...r]
    );
    setGrid(newGrid);
  };

  const handleEditSave = () => {
    setEditMode(!editMode);
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
  };

  const isAdjacentToAnyTable = (row, col) => areas.some(area => isAdjacent({ top: row, left: col, width: 1, height: 1 }, area));

  const handleCreateAreas = async () => {
    const newAreas = await prepareNewAreas(grid, areaSize, createdAreas, areas, dataPost, setTotalCapacity, setCreatedAreas, window, labels);
    setAreas([...areas, ...newAreas]);
    setGrid(Array(areaSize.height).fill().map(() => Array(areaSize.width).fill(0)));
  };

  const handleAreaSizeChange = async (e) => {
    e.preventDefault();
    const { width, height } = e.target.elements;
    const newAreaSize = { width: parseInt(width.value), height: parseInt(height.value) };

    try {
      await dataSaver(window.baseDomain + `restaurants/map/update-delete/${mapId}`, newAreaSize);
      setAreaSize(newAreaSize);
    } catch (error) {
      console.error('Error updating area size', error);
    }
    setModalVisible(false);
  };

  const handleMouseEnter = (index) => setHoveredArea(index);
  const handleMouseLeave = () => setHoveredArea(null);
  const handleSquareMouseEnter = (row, col) => editMode && setHoveredSquare({ row, col });
  const handleSquareMouseLeave = () => editMode && setHoveredSquare(null);
  const handleEditTable = (index) => { setSelectedAreaIndex(index); setEditModalVisible(true); };
  const handleRelocateTable = (index) => { setRelocating(true); setRelocatingTable(index); };
  const handleDeleteTableClick = (index) => { setDeleteModalVisible(true); setAreaToDelete(index); };
  const confirmDeleteTable = async () => {
    const index = areaToDelete;
    const area = areas[index];
    setTotalCapacity(totalCapacity => totalCapacity - (area.width * area.height));

    try {
      await dataDelete(window.baseDomain + `tables/update-delete/${area.id}`);
      setAreas(areas.filter((_, i) => i !== index));
      setCreatedAreas(prev => {
        const newSet = new Set(prev);
        newSet.delete(JSON.stringify({ left: area.left, top: area.top, width: area.width, height: area.height, name: area.name, display_description: area.display_description, shape: area.shape }));
        return newSet;
      });
    } catch (error) {
      console.error('Error deleting table', error);
    }
    setDeleteModalVisible(false);
    setAreaToDelete(null);
  };

  const handleEditModalClose = () => setEditModalVisible(false);

  const handleTableNameChange = async (newName, newDisplayDescription, newShape, newLabelId) => {
    const updatedAreas = [...areas];
    const area = updatedAreas[selectedAreaIndex];
    area.name = newName;
    area.display_description = newDisplayDescription;
    area.shape = newShape;

    try {
      await dataPatch(window.baseDomain + `tables/update-delete/${area.id}`, {
        name: area.name,
        table_label: newLabelId,
        shape: area.shape,
      });
    } catch (error) {
      console.error('Error updating table', error);
    }

    setAreas(updatedAreas);
    handleEditModalClose();
  };

  const addLabel = (newLabel) => {
    setLabels(prevLabels => [...prevLabels, newLabel]);
  };

  const handleMouseMove = (e) => {
    if (relocating && relocatingTable !== null) {
      const gridElement = document.querySelector('.grid-container');
      const updatedAreas = relocateTable(e, gridElement, areaSize, areas, relocatingTable);
      setAreas(updatedAreas);
    }
  };

  const handleMouseClick = async () => {
    if (relocating && relocatingTable !== null) {
      await finalizeRelocation(areas, relocatingTable, dataPatch, window);
      setRelocating(false);
      setRelocatingTable(null);
    }
  };

  if (mapLoading || tablesLoading || labelsLoading) return <div>Loading...</div>;
  if (mapError || tablesError || labelsError) return <div>Error: {mapError ? mapError.message : tablesError ? tablesError.message : labelsError.message}</div>;

  return (
    <div className="container" onMouseMove={handleMouseMove} onClick={handleMouseClick}>
      <h1>Tafelplan
        
        {labels.map(label => (
        <span key={label.id} className="badge bg-secondary badge-margin">
          <i className="bi bi-person"></i> {label.display_description}: {label.max_capacity} personen
        </span>
      ))}
      </h1>
      <div className="alert alert-primary mt-3 mb-10" role="alert">
        Let op! Elk vierkant staat voor 1 persoon. Overflowing is standaard uitgeschakeld. Zorg dus dat het aantal tafels groot genoeg is om de capaciteit van het restaurant te dragen of schakel overflowing in. Dit is niet van toepassing als er geen tafels toegevoegd zijn.
      </div>
      <div className="btn-group position-absolute top-0 end-0 m-3">
        <button className="btn btn-secondary" onClick={handleEditSave}>
          {editMode ? <><i className="bi bi-lock"></i> Vergrendelen</> : <><i className="bi bi-pencil"></i> Bewerken</>}
        </button>
        <button className="btn btn-info" onClick={handleCreateAreas}>Nieuwe Tafels</button>
        <button className="btn btn-primary" onClick={() => setModalVisible(true)}>Wijzig Gebiedsgrootte</button>
      </div>
      <AreaModal visible={modalVisible} onClose={() => setModalVisible(false)} areaSize={areaSize} onAreaSizeChange={handleAreaSizeChange} />
      <div className="d-grid gap-2 grid-container" style={{ gridTemplateColumns: `repeat(${areaSize.width}, 1fr)` }}>
        {grid.map((row, i) =>
          row.map((col, j) => {
            const isAdjacentSquare = isAdjacentToAnyTable(i, j);
            const squareClass = col
              ? 'square-selected'
              : hoveredSquare && hoveredSquare.row === i && hoveredSquare.col === j
                ? isAdjacentSquare ? 'square-hover-adjacent' : 'square-hover'
                : 'bg-secondary';
            return (
              <div
                key={`${i}-${j}`}
                className={`square ${squareClass}`}
                onClick={() => toggleSquare(i, j)}
                onMouseEnter={() => handleSquareMouseEnter(i, j)}
                onMouseLeave={handleSquareMouseLeave}
              >
                <div className="square-content"></div>
              </div>
            );
          })
        )}
        {areas.map((area, index) => (
          <div
            key={index}
            className={`area ${area.shape === 'round' ? 'round-table' : 'square-table'}`}
            style={{
              top: `${(area.top / areaSize.height) * 100}%`,
              left: `${(area.left / areaSize.width) * 100}%`,
              width: `${(area.width / areaSize.width) * 100}%`,
              height: `${(area.height / areaSize.height) * 100}%`,
              opacity: relocating && relocatingTable === index ? 0.5 : 1
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <h4 className="area-title">
              <span className="area-name">{area.name}</span>
              {labels.length > 1 && (
                <>
                  <br />
                  <span className="area-description">{area.display_description}</span>
                </>
              )}
            </h4>
            {hoveredArea === index && !relocating && (
              <div className="area-buttons">
                <button onClick={() => handleEditTable(index)}>
                  <i className="bi bi-pencil"></i>
                </button>
                <button onClick={() => handleRelocateTable(index)}>
                  <i className="bi bi-arrows-move"></i>
                </button>
                <button onClick={() => handleDeleteTableClick(index)}>
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <EditTableModal 
        visible={editModalVisible} 
        onClose={handleEditModalClose} 
        area={areas[selectedAreaIndex]} 
        onSave={handleTableNameChange} 
        areas={areas} 
        labels={labels}
        addLabel={addLabel}
      />
      <DeleteModal
        visible={deleteModalVisible}
        message={"Zeker dat u tafel " + areas[areaToDelete]?.name + " wilt verwijderen?"}
        onCancel={() => setDeleteModalVisible(false)}
        onConfirm={confirmDeleteTable}
      />
    </div>
  );
}

export default Tafels;
