import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Logo from './Logo'
import reportWebVitals from './reportWebVitals';
import { applyStarsEffect, applyShootingStarsEffect, clearEffects } from './pages/layout/gradient/gradient_effects';

const root = ReactDOM.createRoot(document.getElementById('root'));
const DEBUG = false;

if (DEBUG) {
  window.baseDomain = 'https://booking-system-test-02d7e73d8f66.herokuapp.com/';
  window.currentDomain = 'http://127.0.0.1:3000';

} else
{
  window.baseDomain = 'https://booking-system-euro-3c75b18a7c36.herokuapp.com/';
  window.currentDomain = 'https://login.reservaties.net/';
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Logo Root
const logoRoot = ReactDOM.createRoot(document.getElementById('logo-root'));

// Render the Logo component
logoRoot.render(
  <React.StrictMode>
    <Logo />
  </React.StrictMode>
);

document.addEventListener('DOMContentLoaded', () => {

  const storedEffect = localStorage.getItem('selectedEffect');

  const sidebar = document.getElementById('kt_app_sidebar');
    if (sidebar) {
      clearEffects(sidebar); // Clear previous effects

      if (storedEffect === 'stars') {
        applyStarsEffect(sidebar);
      } else if (storedEffect === 'shootingStars') {
        applyShootingStarsEffect(sidebar);
      }
    }

  const storedColor = localStorage.getItem('selectedGradient');
  if (storedColor) {
    document.getElementById('kt_app_sidebar').style.background = storedColor;
  }

  const loadingScreen = document.getElementById('loading-screen');
  if (loadingScreen) {
    loadingScreen.style.display = 'none';
  }

  const profileImage1 = document.getElementById('profile-image-1');

  if (profileImage1) {
    const restaurantName = localStorage.getItem('restaurantName');
    if (restaurantName) {
      const imageUrl = `https://static.reservaties.net/profiles/${restaurantName}.webp`;

      // Create a new image object to check if the URL is valid
      const img = new Image();
      img.src = imageUrl;

      // If the image loads successfully, use it. Otherwise, fallback to the default image
      img.onload = function() {
        profileImage1.src = imageUrl;
      };
      img.onerror = function() {
        profileImage1.src = 'https://static.reservaties.net/layout/user.webp';
      };
    } else {
      // Fallback to default image if restaurantName is not found in localStorage
      profileImage1.src = 'https://static.reservaties.net/layout/user.webp';
    }
  }

  const profileImage2 = document.getElementById('profile-image-2');

  if (profileImage2) {
    const restaurantName = localStorage.getItem('restaurantName');
    if (restaurantName) {
      const imageUrl = `https://static.reservaties.net/profiles/${restaurantName}.webp`;

      // Create a new image object to check if the URL is valid
      const img = new Image();
      img.src = imageUrl;

      // If the image loads successfully, use it. Otherwise, fallback to the default image
      img.onload = function() {
        profileImage2.src = imageUrl;
      };
      img.onerror = function() {
        profileImage2.src = 'https://static.reservaties.net/layout/user.webp';
      };
    } else {
      // Fallback to default image if restaurantName is not found in localStorage
      profileImage2.src = 'https://static.reservaties.net/layout/user.webp';
    }
  }
});



reportWebVitals();
