import React, { useState } from 'react';
import { Modal, Button, Form, ProgressBar } from 'react-bootstrap';
import { format } from 'date-fns';
import { nl, fr } from 'date-fns/locale';
import '../css/reservation_modal.css';
import { slotToTime } from '../../utils/converters';

const ReservationModal = ({
  showModal,
  setShowModal,
  guests,
  selectedDate,
  selectedSlot,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  handleSubmit,
  handleGuestsClick,
  language,
  selectedMenu,
  setSelectedMenu,
  menuItems,
  extraInfo,
  setExtraInfo,
  isLoading,
  progress
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const emailDomains = ['@gmail.com', '@hotmail.com', '@telenet.be'];

  const handleFormSubmit = () => {
    handleSubmit();
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const atPosition = newEmail.indexOf('@');
    if (atPosition === -1) {
      setEmailSuggestions(emailDomains);
    } else {
      setEmailSuggestions([]);
    }
  };

  const handleEmailSuggestionClick = (suggestion) => {
    setEmail((prevEmail) => prevEmail.split('@')[0] + suggestion);
    setEmailSuggestions([]);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEmailSuggestions([]);
  };

  function isMonthValid(ruleset, date) {
    const monthRule = ruleset.match(/_m[1-9]|_m1[0-2]/);
    if (!monthRule) return true;
    const ruleMonth = parseInt(monthRule[0].slice(2), 10);
    const selectedMonth = date ? date.getMonth() + 1 : 'N/A';
    console.log(`Month Rule: ${ruleMonth}, Selected Month: ${selectedMonth}`);
    return ruleMonth === selectedMonth;
  }
  
  function isDayValid(ruleset, date) {
    const dayRules = ruleset.match(/d[1-7]/g);
    if (!dayRules) return true;
    const selectedDay = date ? date.getDay() : 'N/A';
    console.log(`Day Rules: ${dayRules}, Selected Day: ${selectedDay}`);
    if (date) {
      return dayRules.some(d => {
        const ruleDay = parseInt(d.slice(1), 10);
        console.log(`Comparing Rule Day: ${ruleDay} with Selected Day: ${selectedDay}`);
        return ruleDay === selectedDay;
      });
    }
    return true;
  }
  
  const filteredMenuItems = menuItems.filter(item => {
    const rulesMatch = item.display_description.match(/__rules(_m[1-9]|_m1[0-2])?(_d[1-7]+)?/);
    if (!rulesMatch) return true;
  
    const ruleset = rulesMatch[0];
    const monthValid = isMonthValid(ruleset, selectedDate);
    const dayValid = isDayValid(ruleset, selectedDate);
  
    const itemDecision = monthValid && dayValid;
  
    console.log(`Item: ${item.display_description.split('__rules')[0]}`);
    console.log(`Ruleset: ${ruleset}`);
    console.log(`Month Valid: ${monthValid}`);
    console.log(`Day Valid: ${dayValid}`);
    console.log(`Selected Date: ${selectedDate}, Selected Month: ${selectedDate ? selectedDate.getMonth() + 1 : 'N/A'}, Selected Day: ${selectedDate ? selectedDate.getDay() : 'N/A'}`);
    console.log(`Final Decision: ${itemDecision}`);
  
    return itemDecision;
  }).map(item => ({
    ...item,
    display_description: item.display_description.split('__rules')[0]
  }));

  return (
    <Modal className="modal-on-top" show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{language === 'NL' ? 'Nieuwe Reservatie' : language === 'FR' ? 'Nouvelle Réservation' : 'New Reservation'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{language === 'NL' ? 'Datum' : language === 'FR' ? 'Date' : 'Date'}: {selectedDate ? format(selectedDate, 'd MMMM yyyy', { locale: language === 'NL' ? nl : fr }) : ''}</p>
        <p>{language === 'NL' ? 'Tijd' : language === 'FR' ? 'Heure' : 'Time'}: {selectedSlot !== null ? slotToTime(Number(selectedSlot)) : ''}</p>
        <p onClick={handleGuestsClick} style={{ cursor: 'pointer' }}>{language === 'NL' ? 'Aantal Personen' : language === 'FR' ? 'Nombre de Convives' : 'Number of Guests'}: {guests}</p>
        
        {isLoading && (
          <ProgressBar now={progress} animated variant="success" className="mb-3"/>
        )}
        
        <Form>
          {filteredMenuItems.length > 0 && (
            <Form.Group controlId="formMenu" className="mt-2 mb-2">
              <Form.Label>{language === 'NL' ? 'Menu' : language === 'FR' ? 'Menu' : 'Menu'}</Form.Label>
              <Form.Control
                as="select"
                value={selectedMenu}
                onChange={(e) => setSelectedMenu(e.target.value)}
              >
                <option value="">{language === 'NL' ? 'Selecteer een menu' : language === 'FR' ? 'Sélectionnez un menu' : 'Select a menu'}</option>
                {filteredMenuItems.map((menu) => (
                  <option key={menu.id} value={menu.display_description}>{menu.display_description}</option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="formFirstName">
            <Form.Label>{language === 'NL' ? 'Voornaam' : language === 'FR' ? 'Prénom' : 'First Name'}</Form.Label>
            <Form.Control
              type="text"
              placeholder={language === 'NL' ? 'Vul voornaam in' : language === 'FR' ? 'Entrez le prénom' : 'Enter first name'}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formLastName" className="mt-2">
            <Form.Label>{language === 'NL' ? 'Achternaam' : language === 'FR' ? 'Nom de Famille' : 'Last Name'}</Form.Label>
            <Form.Control
              type="text"
              placeholder={language === 'NL' ? 'Vul achternaam in' : language === 'FR' ? 'Entrez le nom de famille' : 'Enter last name'}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formEmail" className="mt-2">
            <Form.Label>{language === 'NL' ? 'Email' : language === 'FR' ? 'Email' : 'Email'}</Form.Label>
            <Form.Control
              type="email"
              placeholder={language === 'NL' ? 'Vul email in' : language === 'FR' ? 'Entrez l\'email' : 'Enter email'}
              value={email}
              onChange={handleEmailChange}
            />
            {emailSuggestions.length > 0 && (
              <div className="email-suggestions">
                {emailSuggestions.map((suggestion) => (
                  <Button
                    key={suggestion}
                    variant="outline-secondary"
                    className="email-suggestion"
                    onClick={() => handleEmailSuggestionClick(suggestion)}
                  >
                    {email.split('@')[0]}{suggestion}
                  </Button>
                ))}
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="formPhone" className="mt-2">
            <Form.Label>{language === 'NL' ? 'Telefoon' : language === 'FR' ? 'Téléphone' : 'Phone'}</Form.Label>
            <Form.Control
              type="tel"
              placeholder={language === 'NL' ? 'Vul telefoonnummer in' : language === 'FR' ? 'Entrez le numéro de téléphone' : 'Enter phone number'}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formExtraInfo" className="mt-2">
            <Form.Label>{language === 'NL' ? 'Extra Info / Allergenen' : language === 'FR' ? 'Informations Supplémentaires / Allergènes' : 'Extra Info / Allergens'}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={language === 'NL' ? 'Vul extra informatie in' : language === 'FR' ? 'Entrez des informations supplémentaires' : 'Enter extra info'}
              value={extraInfo}
              onChange={(e) => setExtraInfo(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isEditing ? (
          <Button variant="secondary" onClick={handleCloseModal}>
            {language === 'NL' ? 'Sluiten' : language === 'FR' ? 'Fermer' : 'Close'}
          </Button>
        ) : (
          <Button variant="secondary" onClick={() => setIsEditing(true)}>
            {language === 'NL' ? 'Bewerken' : language === 'FR' ? 'Modifier' : 'Edit'}
          </Button>
        )}
        <Button variant="primary" onClick={handleFormSubmit}>
          {language === 'NL' ? 'Bevestigen' : language === 'FR' ? 'Confirmer' : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationModal;
