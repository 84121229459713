import React, { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import '../../css/note.css';

const TagsWithPercentage = () => {
  const [tagData, setTagData] = useState({});

  const notesStorageKey = `${window.currentEmailSelection}-notes`;

  useEffect(() => {
    const fetchTagData = () => {
      const storedNotes = localStorage.getItem(notesStorageKey);
      if (storedNotes) {
        const notes = JSON.parse(storedNotes);
        const totalNotes = notes.length;
        const tagCounts = notes.reduce((acc, note) => {
          const tag = note.tag || 'Untagged';
          acc[tag] = (acc[tag] || 0) + 1;
          return acc;
        }, {});

        const tagPercentages = Object.entries(tagCounts).reduce((acc, [tag, count]) => {
          acc[tag] = ((count / totalNotes) * 100).toFixed(2); // Calculate percentage
          return acc;
        }, {});

        setTagData(tagPercentages);
      }
    };

    fetchTagData();
  }, [notesStorageKey]);

  return (
        <div>
          {Object.keys(tagData).length > 0 ? (
            Object.entries(tagData).map(([tag, percentage]) => (
              <Badge className="gray-badge" key={tag} pill variant="info" style={{margin: '5px' }}>
                {tag}: {percentage}%
              </Badge>
            ))
          ) : (
            <div></div>
          )}
        </div>
  );
};

export default TagsWithPercentage;
