import React from 'react';

function DeleteModal({ visible, message, onCancel, onConfirm }) {
  if (!visible) return null;

  return (
    <div className="custom-alert modal-on-top">
      <div className="custom-alert-content">
        <h5>Bevestig verwijdering</h5>
        <p>{message}</p>
        <div className="custom-alert-actions">
          <button className="btn btn-secondary" onClick={onCancel}>Nee, annuleer</button>
          <button className="btn btn-danger" onClick={onConfirm}>Ja, verwijderen</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
