import React from 'react';
import { FaCalendarDay, FaCalendarWeek, FaCalendarAlt, FaCalendar } from 'react-icons/fa'; // Import FA icons

const ViewModeButtons = ({ viewMode, setViewMode }) => {
  return (
    <div className="map-buttons">
      <button
        className={`btn mx-3 mb-2 ${viewMode === "day" ? "selected-btn" : ""}`}
        onClick={() => setViewMode("day")}
      >
        <FaCalendarDay className="me-2" /> Vandaag
      </button>
      <button
        className={`btn mx-3 mb-2 ${viewMode === "week" ? "selected-btn" : ""}`}
        onClick={() => setViewMode("week")}
      >
        <FaCalendarWeek className="me-2" /> Week
      </button>
      <button
        className={`btn mx-3 mb-2 ${viewMode === "month" ? "selected-btn" : ""}`}
        onClick={() => setViewMode("month")}
      >
        <FaCalendarAlt className="me-2" /> Maand
      </button>
    </div>
  );
};

export default ViewModeButtons;
