import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import DeleteModal from '../../components/delete_modal';
import { slotToTime } from '../../utils/converters';
import '../css/opening.css';
import '../css/general.css';

const ExceptionForm = () => {
  const [rangeStart, setRangeStart] = useState('');
  const [rangeEnd, setRangeEnd] = useState('');
  const [capacity, setCapacity] = useState(0);
  const [startTime, setStartTime] = useState(14);
  const [endTime, setEndTime] = useState(47);
  const [tableLabel, setTableLabel] = useState('');
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [exceptionToDelete, setExceptionToDelete] = useState(null);
  const [capacityModifiers, setCapacityModifiers] = useState([]);

  const tableLabelUrl = `${window.baseDomain}tables/admin-label/`;
  const { data: tableLabels, error: tableLabelError, loading: tableLabelLoading } = useDataRetriever(tableLabelUrl);

  const capacityModifiersUrl = `${window.baseDomain}restaurants/capacity-modifiers/`;
  const { data: fetchedCapacityModifiers, error: capacityModifiersError, loading: capacityModifiersLoading } = useDataRetriever(capacityModifiersUrl);

  useEffect(() => {
    if (fetchedCapacityModifiers) {
      setCapacityModifiers(fetchedCapacityModifiers);
    }

    if (capacityModifiersError) {
      console.error('Error fetching capacity modifiers:', capacityModifiersError);
    }
  }, [fetchedCapacityModifiers, capacityModifiersError]);

  const getAuthHeaders = () => ({
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + localStorage.getItem('authKey'),
  });

  const postCapacityModifier = async (date) => {
    const payload = {
      date: date,
      capacity: parseInt(capacity),
      start_time: parseInt(startTime),
      end_time: parseInt(endTime),
      table_label: parseInt(tableLabel),
    };

    try {
      const response = await fetch(capacityModifiersUrl, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post capacity modifier');
      }

      const newModifier = await response.json();
      setCapacityModifiers((prev) => [...prev, newModifier]);
    } catch (error) {
      console.error(`Failed to post capacity modifier for ${date}:`, error.message);
    }
  };

  const deleteCapacityModifier = async (id) => {
    const deleteUrl = `${capacityModifiersUrl}update-delete/${id}/`;

    try {
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete capacity modifier');
      }

      // Remove the deleted modifier from the list
      setCapacityModifiers((prev) => prev.filter(modifier => modifier.id !== id));
    } catch (error) {
      console.error('Failed to delete capacity modifier:', error.message);
    }
  };

  const addCapacityModifiersForDateRange = async () => {
    if (rangeStart && rangeEnd && new Date(rangeStart) <= new Date(rangeEnd)) {
      const start = new Date(rangeStart);
      const end = new Date(rangeEnd);

      for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
        const date = d.toISOString().split('T')[0];
        await postCapacityModifier(date);
      }

      // Clear the form inputs
      setRangeStart('');
      setRangeEnd('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addCapacityModifiersForDateRange();
    setCapacity(0);
    setStartTime(14);
    setEndTime(47);
    setTableLabel('');
  };

  const confirmDeleteException = (exception) => {
    setExceptionToDelete(exception);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    if (exceptionToDelete) {
      await deleteCapacityModifier(exceptionToDelete.id);
      setDeleteModalVisible(false);
      setExceptionToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
    setExceptionToDelete(null);
  };

  const generateTimeOptions = (valueShift) => {
    const options = [];
    for (let i = 14; i <= 47; i++) {
      options.push(<option key={i} value={i}>{slotToTime(i + valueShift)}</option>);
    }
    return options;
  };

  const handleRangeStartChange = (e) => {
    const selectedStartDate = e.target.value;
    setRangeStart(selectedStartDate);
    setRangeEnd(selectedStartDate); // Automatically set the end date to match the start date
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format the date to YYYY-MM-DD
  };

  if (tableLabelLoading || capacityModifiersLoading) {
    return <div>Loading...</div>;
  }

  if (tableLabelError || capacityModifiersError) {
    return <div>Error: {tableLabelError?.message || capacityModifiersError?.message}</div>;
  }

  return (
    <div className="container">
      <h1 style={{ marginBottom: '30px' }}>Beheer Uitzonderingen</h1>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="rangeStart" className="form-label">Begin Datum</label>
            <input
              type="date"
              className="form-control"
              id="rangeStart"
              value={rangeStart}
              onChange={handleRangeStartChange}
              min={getTodayDate()} // Prevent selecting past dates
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="rangeEnd" className="form-label">Eind Datum</label>
            <input
              type="date"
              className="form-control"
              id="rangeEnd"
              value={rangeEnd}
              onChange={(e) => setRangeEnd(e.target.value)}
              min={rangeStart} // End date cannot be earlier than the start date
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="startTime" className="form-label">Starttijd</label>
            <select
              className="form-select"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(parseInt(e.target.value))}
              required
            >
              {generateTimeOptions(0)}
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="endTime" className="form-label">Eindtijd</label>
            <select
              className="form-select"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(parseInt(e.target.value))}
              required
            >
              {generateTimeOptions(1)}
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="capacity" className="form-label">Capaciteit</label>
            <input
              type="number"
              className="form-control"
              id="capacity"
              value={capacity}
              onChange={(e) => setCapacity(parseInt(e.target.value))}
              min="0"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="tableLabel" className="form-label">Zitplaats</label>
            <select
              className="form-select"
              id="tableLabel"
              value={tableLabel}
              onChange={(e) => setTableLabel(e.target.value)}
              required
            >
              <option value="">Selecteer een zitplaats</option>
              {tableLabels.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.display_description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">Uitzondering Opslaan</button>
        </div>
      </form>

      {/* Display the added exceptions */}
      <div className="mt-4">
        <h2>Uitzonderingen</h2>
        <ul className="list-group">
          {capacityModifiers.map((modifier) => (
            <li key={modifier.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                <strong>Datum:</strong> {modifier.date} | <strong>Capaciteit:</strong> {modifier.capacity} | <strong>Starttijd:</strong> {slotToTime(modifier.start_time)} | <strong>Eindtijd:</strong> {slotToTime(modifier.end_time)} | <strong>Zitplaats:</strong> {tableLabels.find(label => label.id === modifier.table_label)?.display_description || 'N/A'}
              </div>
              <div>
                <button className="btn btn-danger btn-sm" onClick={() => confirmDeleteException(modifier)}>Verwijder</button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <DeleteModal
        visible={deleteModalVisible}
        message={`Weet u zeker dat u deze uitzondering wilt verwijderen?`}
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

export default ExceptionForm;
