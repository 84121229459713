import React from 'react';

const showAreaModal = ({ visible, onClose, areaSize, onAreaSizeChange }) => {
  if (!visible) return null;

  return (
    <div className="modal show" tabIndex="-1" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Gebiedsgrootte Wijzigen</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <form onSubmit={onAreaSizeChange}>
              <div className="mb-3">
                <label htmlFor="width" className="form-label">Breedte</label>
                <input type="number" className="form-control" id="width" defaultValue={areaSize.width} required />
              </div>
              <div className="mb-3">
                <label htmlFor="height" className="form-label">Hoogte</label>
                <input type="number" className="form-control" id="height" defaultValue={areaSize.height} required />
              </div>
              <button type="submit" className="btn btn-primary">Opslaan</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default showAreaModal;
