import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../../utils/useDataRetriever';
import DeleteModal from '../../../components/delete_modal';
import EditModal from './edit_modal';
import '../../css/opening.css';
import '../../css/general.css';

const Labels = () => {
  const url = `${window.baseDomain}tables/admin-label/`;
  const { data, error, loading } = useDataRetriever(url);
  const [labels, setLabels] = useState([]);
  const [newLabel, setNewLabel] = useState('');
  const [maxCapacity, setMaxCapacity] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState(null);
  const [labelToEdit, setLabelToEdit] = useState(null);
  const [newMaxCapacity, setNewMaxCapacity] = useState('');

  useEffect(() => {
    if (data && data.length > 0) {
      const labelList = data.map(item => ({ id: item.id, display_description: item.display_description, max_capacity: item.max_capacity }));
      setLabels(labelList);
    }
  }, [data]);

  const getAuthHeaders = () => {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('authKey'),
    };
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const postLabel = async (display_description, max_capacity) => {
    const formattedLabel = capitalizeFirstLetter(display_description);
    const payload = { display_description: formattedLabel, max_capacity: max_capacity ? parseInt(max_capacity) : null };
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to post label');
      }

      const newLabel = await response.json();
      setLabels([...labels, newLabel]);
    } catch (error) {
      console.error(`Failed to post label ${display_description}: ${error.message}`);
    }
  };

  const addLabel = async () => {
    if (newLabel && maxCapacity && !labels.some(label => label.display_description === capitalizeFirstLetter(newLabel))) {
      await postLabel(newLabel, maxCapacity);
      setNewLabel('');
      setMaxCapacity('');
    }
  };

  const deleteLabel = async (id) => {
    const endpoint = `${url}update-delete/${id}/`;

    try {
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        throw new Error('Failed to delete label');
      }

      setLabels(labels.filter(label => label.id !== id));
    } catch (error) {
      console.error(`Failed to delete label ${id}: ${error.message}`);
    }
  };

  const updateLabel = async (id, max_capacity) => {
    const endpoint = `${url}update-delete/${id}/`;
    const payload = { max_capacity: parseInt(max_capacity) };
  
    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update label');
      }
  
      // Since the update endpoint does not return the display description, preserve it from the state
      const updatedLabel = labels.find(label => label.id === id);
      updatedLabel.max_capacity = payload.max_capacity;
  
      setLabels(labels.map(label => label.id === id ? updatedLabel : label));
    } catch (error) {
      console.error(`Failed to update label ${id}: ${error.message}`);
    }
  };
  

  const removeLabel = async (labelToRemove) => {
    setLabelToDelete(labelToRemove);
    setModalVisible(true);
  };

  const handleCancelDelete = () => {
    setModalVisible(false);
    setLabelToDelete(null);
  };

  const handleConfirmDelete = async () => {
    await deleteLabel(labelToDelete.id);
    setModalVisible(false);
    setLabelToDelete(null);
  };

  const editLabel = async (labelToEdit) => {
    setLabelToEdit(labelToEdit);
    setNewMaxCapacity(labelToEdit.max_capacity || '');
    setEditModalVisible(true);
  };

  const handleCancelEdit = () => {
    setEditModalVisible(false);
    setLabelToEdit(null);
  };

  const handleConfirmEdit = async () => {
    await updateLabel(labelToEdit.id, newMaxCapacity);
    setEditModalVisible(false);
    setLabelToEdit(null);
    setNewMaxCapacity('');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="container">
      <h1>Zitplaatsen (capaciteit per zaal)</h1>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <label htmlFor="newLabel" className="form-label">Voeg zitplaatsen toe (voor keuzemenu klant)</label>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <input 
            type="text" 
            className="form-control" 
            id="newLabel" 
            value={newLabel} 
            onChange={(e) => setNewLabel(e.target.value)}
            placeholder="Bvb. terras, binnen, bar"
          />
          <input 
            type="number" 
            className="form-control" 
            value={maxCapacity}
            onChange={(e) => setMaxCapacity(e.target.value)}
            placeholder="Maximale capaciteit"
          />
          <button className="btn btn-primary" onClick={addLabel}>Toevoegen</button>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Zitplaatsen capaciteit</label>
        <ul className="list-group">
          {labels.map((label, index) => (
            <li key={label.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div>
                {label.display_description}
                {label.max_capacity && <span className="text-muted"> - Max. capaciteit: {label.max_capacity}</span>}
              </div>
              <div>
                <button className="btn btn-secondary btn-sm me-2" onClick={() => editLabel(label)}>Bewerken</button>
                {labels.length > 1 && (
                <button className="btn btn-danger btn-sm" onClick={() => removeLabel(label)}>Wissen</button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <DeleteModal 
        visible={modalVisible} 
        message="Weet u zeker dat u dit label wilt verwijderen? Wees voorzichtig, overboekingen kunnen gebeuren."
        onCancel={handleCancelDelete} 
        onConfirm={handleConfirmDelete} 
      />
      <EditModal
        visible={editModalVisible}
        message="Voer een nieuwe maximale capaciteit in:"
        maxCapacity={newMaxCapacity}
        onMaxCapacityChange={(e) => setNewMaxCapacity(e.target.value)}
        onCancel={handleCancelEdit}
        onConfirm={handleConfirmEdit}
      />
    </div>
  );
}

export default Labels;
