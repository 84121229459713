import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function EditTableModal({ visible, onClose, area, onSave, areas, labels, addLabel }) {
  const [tableName, setTableName] = useState('');
  const [displayDescription, setDisplayDescription] = useState('');
  const [shape, setShape] = useState('square');

  useEffect(() => {
    if (area) {
      setTableName(capitalize(area.name));
      setDisplayDescription(area.display_description || (labels.length === 1 ? labels[0].display_description : ''));
      setShape(area.shape || 'square');
    }
  }, [area, labels]);

  useEffect(() => {
    if (displayDescription && !areas.find(a => a.display_description === displayDescription)) {
      setDisplayDescription('');
    }
  }, [areas]);

  const handleSave = async () => {
    let labelId = null;
    if (displayDescription) {
      const existingLabel = labels.find(label => label.display_description === displayDescription);
      labelId = existingLabel ? existingLabel.id : null;
    } else if (labels.length === 1) {
      // If no displayDescription is selected, but there's only one label, use it
      setDisplayDescription(labels[0].display_description);
      labelId = labels[0].id;
    }
    onSave(capitalize(tableName), displayDescription, shape, labelId);
  };

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const uniqueDisplayDescriptions = [...new Set(labels.map(label => label.display_description))];

  return (
    <Modal className="modal-on-top" show={visible} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Bewerk Tafel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTableName">
            <Form.Label>Tafel Naam</Form.Label>
            <Form.Control 
              type="text" 
              value={tableName} 
              onChange={(e) => setTableName(e.target.value)} 
            />
          </Form.Group>
          <Form.Group controlId="formTableDescription" style={{ marginTop: '10px' }}>
            <Form.Label>Reservatietype (keuzemenu voor klant)</Form.Label>
            {uniqueDisplayDescriptions.length === 1 ? (
              <Form.Control 
                type="text" 
                value={uniqueDisplayDescriptions[0]} 
                readOnly 
              />
            ) : (
              <div className="d-flex align-items-center">
                <Form.Select
                  value={displayDescription}
                  onChange={(e) => setDisplayDescription(e.target.value)}
                >
                  {uniqueDisplayDescriptions.map((description, index) => (
                    description && description.trim() !== '' &&
                    <option key={index} value={description}>
                      {description}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}
          </Form.Group>
          <Form.Group controlId="formTableShape" style={{ marginTop: '10px' }}>
            <Form.Label>Vorm Tafel</Form.Label>
            <Form.Select value={shape} onChange={(e) => setShape(e.target.value)}>
              <option value="square">Vierkant</option>
              <option value="round">Rond</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Sluiten
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditTableModal;
