import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';
import { Modal, Button } from 'react-bootstrap';
import CompactDay from '../common/compact_day';
import '../common/calendar.css';

const Calendar = ({ events }) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (date) => {
    setSelectedDate(date);
    setShow(true);
  };

  const handleEventClick = ({ event }) => {
    const eventDate = event.startStr.split('T')[0];
    handleShow(eventDate);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    return new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
  };

  return (
    <div className="full-calendar-container">
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={events}
        locale={nlLocale}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reservaties voor {formatDate(selectedDate)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompactDay date={selectedDate} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

// Function to render custom event content with the specified format
const renderEventContent = (eventInfo) => {
  return (
    <div style={{ backgroundColor: eventInfo.event.extendedProps.color, whiteSpace: 'pre-line' }}>
      <div>{eventInfo.event.title}</div>
    </div>
  );
};

export default Calendar;
