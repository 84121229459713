import React, { useState, useEffect } from 'react';
import { ListGroup, Badge, Modal, Form, Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaCheck } from 'react-icons/fa';
import NoteInput from './note_input';
import '../../css/analytics.css';
import '../../css/general.css';
import '../../css/note.css';

// Helper function to calculate the contrast of a color
function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

const staticTags = [
  { name: "Op Tijd", color: "#4CD964" }, // Green
  { name: "Te Laat", color: "#FF9500" }, // Orange
  { name: "No Show", color: "#FF3B30" }, // Red
];

const macTagColors = [
  '#FF3B30', // Red
  '#FF9500', // Orange
  '#FFCC00', // Yellow
  '#4CD964', // Green
  '#007AFF', // Blue
];

function Note() {
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editNoteIndex, setEditNoteIndex] = useState(null);
  const [editNoteContent, setEditNoteContent] = useState('');
  const [newTag, setNewTag] = useState('');
  const [newTagColor, setNewTagColor] = useState('');

  const notesStorageKey = `${window.currentEmailSelection}-notes`;
  const tagsStorageKey = `${window.currentEmailSelection}-usedTags`;

  useEffect(() => {
    const storedNotes = localStorage.getItem(notesStorageKey);
    if (storedNotes) {
      setNotes(JSON.parse(storedNotes));
    }
  }, [notesStorageKey]);

  const saveNotesToLocalStorage = (updatedNotes) => {
    localStorage.setItem(notesStorageKey, JSON.stringify(updatedNotes));
    setNotes(updatedNotes);
  };

  const addNote = (note) => {
    const dateTime = new Date().toLocaleString('nl-NL', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const newNoteObject = {
      ...note,
      timestamp: dateTime,
    };

    const updatedNotes = [...notes, newNoteObject];
    saveNotesToLocalStorage(updatedNotes);
  };

  const handleEditNote = (index) => {
    setEditNoteIndex(index);
    setEditNoteContent(notes[index].content);
    setNewTag(notes[index].tag);
    setNewTagColor(notes[index].tagColor);
    setShowModal(true);
  };

  const saveEditedNote = () => {
    const dateTime = new Date().toLocaleString('nl-NL', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

    const updatedNotes = [...notes];
    updatedNotes[editNoteIndex] = {
      ...updatedNotes[editNoteIndex],
      content: editNoteContent,
      timestamp: dateTime,
      tag: newTag,
      tagColor: newTagColor,
    };

    saveNotesToLocalStorage(updatedNotes);
    setShowModal(false);
    setEditNoteIndex(null);
    setEditNoteContent('');
    setNewTag('');
    setNewTagColor('');
  };

  const deleteNote = (index) => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    saveNotesToLocalStorage(updatedNotes);
  };

  const allTags = [...staticTags];

  return (
    <div>

      {/* Render NoteInput component */}
      <NoteInput onAddNote={addNote} usedTags={allTags} />

      {/* Timeline of Notes */}
      <h5 className='mt-10'>Notitie Tijdlijn</h5>
      <ListGroup>
        {notes.map((note, index) => (
          <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
            <div>
              <div>{note.content}</div>
              <small className="text-muted">Bewerkt op: {note.timestamp}</small>
              {note.tag && (
                <Badge
                  className='custom-badge'
                  style={{
                    '--bg-color': note.tagColor,
                    '--text-color': getContrastYIQ(note.tagColor),
                    marginLeft: '10px',
                  }}
                >
                  {note.tag}
                </Badge>
              )}
            </div>
            <div className="d-flex">
              <div
                className="rounded-circle d-flex justify-content-center align-items-center"
                style={{
                  width: '35px',
                  height: '35px',
                  backgroundColor: '#dcdcdc',
                  marginRight: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => handleEditNote(index)}
              >
                <FaEdit />
              </div>
              <div
                className="rounded-circle d-flex justify-content-center align-items-center"
                style={{
                  width: '35px',
                  height: '35px',
                  backgroundColor: '#dcdcdc',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#FF3B30')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#dcdcdc')}
                onClick={() => deleteNote(index)}
              >
                <FaTrash />
              </div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Notitie Bewerken</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="editNoteInput">
            <Form.Label>Bewerk Notitie</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editNoteContent}
              onChange={(e) => setEditNoteContent(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="editTagInput" className="mt-6">
            <Form.Label>Bewerk Tag</Form.Label>
            <Form.Control
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="editTagColorInput" className="mt-6">
            <Form.Label className='mb-3'>Kleur van de Tag</Form.Label>
            <div className="d-flex justify-content-start">
            {macTagColors.map((color) => (
                  <button
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: color === newTagColor ? '2px solid black' : '1px solid transparent',
                      marginRight: '10px',
                      width: '30px',
                      height: '30px',
                      cursor: 'pointer',
                      borderRadius: '40px',
                    }}
                    onClick={() => setNewTagColor(color)}
                  />
                ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuleren
          </Button>
          <Button variant="primary" onClick={saveEditedNote}>
            <FaCheck style={{ marginRight: '5px' }} />
            Opslaan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Note;
