import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';
import { Modal, Button } from 'react-bootstrap';
import CompactDay from '../common/compact_day';
import '../../css/general.css';
import '../common/calendar.css';

const Calendar = ({ events }) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = (date) => {
    setSelectedDate(date);
    setShow(true);
  };

  const handleEventClick = ({ event }) => {
    const eventDate = event.startStr;
    handleShow(eventDate);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) {
      return 'Invalid Date';
    }
    return new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
  };

  return (
    <div className="calendar-wrapper">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        locale={nlLocale}
        eventClick={handleEventClick}
        eventClassNames={({ event }) => event.extendedProps.className}
        height={600}  // Adjust height as needed
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next'
        }}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reservaties voor {formatDate(selectedDate)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompactDay date={selectedDate} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calendar;
