import React from 'react';

const FilterShiftAlert = ({ handleFilterSelect, setFilterAlert, setMealType, setMealTypeParent, setSortType }) => {
  return (
    <div className="custom-alert filter-alert">
      <div className="custom-alert-content">
        <h5>Filter Shift</h5>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }} className="custom-alert-actions">
          <button className="btn btn-primary w-100 mb-2" onClick={() => handleFilterSelect('Ontbijt')}>Ontbijt</button>
          <button className="btn btn-primary w-100 mb-2" onClick={() => handleFilterSelect('Lunch')}>Lunch</button>
          <button className="btn btn-primary w-100 mb-2" onClick={() => handleFilterSelect('Diner')}>Diner</button>
          <button className="btn btn-danger w-100" onClick={() => handleFilterSelect('Hele Dag')}>Hele Dag</button>
          <button className="btn btn-secondary w-100 mt-3" onClick={() => { 
            setFilterAlert(false); 
            setMealType("Hele Dag"); 
            setMealTypeParent("Hele Dag"); 
            setSortType(null); 
          }}>
            Annuleer
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterShiftAlert;
