import React from 'react';
import '../../css/analytics.css';
import '../../css/general.css';

function Voordeel() {

  return (
    <div>
      <h1>Uw Voordeel</h1>
      <div className="row">
        <div className="col-md-4">
        <div className="card text-center">
        <div className="card-header text-muted">

          <p style={{textAlign:'center', width:'100%'}}>
          Goedkoopste Vergelijkbaar Alternatief
          </p>
              
            </div>
            <div className="card-body">
              <h5 className="card-title">€69 / maand</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header text-muted">
            <p style={{textAlign:'center', width:'100%'}}>
                Modaal Abonnement Concurrentie
            </p>
            </div>
            <div className="card-body">
              <h5 className="card-title">€145 / maand</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="card text-center">
        <div className="card-header text-muted">
            <p style={{textAlign:'center', width:'100%'}}>
              Uw Jaarlijks Voordeel met Mateza Booking
            </p>
              
            </div>
            <div className="card-body">
              <h5 className="card-title">€558 tot €1470</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-4">
        <div className="card text-center">
        <div className="card-header text-muted">

          <p style={{textAlign:'center', width:'100%'}}>
          Uw Klantenkorting op Websites
          </p>
              
            </div>
            <div className="card-body">
              <h5 className="card-title">35% korting</h5>
            </div>
          </div>
        </div>
        <div className="col-md-4">
        <div className="card text-center">
        <div className="card-header text-muted">

          <p style={{textAlign:'center', width:'100%'}}>
          Uw Besparing op Cadeaubonnen
          </p>
              
            </div>
            <div className="card-body">
              <h5 className="card-title">€4-5 per transactie</h5>
            </div>
          </div>
        </div>

        <div className="col-md-4">
        <div className="card text-center">
        <div className="card-header text-muted">

          <p style={{textAlign:'center', width:'100%'}}>
          Uw Donaties aan het Goede Doel (max. 1 euro per cadeaubon)
          </p>
              
            </div>
            <div className="card-body">
              <h5 className="card-title">€0</h5>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Voordeel;
